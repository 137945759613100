import React from "react";
import {Typography} from "antd";

const { Text } = Typography

function Email() {

    return (
        <Text>eMail: support@sixthcontinent.com</Text>
    )
}

export default Email