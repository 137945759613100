export const socialLinks = {
    fb: {
        US: 'https://www.facebook.com/unitedfree.us/',
        IT: 'https://www.facebook.com/unitedfree.us/',
        EU: 'https://www.facebook.com/unitedfree.us/',
        DE: 'https://www.facebook.com/unitedfree.us/',
        ES: 'https://www.facebook.com/unitedfree.us/',
        PT: 'https://www.facebook.com/unitedfree.us/',
    },
    ig: {
        US: 'https://www.instagram.com/unitedfree.us/',
        IT: 'https://www.instagram.com/unitedfree.us/',
        EU: 'https://www.instagram.com/unitedfree.us/',
        DE: 'https://www.instagram.com/unitedfree.us/',
        ES: 'https://www.instagram.com/unitedfree.us/',
        PT: 'https://www.instagram.com/unitedfree.us/',
    },
}
