export { default as getAffiliates } from './get.affiliates'
export { default as getBasicProfile } from './get.basic.profile'
export { default as getTotalBalance } from './get.total.balance'
export { default as logout } from './logout'
export { default as sendVerifyEmail } from './send.verify.email'
export { default as updateAvatar } from './update.avatar'
export { default as updateProfile } from './update.profile'
export { default as updatePrivacy } from './update.privacy'
export { default as updateSsn } from './update.ssn'

export { default as verifyAccount } from './verify.account'

/** BADGES */
export { default as getAllBadges } from './badges.get.all'
export { default as getNewBadges } from './badges.get.new'
export { default as updateBadges } from './badges.update'

/** ORANGE */
export { default as getOrangeStatus } from './get.orange.status'
export { default as getOrangePromocode } from './get.orange.promocode'
