import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Button, Row, Col, Alert, Typography } from 'antd'
import { forgotPassword } from 'Lib/sxc/loginregister'
import { useIntl } from 'react-intl'
import { UserOutlined } from '@ant-design/icons'
import Loader from 'Lib/ui/Loader'

interface IMessage {
    type: 'success' | 'info' | 'warning' | 'error'
    message: string
}

const ForgotPasswordForm: React.FC = () => {
    const intl = useIntl()
    const isMounted = useRef(true)
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [msg, setMsg] = useState<IMessage>({ type: 'info', message: '' })

    // messages
    const TRANSL_SENT = String(intl.messages['Common.email_sent'])
    const TRANSL_REQUESTED = String(intl.messages['Common.password_requested'])
    const TRANSL_WRONG = String(intl.messages['Common.something_wrong'])
    const TRANSL_MAIL = String(intl.messages['Common.form.enter_email'])
    //

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])
    const onFinish = (values: Record<string, unknown>) => {
        setLoading(true)

        const opts = {
            reqObj: {
                username: values.email,
            },
        }

        ;(async () => {
            try {
                await forgotPassword(opts)
                if (isMounted.current) {
                    setMsg({
                        type: 'success',
                        message: TRANSL_SENT,
                    })
                    setSent(true)
                }
            } catch (error) {
                console.log(error)
                if (isMounted.current) {
                    setMsg({
                        type: 'error',
                        message:
                            error.message === 'PasswordRequested'
                                ? TRANSL_REQUESTED
                                : TRANSL_WRONG,
                    })
                }
            } finally {
                if (isMounted.current) setLoading(false)
            }
        })()
    }
    return (
        <>
            <Typography.Paragraph>
                {intl.messages['Common.forgot_email_message']}
            </Typography.Paragraph>
            <Row justify="center" className="form">
                <Col xs={24}>
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: TRANSL_MAIL,
                                },
                            ]}
                        >
                            <Input
                                prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                }
                            />
                        </Form.Item>

                        <Form.Item>
                            {loading ? (
                                <Loader />
                            ) : (
                                <Button
                                    className="btn-submit"
                                    type="primary"
                                    htmlType="submit"
                                    disabled={sent}
                                >
                                    {
                                        intl.messages[
                                            'Common.request_new_password'
                                        ]
                                    }
                                </Button>
                            )}
                        </Form.Item>
                        {msg.message && (
                            <Alert
                                message={msg.message}
                                type={msg.type}
                                showIcon
                            />
                        )}
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default ForgotPasswordForm
