import Joi from 'joi'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        email: Joi.string().email({ tlds: { allow: false } }),
        is_all_accepted: 1,
        is_selling_accepted: 1,
        is_usage_accepted: 1,
        is_privacy_accepted: 1,
        is_marketing_accepted: 1,
        password: Joi.string(),
        type: 1,
    }),
}).options({ presence: 'required' })

export default async opts => {
    const { error, value } = schema.validate(opts)
    if (error) console.log(error)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}webapi/userpreregistration`

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        }).then(res => res.json())

        if (res.code !== 200 && res.code !== 93) {
            const error = new Error('REQUEST_FAILED')
            error.code = 'RequestFailed'
            throw error
        }

        return res
    } catch (err) {
        throw err
    }
}
