import { CONNEXPAY, methods, STRIPE, YAPILY } from './constants'

/**
 *
 * CART SECTION
 * the order in which gateways are written is important
 *
 */

export const paymentsObj = {
    IT: methods([YAPILY, STRIPE]),
    EU: methods([YAPILY, STRIPE]),
    DE: methods([YAPILY, STRIPE]),
    PT: methods([YAPILY, STRIPE]),
    ES: methods([YAPILY, STRIPE]),
    US: methods([CONNEXPAY]),
}

/**
 *
 * RICARICACELL SECTION
 *
 */

export const paymentsObjRicaricacell = methods([])

/**
 *
 * BULLETIN SECTION
 *
 */

export const paymentsObjBulletin = methods([])

/**
 *
 * ORANGE SECTION
 *
 */

export const paymentsObjOrange = {
    IT: methods([YAPILY, STRIPE]),
    EU: methods([YAPILY, STRIPE]),
    DE: methods([YAPILY, STRIPE]),
    PT: methods([YAPILY, STRIPE]),
    ES: methods([YAPILY, STRIPE]),
    US: methods([CONNEXPAY]),
}
