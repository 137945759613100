import platform from 'platform'

/**
 * Produce device type and name based on platform
 */
export default () => {
    let type = 'pc'

    if (
        /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(
            navigator.userAgent,
        )
    ) {
        type = 'smartphone'
    } else if (
        /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(
            navigator.userAgent,
        )
    ) {
        type = 'tablet'
    }

    const prod = platform.product || platform.os.family
    const manuf = platform.manufacturer || ''

    return { type, name: `${platform.name} ${prod} ${manuf}` }
}
