import { combineReducers } from 'redux'
import AuthReducer from './reducers/auth'
import PagamenoReducer from './reducers/pagameno'
import CartReducer from './reducers/cart'
import CheckoutReducer from './reducers/checkout'
import GroupsReducer from './reducers/groups'
import NotificationsReducer from './reducers/notifications'
import ProfileReducer from './reducers/profile'
import RicaricacellReducer from './reducers/ricaricacell'
import ShoppingReducer from './reducers/shopping'
import BestshoppingReducer from './reducers/bestshopping'
import VoucherReducer from './reducers/voucher'
import WalletReducer from './reducers/wallet'
import RegistrationReducer from './reducers/registration'
import TimelineReducer from './reducers/timeline'
import OrangeReducer from './reducers/orange'
import WishReducer from './reducers/wishlist'

import ChannelsReducer from './reducers/chat_channel'
import MessagesReducer from './reducers/chat_messages'
import IdleReducer from './reducers/chat_idle'
import SettingsReducer from './reducers/chat_settings'
import SizeReducer from './reducers/chat_size'
import UserReducer from './reducers/chat_user'
import WriteReducer from './reducers/chat_write'

const rootReducer = combineReducers({
    Auth: AuthReducer,
    Bestshopping: BestshoppingReducer,
    Cart: CartReducer,
    Checkout: CheckoutReducer,
    Groups: GroupsReducer,
    Notifications: NotificationsReducer,
    Orange: OrangeReducer,
    Pagameno: PagamenoReducer,
    Profile: ProfileReducer,
    Registration: RegistrationReducer,
    Ricaricacell: RicaricacellReducer,
    Shopping: ShoppingReducer,
    Timeline: TimelineReducer,
    Voucher: VoucherReducer,
    Wallet: WalletReducer,
    Wishlist: WishReducer,

    ChatChannels: ChannelsReducer,
    ChatMessages: MessagesReducer,
    ChatIdle: IdleReducer,
    ChatSettings: SettingsReducer,
    ChatSize: SizeReducer,
    ChatUser: UserReducer,
    ChatWrite: WriteReducer,
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
