import 'core-js/stable'
import 'regenerator-runtime/runtime'
import C from 'Lib/redux/constants'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {
    BrowserRouter as Router,
    useLocation,
    useHistory,
} from 'react-router-dom'
import IntlProvider from 'Lib/components/Localization/IntlProvider'
import configureStore from 'Lib/redux/configureStore'
import { Provider, useSelector, useDispatch } from 'react-redux'
import EventEmitter from 'event-emitter'

import './sass/index.scss'

// Our translated strings
import localeDataIt from './i18n/it/it_react.json'
import localeDataEn from './i18n/en/en_react.json'
import localeDataFr from './i18n/fr/fr_react.json'
import localeDataDe from './i18n/de/de_react.json'
import localeDataEs from './i18n/es/es_react.json'
import localeDataPt from './i18n/pt/pt_react.json'

import useStartAll from './react/header/hooks/index'
import Routing from './react/Routing'
import Auth from './react/auth'
import Header from './react/header/'
import Footer from './react/footer/'
import Badges from './react/badges/'
import Kount from './react/kount/'
import Maintenance from './react/maintenance/'

import { RootState } from 'Lib/redux/reducers'
import { Environment } from '../config/webpack/environment'

const MAINTENANCE = false

const localeData = {
    it: { ...localeDataIt },
    en: { ...localeDataEn },
    fr: { ...localeDataFr },
    de: { ...localeDataDe },
    es: { ...localeDataEs },
    pt: { ...localeDataPt },
}

const App: React.FC = () => {
    try {
        useStartAll()
    } catch (err) {
        console.log(err)
    }

    const loadingProfile = useSelector((s: RootState) => s.Profile.loading)
    const lang = useSelector((s: RootState) => s.Profile.language.toLowerCase())

    if (MAINTENANCE) {
        return (
            <IntlProvider localeData={localeData} initialLocale={lang}>
                <Maintenance />
            </IntlProvider>
        )
    }

    if (loadingProfile) {
        return (
            <div id="header">
                <div className="wrapper"></div>
            </div>
        )
    }

    return (
        <IntlProvider localeData={localeData} initialLocale={lang}>
            <Router>
                <PageChange />
                <Auth />
                <div className="max-width">
                    <Header />
                    <Routing />
                </div>
                <Footer />
                <Badges />
                <Kount />
            </Router>
        </IntlProvider>
    )
}

const PageChange: React.FC = (): null => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch({ type: C.ROUTE_CHANGE, payload: location.pathname, history })
        window.scrollTo(0, 0)
    }, [location])

    return null
}

// the div where react will be mounted
const MOUNT_NODE = document.getElementById('boot')
const store = configureStore()
window.ee = EventEmitter()

try {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        MOUNT_NODE,
    )
} catch (err) {
    console.log(err)
}
