import Cookies from 'js-cookie'
import { Environment } from '../../../../config/webpack/environment'

/**
 *
 * @param {object} response
 */
export default function({ data = {} }) {
    const cc = data.country
    const country = localStorage.getItem('UserCountry')
    if (country !== 'US' && cc === 'US') {
        amazon.Login.setClientId(Environment.amzClientId)
        Cookies.remove('amazon_Login_state_cache')
    } else if (country === 'US' && cc !== 'US') {
        amazon.Login.setClientId(Environment.amzClientIdEu)
        Cookies.remove('amazon_Login_state_cache')
    }
}
