export default function extendChannelAttrs(channel = {}, userId) {
    try {
        if (!channel || !channel.hasOwnProperty('members')) {
            return {
                chatName: '',
                chatAvatar: '',
                notSet: true,
                is1to1: false,
                isBlockedByMe: false,
                isBlockingMe: false,
                membersCount: 0,
                secondMember: {},
            }
        }

        const is1to1 = channel.isDistinct
        const membersCount = channel.memberCount

        const secondMember = is1to1
            ? channel.members.find(
                  obj => String(obj.userId) !== String(userId),
              ) || {}
            : {}

        const isBlockedByMe = is1to1 && secondMember.isBlockedByMe
        const isBlockingMe = is1to1 && secondMember.isBlockingMe

        const chatName = is1to1 ? secondMember.nickname : channel.name
        const chatAvatar = is1to1 ? secondMember.profileUrl : channel.coverUrl

        return {
            chatName,
            chatAvatar,
            notSet: false,
            is1to1,
            isBlockedByMe,
            isBlockingMe,
            membersCount,
            secondMember,
        }
    } catch (err) {
        console.log(err)
    }
}
