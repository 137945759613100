import { Dispatch } from 'react'
import { History } from 'history'
import C from 'Lib/redux/constants'
import { getBasicProfile } from 'Lib/sxc/user'
import { getRedisCart, deleteCart } from 'Lib/sxc/cart'
import { checkOrangeStatus } from 'Lib/sxc/orange/'
import { mergeGlobalNotif } from 'Lib/sxc/notifications'

/**
 *
 * @param {any} res //Requestlogin response
 * @param {Dispatch<any>} dispatch //dispatcher from redux (passed as parameter cause this function is not a custom hook)
 * @param {boolean} isRegistration //boolean that allow has to reuse this function after the registration
 */

const configProfile = async (
    dispatch: Dispatch<any>,
    history: History,
    isRegistration: boolean,
    redirect: string
): Promise<void> => {
    const accessToken = localStorage.getItem('access_token')

    try {
        const profRes = await getBasicProfile()

        dispatch({
            type: C.GET_PROFILE,
            payload: {
                ...profRes.data,
                accessToken,
            },
        })

        try {
            const cartRes = await getRedisCart()
            dispatch({
                type: C.CART_GET,
                payload: cartRes,
            })
        } catch (err) {
            console.log(err)
            /**
             * if there is some error, delete the current cart and retrieve again
             */
            if (err.message === 'DEVICE_TO_VALIDATE') {
                history.push('/device')
            } else {
                await deleteCart()
            }
        }

        try {
            sessionStorage.removeItem('sxc.last_noti_pull')
            dispatch({
                type: C.NOTIFICATIONS_GET,
                payload: await mergeGlobalNotif(),
            })
        } catch (err) {
            console.error(err)
        }

        //* UPDATE ORANGE VALUES
        try {
            const res = await checkOrangeStatus()
            dispatch({ type: C.ORANGE_CHECK_STATUS, payload: res.data })
        } catch (err) {
            console.log(err)
        }

        // GET NOTIFICATIONS
        dispatch({ type: C.NOTIFY_PULL })
        if (redirect)
            history.push(redirect)
        else if (!isRegistration)
            history.push('/shopping')
    } catch (err) {
        console.log(err)
    }
}

export default configProfile
