export default function getChannelMembers(groupChannel) {
    const membersQuery = groupChannel.createMemberListQuery()

    return offset =>
        new Promise((resolve, reject) => {
            membersQuery.limit = offset

            if (membersQuery.hasNext) {
                membersQuery.next((members, error) => {
                    if (error) {
                        reject(error)
                    }
                    resolve(members)
                })
            }
        })
}
