import C from 'Lib/redux/constants'
import {
    requestLoginAmazon,
    requestAfterLoginAmazon,
    generatePwd,
    amazonAuthorize,
} from 'Lib/sxc/loginregister'
import { checkWidget } from 'Lib/sxc/amazon'
import configProfile from '../functions/configProfile'
import { useDispatch } from 'react-redux'
import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Environment } from "../../../../../config/webpack/environment";
import {useLocation} from 'react-use';

export default function useLoginWithAmazon(): any {
    const history = useHistory()
    const dispatch = useDispatch()
    const [type, setType] = useState('')
    const isMounted = useRef(true)
    const location = useLocation()
    const isDeviceReset = location.state?.state?.deviceReset

    useLayoutEffect(() => {
        (function () {
            // Scoping function to avoid globals
            const isAmzSandbox = Environment.amzSandbox ? '/sandbox' : ''

            const script = document.createElement('script')

            const src =
                Environment.defaultCountry.toLowerCase() === 'us'
                    ? `https://static-na.payments-amazon.com/OffAmazonPayments/us${isAmzSandbox}/js/Widgets.js`
                    : `https://static-eu.payments-amazon.com/OffAmazonPayments/eur${isAmzSandbox}/lpa/js/Widgets.js`
            // document.write(`<script async src="${src}"><\/script>`)

            script.src = src
            script.type = 'text/javascript'
            document.getElementsByTagName('head')[0].appendChild(script)
        })()
    }, [])

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (!type) {
            return
        }

        (async () => {
            try {
                const auth = await amazonAuthorize()

                const opts = {
                    reqObj: {
                        access_token: auth.access_token,
                        token_type: auth.token_type,
                        is_all_accepted: 1,
                        is_selling_accepted: 1,
                        is_usage_accepted: 1,
                        is_privacy_accepted: 1,
                        is_marketing_accepted: 1,
                    },
                }
                const res = await requestLoginAmazon(opts)

                if (res.code === 200) {
                    /**
                     * user not exists
                     */
                    /**
                     * user not exists on sxc and flagged checkboxes
                     */
                    dispatch({
                        type: C.PREREGISTRATION_AMZ,
                        payload: {
                            firstName: res.firstname,
                            lastName: res.lastname,
                            password: generatePwd(),
                            preId: res.id,
                            email: res.email,
                        },
                    })
                    dispatch({
                        type: C.AUTH_SET,
                        payload: 'signup',
                    })
                } else if (res.code === 101) {
                    /**
                     * user exists proceed with login
                     */
                    const opts = {
                        reqObj: {
                            access_token: window.TEMP_TOKEN,
                            user_id: window.TEMP_ID,
                        },
                    }
                    const res2 = await requestAfterLoginAmazon(opts)
                    dispatch({
                        type: C.LOGIN,
                        payload: {
                            user_email: window.EMAIL,
                        },
                    })
                    dispatch({
                        type: C.AUTH_SET,
                        payload: null,
                    })
                    checkWidget(res2)
                    await configProfile(dispatch, history, false, isDeviceReset ? '/device' : null)
                }
            } catch (err) {
                console.log(err)
            }
        })()
    }, [type])

    return setType
}
