import { str } from './js.utils'
import { Environment } from '../../../config/webpack/environment'

export const getCurrencySymbol = (currencyName: 'USD' | 'EUR'): string => {
    let symbol
    switch (currencyName) {
        case 'USD':
            symbol = '$'
            break
        case 'EUR':
        default:
            symbol = '€'
    }
    return symbol
}

export const formatPrice = ({
    price = 0,
    currency = Environment.currency,
    isInCents = false,
}: {
    price: number | string
    currency?: 'USD' | 'EUR'
    isInCents?: boolean
}): string => {
    const text = price ? str(price) : '000'

    const symbol = getCurrencySymbol(currency)

    if (isInCents) {
        const beforeComma = text.slice(0, text.length - 2) || 0
        let afterComma = text.slice(text.length - 2, text.length)
        afterComma = String(afterComma).replace('.', '')
        afterComma = afterComma.length < 2 ? `0${afterComma}` : afterComma

        return `${symbol} ${beforeComma}.${afterComma}`
    }

    return `${symbol} ${price}`
}
