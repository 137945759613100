export default function UniversalId() {
    var atsScript = document.createElement('script')
    atsScript.onload = function() {
        window.ats.start({
            placementID: 1346,
            storageType: 'localStorage',
            detectionType: 'scrapeAndUrl',
            urlParameter: 'user_id',
            cssSelectors: ['input[type=text]', 'input[type=email]'],
            logging: 'debug',
            pixelID: 709093,
        })
    }
    atsScript.src = 'https://ats.rlcdn.com/ats.js'
    document.body.appendChild(atsScript)
}
