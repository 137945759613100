import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    access_token: Joi.string(),
    pan_pin: Joi.string(),
    pin: Joi.string(),
    user_id: Joi.number().integer(),
}).options({ presence: 'required' })

export default async (opts = {}) => {
    const { error } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.sxcCardDomain}1.0/sixthcontinentcard/recharge?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (Number(res.response.code) !== 200) {
            const err = new Error('REQUEST_FAILED')
            err.code = res.response?.data?.errors?.code
            throw err
        }

        return res
    } catch (err) {
        throw err
    }
}
