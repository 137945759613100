export default purchase => {
    let formatted = { ...purchase }

    /**
     * set not active and return if is not
     */
    if (!purchase.is_active) {
        formatted.status = 'not_active'
        return formatted
    }

    /**
     * if voucher is physical
     */
    if (purchase.deliveryType === 'physical') {
        if (purchase.deliveryTypeWallet === 'download') {
            formatted.status = 'download'
        } else {
            formatted.status = 'physical'
        }
    }

    /**
     * if voucher is digital
     */
    if (purchase.deliveryType === 'digital' || purchase.deliveryType === 'precharged') {
        switch (purchase.deliveryTypeWallet) {
            case 'delayed':
                /**
                 * u.s. vouchers hack
                 */
                if (purchase.vendor.toUpperCase().trim() === 'INCOMMUS') {
                    if (purchase.coupon_link.trim()) {
                        formatted.status = 'download'
                    } else {
                        formatted.status = 'wait'
                    }
                } else if (purchase.api_response) {
                    const status = JSON.parse(purchase.api_response) || {}

                    if (status.deliveryState === 'WAITING') {
                        formatted.status = 'download'
                    } else {
                        formatted.status = 'wait'
                    }
                } else {
                    formatted.status = 'wait'
                }
                break
            case 'mail':
            case 'nodownload':
                formatted.status = purchase.deliveryTypeWallet
                break
            case 'recharge':
                formatted.status = purchase.name.includes('RecardQ8') ? 'recharge' : 'download'
                break
            default:
                formatted.status = 'download'
        }
    }

    return formatted
}
