import { Environment } from "../../../../config/webpack/environment";

export default async opts => {
    const url = `${Environment.payDomain}webapi/setamazontransaction`

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.code !== 101) {
            const err = new Error('REQUEST_FAILED')
            err.data = res.data
            throw err
        }

        return res
    } catch (err) {
        throw err
    }
}
