/**
 *
 */
import { Environment } from "../../../../config/webpack/environment";

export default async (options = {}) => {
    /**
     * if profile already exist on localstorage get it
     */
    /* if (storedProfile && !options.forceApi) {
        return { data: storedProfile }
    } */

    const url = `${Environment.apiDomain}api/viewmultiprofiles?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`
    const opts = {
        reqObj: {
            user_id: window.SESSION_ID,
            profile_type: 4,
        },
    }

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.message !== 'SUCCESS') {
            throw new Error('REQUEST_FAILED')
        }

        if (res.code === 1090) {
            throw new Error('DEVICE_TO_VALIDATE')
        }

        return res
    } catch (err) {
        throw err
    }
}
