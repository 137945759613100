import moment from 'moment'
import C from 'Lib/redux/constants'
import faviconUpdate from 'Lib/sxc/faviconUpdate'
import produce from 'immer'
import { NotifyState, NotifyType } from 'Lib/types/notifications'

const orderByCreated = (arr: NotifyType[] = []): NotifyType[] =>
    arr.sort((a: NotifyType, b: NotifyType): number => {
        const dateA = a.created_at
        const dateB = b.created_at

        return -moment(dateA).valueOf() < -moment(dateB).valueOf() ? -1 : 0
    })

const initialState: NotifyState = {
    groups: [],
    merged: [],
    messages: { unread: 0, channelsList: {}, channelsOrder: [] },
    pendingFriend: [],
    social: [],
    transaction: [],
    pulling: true,
}

const DatalayerReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.NOTIFY_PULL:
            draft.pulling = true
            break
        case C.NOTIFICATIONS_ACTION_DONE:
            draft.merged = [
                ...draft.merged.filter(
                    (val: NotifyType) => val.id !== payload.id,
                ),
            ]
            draft[payload.type] = [
                ...draft[payload.type].filter(
                    (val: NotifyType) => val.id !== payload.id,
                ),
            ]
            break
        case C.NOTIFICATIONS_DELETE:
            draft.merged = [
                ...draft.merged.filter(
                    (notif: NotifyType) => notif.id !== payload.id,
                ),
            ]

            draft[payload.type] = [
                ...draft[payload.type].filter(
                    (notif: NotifyType) => notif.id !== payload.id,
                ),
            ]
            break
        case C.NOTIFICATIONS_GET:
            draft.groups = orderByCreated(payload.groups)
            draft.pendingFriend = orderByCreated(payload.pendingFriend)
            draft.merged = orderByCreated(payload.allMerged)
            draft.social = orderByCreated(payload.social)
            draft.transaction = orderByCreated(payload.transactions)
            draft.pulling = false
            break
        case C.NOTIFICATIONS_MARK_ALL_READ:
            draft.merged = []
            draft[payload] = draft[payload].map((v: NotifyType) => {
                v.is_read = true
                return v
            })
            break
        case C.NOTIFICATIONS_MARK_READ:
            draft.merged = [
                ...draft.merged.filter(
                    (notif: NotifyType) => notif.id !== payload.id,
                ),
            ]

            draft[payload.type] = [
                ...draft[payload.type].map((notif: NotifyType) => {
                    if (notif.id === payload.id) {
                        notif.is_read = true
                    }
                    return notif
                }),
            ]
            break
        case C.NOTIFICATIONS_MSG_GET_COUNT:
            faviconUpdate(payload)
            draft.messages.unread = payload
            break
        case C.NOTIFICATIONS_MSG_GET_CHANNELS:
            draft.messages.channelsList = payload.channels
            draft.messages.channelsOrder = payload.channelOrder
            break
        case C.LOGOUT:
            return { ...initialState }
        default:
            return draft
    }
})

export default DatalayerReducer
