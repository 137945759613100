import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { ChatSizeState } from 'Lib/types/chat'

const initialState: ChatSizeState = {
    isFullScreen: false,
    chatMobile: false,
}

const ChatSizeReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.CHAT_SIZE_INIT:
            draft.isFullScreen = payload.isFullScreen
            draft.chatMobile = payload.chatMobile
            break
        case C.CHAT_SET_FULL_SCREEN:
            draft.isFullScreen = payload.isFullScreen
            break
        default:
            return draft
    }
})

export default ChatSizeReducer
