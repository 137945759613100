/**
 *
 * @param {*} idFilter
 */
export default function getBlockedUsers(idFilter = []) {
    try {
        const blockedUserListQuery = window.sb.createBlockedUserListQuery()
        blockedUserListQuery.userIdsFilter = idFilter

        return () =>
            new Promise((resolve, reject) => {
                blockedUserListQuery.next(function(users, error) {
                    if (error) {
                        reject(error)
                    }
                    resolve(users)
                })
            })
    } catch (err) {
        return () => new Promise(res => res([]))
    }
}
