import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Button, Row, Col, Alert } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import C from 'Lib/redux/constants'
import { getAccessToken, requestLogin } from 'Lib/sxc/loginregister'
import base64 from 'base-64'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import configProfile from '../functions/configProfile'
import { useIntl } from 'react-intl'
import { Environment } from '../../../../../config/webpack/environment'
import Loader from 'Lib/ui/Loader'
import {useLocation} from 'react-use';

const SignInForm: React.FC = () => {
    const history = useHistory()
    const intl = useIntl()
    const dispatch = useDispatch()
    const isMounted = useRef(true)
    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState('')
    const location = useLocation()
    const isDeviceReset = location.state?.state?.deviceReset

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])
    const onFinish = async (values: Record<string, string>) => {
        setLoading(true)
        try {
            const base64Pwd = base64.encode(values.password)
            await getAccessToken({
                reqObj: {
                    client_id: Environment.client_id,
                    client_secret: Environment.client_secret,
                    grant_type: Environment.grant_type,
                    username: values.email,
                    password: base64Pwd,
                },
            })
            await requestLogin({
                reqObj: {
                    username: values.email,
                    password: base64Pwd,
                },
            })

            dispatch({
                type: C.AUTH_SET,
                payload: null,
            })

            /** datalayer */
            dispatch({
                type: C.LOGIN,
                payload: {
                    user_email: values.email,
                },
            })

            await configProfile(dispatch, history, false, isDeviceReset ? '/device' : null)
        } catch (error) {
            console.log(error)
            if (isMounted.current) {
                setMsg(
                    error.message === 'USERNAME_OR_PASSWORD_IS_WRONG'
                        ? String(intl.messages['Common.invalidUserPwd'])
                        : String(intl.messages['Common.something_wrong']),
                )
                setLoading(false)
            }
        }
    }

    return (
        <>
            <Row justify="center" className="form">
                <Col xs={24}>
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: String(
                                        intl.messages[
                                            'Common.form.enter_email'
                                        ],
                                    ),
                                },
                            ]}
                        >
                            <Input
                                prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: String(
                                        intl.messages['Common.insertPassword'],
                                    ),
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={
                                    <LockOutlined className="site-form-item-icon" />
                                }
                            />
                        </Form.Item>

                        <Form.Item>
                            {loading ? (
                                <Loader />
                            ) : (
                                <Button
                                    className="btn-submit"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {intl.messages['Common.login']}
                                </Button>
                            )}
                        </Form.Item>
                        {msg && <Alert type="error" message={msg} />}
                    </Form>
                    <a
                        className="link"
                        onClick={() =>
                            dispatch({
                                type: C.AUTH_SET,
                                payload: 'forgot',
                            })
                        }
                    >
                        {intl.messages['Common.forgot_password']}
                    </a>
                </Col>
            </Row>
        </>
    )
}

export default SignInForm
