export default function Connect(userId, token) {
    return new Promise((resolve, reject) => {
        window.sb.connect(userId, token, (user, err) => {
            if (err) {
                reject(err)
            } else {
                resolve(user)
            }
        })
    })
}
