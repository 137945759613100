export const endpoints = {
    apiDomain: 'https://produs.sixthcontinent.com/',
    base_url: 'https://www.unitedfree.com/',
    cdcDomain: 'https://cdc.sixthcontinent.com/api/v1.0/offerAmount',
    crawlerDomain: 'https://ogus.sixthcontinent.com',
    devicesDomain: 'https://sxdevilmanus.sixthcontinent.com/api/',
    elasticDomain: 'https://msearchus.sixthcontinent.com',
    groupsDomain: 'https://sxpinewoodus.sixthcontinent.com/api/1.0/',
    messengerDomain: 'https://smsmessenger.sixthcontinent.com/',
    notifyDomain: 'https://sxermesus.sixthcontinent.com/api/',
    payDomain: 'https://payus.sixthcontinent.com/',
    postsDomain: 'https://sxgeorgeus.sixthcontinent.com/api/',
    smsDomain: 'https://sxotpus.sixthcontinent.com/api/1.0/',
    sxcCardDomain: 'https://sxabba.sixthcontinent.com/api/',
    sxcTicketDomain: 'https://sxticket.sixthcontinent.com/api/',
}
