import React from 'react'
import Alert from 'antd/es/alert'
import { useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { useIntl } from 'react-intl'

const PendingCi: React.FC = () => {
    const intl = useIntl()
    const pendingCi = useSelector((s: RootState) => s.Wallet.pendingCi)

    if (!pendingCi.visible) {
        return null
    }

    return (
        <Alert
            message=""
            description={intl.formatMessage(
                { id: 'Wallet.pendingCi' },
                {
                    placeholder1: pendingCi.time,
                    placeholder2: pendingCi.credits.toFixed(2),
                    placeholder3: pendingCi.points,
                },
            )}
            type="warning"
            showIcon
            closable
            className="pending-ci-alert"
        />
    )
}

export default PendingCi
