import Joi from 'joi'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        token: Joi.string(),
        password: Joi.string(),
    }),
}).options({ presence: 'required' })

export default async opts => {
    const { error } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}webapi/reset`

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        switch (res.code) {
            case '112':
                throw new Error('InvalidToken')
            case '113':
                throw new Error('InvalidPassword')
            case '100':
                throw new Error('AccountInactive')
            case '108':
                throw new Error('NoResetRequest')
            case '102':
                throw new Error('InvalidPasswordFormat')
            default:
                if (res.code !== '101') {
                    throw new Error('RequestFailed')
                }
        }

        return res
    } catch (err) {
        throw err
    }
}
