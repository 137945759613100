import Joi from 'joi'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        city_born: Joi.string().allow('', null),
        currently_working: Joi.string().allow('', null),
        date_of_birth: Joi.string(),
        firstname: Joi.string(),
        gender: Joi.string(),
        hobbies: Joi.string().allow('', null),
        lastname: Joi.string(),
        profile_image_thumb: Joi.string().allow('', null),
        relationship: Joi.string().allow('', null),
        type: 1,
        user_id: Joi.string(),
    }),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error } = schema.validate(opts)
    if (error) {
        console.log(error)
        throw new Error('INVALID_OPTS')
    }

    const url = `${Environment.apiDomain}api/updatemultiprofiles?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then((res) => res.json())

        if (res.code !== '101') {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
