// This is called with the results from from FB.getLoginStatus().
import { Environment } from '../../../../config/webpack/environment'

window.fbAsyncInit = function () {
    FB.init({
        appId: Environment.fbId,
        cookie: true, // enable cookies to allow the server to access
        status: true,
        xfbml: false, // parse social plugins on this page
        version: 'v12.0',
    })
}

export const checkFBLoginState = () =>
    new Promise((resolve, reject) => {
        FB.login(
            async () => {
                try {
                    const res = await fbAPI()
                    resolve(res)
                } catch (error) {
                    console.log(error.message)
                    reject(error.messge)
                }
            },
            { scope: 'public_profile,email' },
        )
    })

function fbAPI() {
    return new Promise((resolve) => {
        FB.api(
            '/me',
            { fields: 'first_name,last_name,email' },
            async (response) => {
                try {
                    const fbId = response.id
                    const fname = response.first_name
                    const lname = response.last_name
                    const email = response.email || ''
                    const res2 = await new Promise((resolve) =>
                        FB.getLoginStatus((response) => resolve(response)),
                    )
                    const accessToken = res2.authResponse.accessToken
                    resolve({ fbId, email, fname, lname, accessToken })
                } catch (error) {
                    console.log(error.message)
                    resolve(error.message)
                }
            },
        )
    })
}
