import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

/**
 *
 *
 *
 */
export default async () => {
    const url = `${Environment.payDomain}api/getmycart?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()

        const opts = {
            reqObj: {
                hash,
            },
        }

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then((res) => res.json())

        if (res.code !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        if (res.code === 1090) {
            throw new Error('DEVICE_TO_VALIDATE')
        }

        return res
    } catch (err) {
        throw err
    }
}
