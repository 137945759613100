export default function GetAllUnreadNotif() {
    return new Promise((resolve, reject) => {
        window.sb.getTotalUnreadMessageCount(function(count, error) {
            if (error) {
                reject(error)
            }

            resolve(count)
        })
    })
}
