import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { ChatUserState } from 'Lib/types/chat'

const initialState: ChatUserState = {
    userId: 0,
    name: '',
    accessToken: null,
    chatToken: null,
    profileImg: '',
}
const ChatUserReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.CHAT_USER_INIT:
            draft.userId = payload.userId
            draft.name = `${payload.firstname} ${payload.lastname}`
            draft.accessToken = payload.accessToken
            draft.chatToken = payload.chatToken
            draft.profileImg = payload.profileImg
            break
        default:
            return draft
    }
})

export default ChatUserReducer
