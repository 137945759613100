export default function updateChannel(channel, name, upload) {
    return new Promise((resolve, reject) => {
        channel.updateChannel(
            name,
            upload ? upload[0].originFileObj : channel.coverUrl,
            '',
            (chn, error) => {
                if (error) reject(error)
                resolve(chn)
            },
        )
    })
}
