import { createStore, applyMiddleware, compose } from 'redux'
import {
    privacyMiddleware,
    adastaMiddleware,
    userMiddleware,
    notificationsMiddleware,
    messengerMiddleware,
} from './middlewares/'
import rootReducer from './reducers'

export function bootstrap(initialState = {}) {
    const enhancers = [
        applyMiddleware(
            privacyMiddleware,
            notificationsMiddleware,
            adastaMiddleware,
            userMiddleware,
            messengerMiddleware,
        ),
    ]

    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
                  // Prevent recomputing reducers for `replaceReducer`
                  name: 'Sxc Monolithe',
                  shouldHotReload: false,
              })
            : compose
    /* eslint-enable */

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(...enhancers),
        // compose(...enhancers),
    )

    return { store }
}

export default () => {
    const { store } = bootstrap({})

    return store
}
