import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    int_code: Joi.string(),
    phone_number: Joi.string(),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const token = window.TOKEN || window.TEMP_TOKEN
    const user_id = window.SESSION_ID || window.TEMP_ID
    const url = `${Environment.devicesDomain}device_register_number?access_token=${token}&session_id=${user_id}`

    opts.user_id = user_id

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then((res) => res.json())

        return res.data.result === '600' || res.data.result === '605'
    } catch (err) {
        throw err
    }
}
