import React from 'react'
import { Button } from 'antd'

interface ButtonProps {
    className?: string
    disabled?: boolean
    loading?: boolean
    htmlType?: 'button' | 'submit' | 'reset'
    size?: 'large' | 'middle' | 'small'
    onClick?: React.MouseEventHandler<HTMLElement>
}

export const ButtonOrange: React.FC<ButtonProps> = ({
    children,
    className,
    onClick,
    htmlType = 'button',
    ...props
}) => (
    <Button
        {...props}
        onClick={onClick}
        className={`orange-btn ${className}`}
        htmlType={htmlType}
    >
        {children}
    </Button>
)

export const ButtonWhite: React.FC<ButtonProps> = ({
    children,
    className,
    onClick,
    htmlType = 'button',
    ...props
}) => (
    <Button
        {...props}
        onClick={onClick}
        className={`white-btn ${className}`}
        htmlType={htmlType}
    >
        {children}
    </Button>
)

export const ButtonViolet: React.FC<ButtonProps> = ({
    children,
    className,
    onClick,
    htmlType = 'button',
    ...props
}) => (
    <Button
        {...props}
        onClick={onClick}
        className={`dark-btn ${className}`}
        htmlType={htmlType}
    >
        {children}
    </Button>
)
