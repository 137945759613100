import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { WishState } from 'Lib/types/wishlist'

const initialState: WishState = {
    list: [],
}

const ProfileReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.WISHLIST_GET:
            draft.list = payload
            break
        case C.WISHLIST_ADD:
            draft.list = [...draft.list, payload]
            break
        case C.WISHLIST_REMOVE:
            draft.list = draft.list.filter((i: any) => i.offer_id !== payload)
            break
        case C.LOGOUT:
            return { ...initialState }
        default:
            return draft
    }
})

export default ProfileReducer
