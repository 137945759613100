export const getDateOnly = (date: string): string => {
    if (!String(date).length || String(date).length < 10) {
        return date
    }

    const newDate = new Date(date)
    const day = newDate.getDate()
    const month = newDate.getUTCMonth() + 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
}

export const dhms = (t: number): string => {
    const days = Math.floor(t / 86400)
    const hours = Math.floor(t / 3600)
    const minutes = Math.floor(t / 60) % 60
    const seconds = t % 60

    return `${days}g ${hours}h ${minutes}m ${seconds}s`
}
