import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import debugApi from 'Lib/sxc/debug.api'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        cart: Joi.array().items(
            Joi.object({
                deviceType: 'web',
                card_id: Joi.string(),
                offer_id: Joi.number(),
                personal_card_id: Joi.string().optional(),
                premiumData: Joi.array().optional(),
                use_credit: Joi.number(),
                quantity: Joi.number(),
            }),
        ),
        use_ticket: Joi.number(),
    }),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error, errors, value } = schema.validate(opts)
    debugApi(error, errors, value)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.payDomain}api/cart?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`
    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()

        value.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        }).then((res) => res.json())

        if (Number(res.code) === 1010 || Number(res.code) === 102) {
            throw new Error('SXCCARD_BALANCE')
        }

        if (Number(res.code) === 1510) {
            throw new Error('OFFER_FOR_OTHER_COUNTRY')
        }

        if (Number(res.code) === 1075) {
            throw new Error('ONLY_ORANGE_PREMIUM')
        }

        if (res.code === 1037) {
            throw new Error('USER_LIMITED')
        }

        if (res.code === 1043) {
            throw new Error('USER_DISABLED')
        }

        if (res.code === 1052) {
            throw new Error('EMAIL_TO_VALIDATE')
        }

        if (res.code === 1090) {
            throw new Error('DEVICE_TO_VALIDATE')
        }

        if (res.code === 1155) {
            throw new Error('PURCHASE_LIMIT_REACHED')
        }

        if (res.code === 1413) {
            throw new Error('THIS_OFFER_MUST_BE_PURCHASED_ALONE_NO_OTHER_OFFERS_CAN_BE_PRESENT_IN_THE_CART')
        }

        if (Number(res.code) !== 101 && Number(res.code) !== 1090) {
            throw new Error('REQUEST_FAILED')
        }


        return res
    } catch (err) {
        throw err
    }
}
