import { getFingerAsync } from 'Lib/sxc/fingerprint'

export default async url => {
    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()

        const opts = {
            reqObj: {
                hash,
            },
        }

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.code !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
