export default function createUnitConfig({
    adCode,
    size,
    rubiconId,
    appnexusId,
    openxId,
    improvedigital,
    ix,
    smartadserver,
}) {
    return {
        code: adCode,
        mediaTypes: {
            banner: {
                sizes: [size],
            },
        },
        bids: [
            {
                bidder: 'rubicon',
                params: {
                    accountId: '10834',
                    siteId: '185540',
                    zoneId: rubiconId,
                },
            },
            {
                bidder: 'appnexus',
                params: { placementId: appnexusId },
            },
            {
                bidder: 'openx',
                params: {
                    unit: openxId,
                    delDomain: 'adasta-d.openx.net',
                },
            },
            {
                bidder: 'improvedigital',
                params: { placementId: improvedigital },
            },
            {
                bidder: 'ix',
                params: { siteId: ix, size },
            },

            {
                bidder: 'smartadserver',
                params: {
                    domain: '//prg.smartadserver.com',
                    siteId: 351981,
                    pageId: 1232904,
                    formatId: smartadserver,
                },
            },
        ],
    }
}
