import C from 'Lib/redux/constants'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { checkOrangeStatus } from 'Lib/sxc/orange/'

export default function useGetOrangeStatus(accessToken: string): void {
    const dispatch = useDispatch()
    /**
     *
     */
    useEffect(() => {
        if (!accessToken) {
            return
        }

        (async function () {
            //* UPDATE ORANGE VALUES
            try {
                const res = await checkOrangeStatus()
                dispatch({
                    type: C.ORANGE_CHECK_STATUS,
                    payload: res.data,
                })
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])
}
