import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { ProfileState } from 'Lib/types/profile'
import { Environment } from "../../../../config/webpack/environment";

const initialState: ProfileState = {
    accessToken: '',
    badge: '',
    bestshopping_id: 0,
    birthday: '',
    avatar: '',
    can_shop: 0,
    city_born: '',
    chat_token: '',
    country: Environment.defaultCountry,
    cover: '',
    current_user_welcome_credits_balance: 0,
    email: '',
    firstname: '',
    forceCardUsage: false,
    gender: 'm',
    has_refund: 0,
    has_user_welcome_credits: false,
    hobbies: '',
    initial_user_welcome_credits_balance: 0,
    is_fiscalcode: 0,
    is_all_accepted: 0,
    is_marketing_accepted: 0,
    is_privacy_accepted: 0,
    is_orange: 0,
    is_orange_premium: 0,
    is_limited_user: 0,
    job: '',
    language: Environment.defaultLanguage,
    lastname: '',
    loading: true,
    relationship: '',
    subscription_date: '',
    user_id: 0,
}

const ProfileReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.GET_PROFILE:
            draft.accessToken = payload.accessToken
            draft.avatar = payload.profile_image_thumb
            draft.badge = payload.badge
            draft.bestshopping_id = ~~payload.bestshopping_userid
            draft.birthday = payload.date_of_birth
            draft.can_shop = ~~payload.can_shop
            draft.city_born = payload.city_born
            draft.chat_token = payload.chat_token
            draft.country = payload.country
            draft.cover = payload.cover_img
            draft.current_user_welcome_credits_balance = payload.current_user_welcome_credits_balance
            draft.email = payload.email
            draft.firstname = payload.firstname
            draft.forceCardUsage = payload.force_card_usage
            draft.gender = payload.gender
            draft.has_user_welcome_credits = payload.has_user_welcome_credits
            draft.has_refund = payload.has_refund
            draft.hobbies = payload.hobbies
            draft.initial_user_welcome_credits_balance = payload.initial_user_welcome_credits_balance
            draft.is_all_accepted = payload.is_all_accepted
            draft.is_fiscalcode = payload.is_fiscalcode
            draft.is_marketing_accepted = payload.is_marketing_accepted
            draft.is_privacy_accepted = payload.is_privacy_accepted
            draft.is_orange = ~~payload.is_orange
            draft.is_orange_premium = ~~payload.is_orange_premium
            draft.is_limited_user = ~~payload.is_limited_user
            draft.job = payload.currently_working
            draft.language =
                payload.current_language?.toUpperCase() ||
                Environment.defaultLanguage.toUpperCase()
            draft.lastname = payload.lastname
            draft.relationship = payload.relationship
            draft.subscription_date = payload.created_at
            draft.user_id = ~~payload.user_id
            draft.loading = false
            break
        case C.CHANGE_LANGUAGE:
            draft.language = payload.toUpperCase()
            break
        case C.ORANGE_CHECK_STATUS:
            draft.is_orange = ~~payload.is_orange
            draft.is_orange_premium = ~~payload.is_orange_premium
            break
        case C.UPDATE_MARKETING:
            draft.is_marketing_accepted = payload
            break
        case C.UPDATE_PRIVACY:
            draft.is_all_accepted = payload.is_all_accepted
            draft.is_marketing_accepted = payload.is_marketing_accepted
            draft.is_privacy_accepted = payload.is_privacy_accepted
            break
        case C.UPDATE_SINGLE_PRIVACY:
            draft.is_privacy_accepted = payload
            break
        case C.USER_NOT_LOGGED:
            draft.country = Environment.defaultCountry
            draft.language = Environment.defaultLanguage.toUpperCase()
            draft.loading = false
            break
        case C.PROFILE_FISCALCODE:
            draft.is_fiscalcode = payload
            break
        case C.TIMELINE_UPDATE_COVER:
            draft.cover = payload
            break
        case C.LOGOUT:
            return { ...initialState }
        default:
            return draft
    }
})

export default ProfileReducer
