import C from 'Lib/redux/constants'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Row, Col, Typography, Badge, Drawer, Menu } from 'antd'
import { BellOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons'
import { useMedia } from 'react-use'
import { logout } from 'Lib/sxc/user'
import { getTotalBalance } from 'Lib/sxc/user'
import { formatPrice } from 'Lib/sxc/currency'
import { ButtonOrange } from 'Lib/components/Buttons'
import PendingCi from './components/PendingCi/PendingCi'
import Language from './components/Language/Language'
import Community from './components/Community'
import Cart from './components/Cart/Cart'

const { Text } = Typography

const Main: React.FC = () => {
    const intl = useIntl()
    const isMobile = useMedia('(max-width: 640px)')
    const userId = useSelector((s: RootState) => s.Profile.user_id)
    const location = useLocation()
    const [totalBalance, setTotalBalance] = useState(0)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        getTotalBalance().then(
            (res) => setTotalBalance(res.data.total),
            console.error,
        )
    }, [userId])

    if (location.pathname === '/new-privacy') {
        return null
    }

    if (location.pathname === '/cart') {
        return (
            <Row id="header">
                <Col sm={4} md={6} className="logo-elem">
                    <Logo />
                </Col>
            </Row>
        )
    }

    if (isMobile) {
        return (
            <>
                <PendingCi />
                <Row id="header">
                    <Col span={2}>
                        <MenuOutlined
                            onClick={() => setVisible(true)}
                            style={{ fontSize: 22 }}
                        />
                        <MobileDrawer
                            visible={visible}
                            setVisible={setVisible}
                        />
                    </Col>
                    <Col span={20} className="align-center">
                        <Logo />
                    </Col>
                    <Col span={2}>
                        <Cart />
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <PendingCi />

            <Row id="header">
                <Col sm={4} md={userId ? 4 : 6} className="logo-elem">
                    <Logo />
                </Col>

                <Col sm={24} md={userId ? 10 : 12}>
                    <Row gutter={{ sm: 30, md: 50 }} justify="center">
                        <Col>
                            <Shop />
                        </Col>
                        <Col>
                            <Community />
                        </Col>
                        <Col>
                            <Howitworks />
                        </Col>
                        <Col>
                            <Language />
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={userId ? 10 : 6}>
                    {userId ? (
                        <Row gutter={30} justify="end">
                            <Col>
                                <Notifications />
                            </Col>
                            <Col>
                                <Profile />
                            </Col>
                            <Col>
                                <Account />
                            </Col>
                            <Col>
                                <Text className="color-orange unclickable">
                                    {intl.messages['Wallet.balance']}:{' '}
                                    {formatPrice({ price: totalBalance })}
                                </Text>
                            </Col>
                            <Col>
                                <Cart />
                            </Col>
                            <Col>
                                <Wishlist />
                            </Col>
                            <Col>
                                <Logout />
                            </Col>
                        </Row>
                    ) : (
                        <Row justify="end">
                            <Col>
                                <Signin />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    )
}

export default Main

/**
 *
 * @returns
 */
const Logo: React.FC = () => {
    const history = useHistory()

    return <div onClick={() => history.push('/')} className="logo"></div>
}

/**
 *
 * @returns
 */
const Shop: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    const intl = useIntl()

    const isShop =
        location.pathname === '/shopping' ||
        location.pathname.includes('smartvoucher')

    return (
        <Text
            onClick={() => history.push('/shopping')}
            className={isShop ? 'active' : ''}
        >
            {intl.messages['Common.shop']}
        </Text>
    )
}

/**
 *
 * @returns
 */
const Howitworks: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    const intl = useIntl()

    const isHIW = location.pathname === '/howitworks'
    return (
        <Text
            onClick={() => history.push('/howitworks')}
            className={isHIW ? 'active' : ''}
        >
            {intl.messages['Common.howitworks']}
        </Text>
    )
}

/**
 *
 * @returns
 */
const Signin: React.FC = () => {
    const dispatch = useDispatch()
    const intl = useIntl()

    return (
        <Text onClick={() => dispatch({ type: C.AUTH_SET, payload: 'signin' })}>
            {intl.messages['Common.login']}
        </Text>
    )
}

/**
 *
 * @returns
 */
const Account: React.FC = () => {
    const history = useHistory()
    const intl = useIntl()

    return (
        <ButtonOrange
            onClick={() => history.push('/user/mywallet')}
            className="account-btn"
        >
            {intl.messages['Common.wallet']}
        </ButtonOrange>
    )
}

/**
 *
 * @returns
 */
const Wishlist: React.FC = () => {
    const history = useHistory()

    return (
        <span
            onClick={() => history.push('/wishlist')}
            className="wishlist fa fa-heart-o header-icon"
            style={{ cursor: 'pointer' }}
        ></span>
    )
}

/**
 *
 * @returns
 */
const Logout: React.FC = () => {
    return (
        <span onClick={doLogout} className="logout">
            <img
                src="/assets/images/icons/logout.svg"
                width="26"
                alt="logout"
            />
        </span>
    )
}

/**
 *
 * @returns
 */
const Notifications: React.FC = () => {
    const history = useHistory()
    const notifies = useSelector((s: RootState) => s.Notifications.merged)

    const unread = notifies?.filter((v: any) => !v.is_read)

    return (
        <span
            onClick={() => history.push('/notifications')}
            className="wishlist"
            style={{ cursor: 'pointer' }}
        >
            <Badge count={unread?.length}>
                <BellOutlined style={{ fontSize: '22px' }} />
            </Badge>
        </span>
    )
}

/**
 *
 * @returns
 */
const Profile: React.FC = () => {
    const history = useHistory()

    return (
        <span
            onClick={() => history.push('/profile/user')}
            className="wishlist"
            style={{ cursor: 'pointer' }}
        >
            <UserOutlined style={{ fontSize: '22px' }} />
        </span>
    )
}

const MobileDrawer: React.FC<{
    visible: boolean
    setVisible: (_: boolean) => void
}> = ({ visible, setVisible }) => {
    const intl = useIntl()
    const history = useHistory()
    const userId = useSelector((s: RootState) => s.Profile.user_id)

    return (
        <Drawer
            visible={visible}
            onClose={() => setVisible(false)}
            placement="left"
        >
            {userId ? (
                <Menu>
                    <Menu.Item
                        onClick={() => {
                            history.push('/user/mywallet')
                            setVisible(false)
                        }}
                    >
                        {intl.messages['Common.wallet']}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            history.push('/shopping')
                            setVisible(false)
                        }}
                    >
                        {intl.messages['Common.shop']}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            history.push('/notifications')
                            setVisible(false)
                        }}
                    >
                        {intl.messages['Common.notifications']}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            history.push('/profile/user')
                            setVisible(false)
                        }}
                    >
                        {intl.messages['Menuleft.myaccount']}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            history.push('/wishlist')
                            setVisible(false)
                        }}
                    >
                        {intl.messages['Wishlist.title']}
                    </Menu.Item>
                    <Menu.Item onClick={doLogout}>
                        {intl.messages['Common.logout']}
                    </Menu.Item>
                </Menu>
            ) : (
                <Signin />
            )}
        </Drawer>
    )
}

const doLogout = async () => {
    const opts = {
        reqObj: {
            access_token: window.TOKEN,
            session_id: window.SESSION_ID,
        },
    }

    await logout(opts)
    window.TOKEN = ''
    window.SESSION_ID = 0
    Object.keys(Cookies.get()).forEach((cookieName) => {
        Cookies.remove(cookieName)
    })

    localStorage.clear()
    window.location.href = '/'
}
