import C from 'Lib/redux/constants'
import moment from 'moment'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getPendingCI } from 'Lib/sxc/wallet'

export default function useGetPendingCi(accessToken: string): void {
    const dispatch = useDispatch()

    useEffect(() => {
        if (!accessToken) return
        ;(async () => {
            try {
                const opts = {
                    reqObj: {
                        user_id: window.SESSION_ID,
                    },
                }

                let pending
                const res = await getPendingCI(opts)
                if (!res.result.length) {
                    pending = { visible: false }
                } else {
                    const ci = res.result.reduce(
                        (tot: number, a: any) => ~~a.citizen_income_used + tot,
                        0,
                    )
                    const pts = res.result.reduce(
                        (tot: number, a: any) => ~~a.points + tot,
                        0,
                    )
                    const lastLock =
                        res.result.length === 1
                            ? res.result[0]
                            : res.result.sort((a: any, b: any) =>
                                  moment(a.time_init_h).unix() <
                                  moment(b.time_init_h).unix()
                                      ? 1
                                      : -1,
                              )[0]

                    /**
                     * calculate when to expire
                     */
                    const expireTime = moment(lastLock.time_init_h).add(30, 'm')
                    const expireFormatted = expireTime.format()

                    /**
                     * get the previous expire if exists
                     */
                    const oldExpireTime = sessionStorage.getItem(
                        'sxc.pendingci.hidden',
                    )

                    /**
                     * if they are different store the new one
                     */
                    if (expireFormatted !== oldExpireTime) {
                        sessionStorage.setItem(
                            'sxc.pendingci.hidden',
                            expireFormatted,
                        )
                    }

                    pending = {
                        credits: ci / 100,
                        points: pts,
                        time: expireTime.format('LT'),
                        visible:
                            moment().unix() <= expireTime.unix() &&
                            expireFormatted !== oldExpireTime,
                    }
                }

                dispatch({
                    type: C.WALLET_PENDINGCI,
                    payload: pending,
                })
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])
}
