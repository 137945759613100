import Joi from 'joi'
import { Environment } from '../../../../config/webpack/environment'

const schema = Joi.object({
    user_id: Joi.number(),
    offers_ids: Joi.array(),
}).options({ presence: 'required' })

export default async (opts: any) => {
    const { error, value } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}api/user-offers-wishlist?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        }).then((res) => res.json())

        if (res.code !== 200) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
