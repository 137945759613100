import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        gateway: Joi.string(),
        deviceType: Joi.string(),
        purchaseType: Joi.string(),
    }),
}).options({ presence: 'required' })

export default async opts => {
    const { error, value } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.payDomain}payment/payhub/create?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()

        value.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/html',
            },
            body: JSON.stringify(value),
        }).then(async res => {
            if (!res.ok) {
                throw new Error('REQUEST_FAILED')
            }

            const resType = await res
                .clone()
                .json()
                .then(() => 'isJson', () => 'isScript')

            if (resType === 'isJson') throw new Error('REQUEST_FAILED')

            return res.text()
        })

        return res
    } catch (err) {
        throw err
    }
}
