import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { createTypeAndName } from 'Lib/sxc/device/'
import { Environment } from "../../../../config/webpack/environment";

export default async () => {
    const token = window.TOKEN || window.TEMP_TOKEN
    const id = window.SESSION_ID || window.TEMP_ID
    const url = `${Environment.devicesDomain}device_register?access_token=${token}&session_id=${id}`

    const pathOptions = window.location.pathname
        .split('/')
        .filter(val => val !== '' && val !== 'confirm_phone')

    const user_id = !window.location.pathname.includes('confirm_phone')
        ? id
        : ~~pathOptions[0]

    const { name, type } = createTypeAndName()

    const opts = {
        user_id,
        name,
        type,
    }

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        return res.data.result === '503'
    } catch (err) {
        throw err
    }
}
