import createUnitConfig from './create.unit.config'
import initAdserver from './init.adserver'
import config from './config'
import { Environment } from "../../../../config/webpack/environment";

const pbjs = window.pbjs || {}

function getViewportWidth() {
    return Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0,
    )
}

function getViewportType() {
    var width = getViewportWidth()
    if (width >= 1141) return 'desktop'
    else if (width >= 1019) return 'tablet-landscape'
    else if (width >= 768) return 'tablet-portrait'
    else return 'phone'
}

export default function(category = null, brand = null) {
    if (!window.googletag) {
        return
    }

    pbjs.initAdserverSet = false

    // reset adunits
    try {
        window.googletag.destroySlots()
    } catch (err) {
        console.log(err)
    }
    pbjs.adUnits = []
    window.AdastaActiveUnitId = []

    const pageType = window.location.pathname
    const viewportType = getViewportType()

    if (
        !['desktop', 'tablet-landscape', 'tablet-portrait'].includes(
            viewportType,
        )
    ) {
        return
    }

    var adUnits = []

    pbjs.que.push(function() {
        /**
         * HOME
         */
        if (pageType === '/') {
            adUnits.push(
                createUnitConfig({
                    adCode: 'div-gpt-ad-1574340075369-0',
                    size: [970, 250],
                    rubiconId: '1692492',
                    appnexusId: '22277855',
                    openxId: '541060254',
                    improvedigital: '22277855',
                    ix: '503177',
                    smartadserver: 67558,
                }),
            )

            adUnits.push(
                createUnitConfig({
                    adCode: 'div-gpt-ad-1574270234483-0',
                    size: [160, 600],
                    rubiconId: '1692490',
                    appnexusId: '19141744',
                    openxId: '541060253',
                    improvedigital: '22277854',
                    ix: '503176',
                    smartadserver: 67566,
                }),
            )

            adUnits.push(
                createUnitConfig({
                    adCode: 'div-gpt-ad-1574262104458-0',
                    size: [300, 250],
                    rubiconId: '1692488',
                    appnexusId: '19141668',
                    openxId: '541060252',
                    improvedigital: '22277853',
                    ix: '503175',
                    smartadserver: 66400,
                }),
            )
        }

        /**
         * SHOPPING
         */
        if (pageType === '/shopping') {
            for (let i = 0; i < 20; i++) {
                adUnits.push(
                    createUnitConfig({
                        adCode: `div-gpt-ad-1574340237170-${i}`,
                        size: [300, 250],
                        rubiconId: '1692494',
                        appnexusId: '19141870',
                        openxId: '19141870',
                        improvedigital: '22277856',
                        ix: '503178',
                        smartadserver: 70122,
                    }),
                )

                adUnits.push(
                    createUnitConfig({
                        adCode: `div-gpt-ad-1574339706986-${i}`,
                        size: [970, 250],
                        rubiconId: '1692496',
                        appnexusId: '19141872',
                        openxId: '541060257',
                        improvedigital: '22277857',
                        ix: '503179',
                        smartadserver: 74025,
                    }),
                )
            }
        }

        /**
         * VOUCHER
         */
        if (pageType.includes('/smartvoucher')) {
            adUnits.push(
                createUnitConfig({
                    adCode: 'div-gpt-ad-1574268061154-0',
                    size: [300, 250],
                    rubiconId: '1692502',
                    appnexusId: '19141882',
                    openxId: '541060265',
                    improvedigital: '22277861',
                    ix: '503182',
                    smartadserver: 67311,
                }),
            )

            adUnits.push(
                createUnitConfig({
                    adCode: 'div-gpt-ad-1574276124796-0',
                    size: [970, 250],
                    rubiconId: '1692504',
                    appnexusId: '19141888',
                    openxId: '541060266',
                    improvedigital: '22277862',
                    ix: '503183',
                    smartadserver: 78549,
                }),
            )
        }

        /**
         * USER / SOCIAL
         */
        if (pageType.includes('/user')) {
            adUnits.push(
                createUnitConfig({
                    adCode: 'div-gpt-ad-1574267641597-0',
                    size: [160, 600],
                    rubiconId: '1692506',
                    appnexusId: '19141893',
                    openxId: '541060267',
                    improvedigital: '22277864',
                    ix: '503184',
                    smartadserver: 67117,
                }),
            )

            adUnits.push(
                createUnitConfig({
                    adCode: 'div-gpt-ad-1574268302888-0',
                    size: [160, 600],
                    rubiconId: '1692508',
                    appnexusId: '19141897',
                    openxId: '541060268',
                    improvedigital: '22277866',
                    ix: '503185',
                    smartadserver: 66779,
                }),
            )

            adUnits.push(
                createUnitConfig({
                    adCode: 'div-gpt-ad-1574275810832-0',
                    size: [970, 250],
                    rubiconId: '1692510',
                    appnexusId: '19141898',
                    openxId: '541060269',
                    improvedigital: '22277869',
                    ix: '503186',
                    smartadserver: 80372,
                }),
            )
        }

        pbjs.setConfig(config)
        pbjs.addAdUnits(adUnits)

        const customConfigObject = {
            buckets: [
                {
                    precision: 2,
                    min: 0,
                    max: 1,
                    increment: 0.01,
                },
                {
                    precision: 2,
                    min: 1,
                    max: 3,
                    increment: 0.05,
                },
                {
                    precision: 2,
                    min: 3,
                    max: 7,
                    increment: 0.1,
                },
                {
                    precision: 2,
                    min: 7,
                    max: 30,
                    increment: 0.5,
                },
            ],
        }
        pbjs.setConfig({
            deviceAccess: window.pbjsCookies,
            consentManagement: {
                gdpr: {
                    cmpApi: 'iab',
                    timeout: 10000,
                    defaultGdprScope: false,
                },
            },
            priceGranularity: customConfigObject,
        })

        pbjs.requestBids({
            bidsBackHandler: initAdserver,
        })
    })

    setTimeout(initAdserver, 3000)

    window.googletag.cmd.push(function() {
        if (pageType === '/') {
            window.googletag
                .defineSlot(
                    '/21859074942/300x250hp',
                    [300, 250],
                    'div-gpt-ad-1574262104458-0',
                )
                .addService(window.googletag.pubads())

            window.googletag
                .defineSlot(
                    '/21859074942/120x600HP_DX_STF-SIXTHCONTINENT(87245):',
                    [160, 600],
                    'div-gpt-ad-1574270234483-0',
                )
                .addService(window.googletag.pubads())
        }

        if (pageType === '/shopping') {
            for (let i = 0; i < 20; i++) {
                window.googletag
                    .defineSlot(
                        '/21859074942/300x250CAT_CENTER_STF-SIXTHCONTINENT(87247)',
                        [300, 250],
                        `div-gpt-ad-1574340237170-${i}`,
                    )
                    .addService(window.googletag.pubads())
                window.googletag
                    .defineSlot(
                        '/21859074942/970x250CAT_CENTER_STF-SIXTHCONTINENT(87249)',
                        [970, 250],
                        `div-gpt-ad-1574339706986-${i}`,
                    )
                    .addService(window.googletag.pubads())
            }
        }

        if (pageType.includes('/smartvoucher')) {
            window.googletag
                .defineSlot(
                    '/21859074942/300x250GIFT_SX_BTF_1-SIXTHCONTINENT(87251)',
                    [[300, 250], [300, 600]],
                    'div-gpt-ad-1574268061154-0',
                )
                .addService(window.googletag.pubads())
            window.googletag
                .defineSlot(
                    '/21859074942/970x250GIFT_CENTER_STF-SIXTHCONTINENT(87250)',
                    [970, 250],
                    'div-gpt-ad-1574276124796-0',
                )
                .addService(window.googletag.pubads())
        }

        if (pageType.includes('/user')) {
            window.googletag
                .defineSlot(
                    '/21859074942/300x250_SOCIAL_SX_STF_2-SIXTHCONTINENT(87270)',
                    [160, 600],
                    'div-gpt-ad-1574267641597-0',
                )
                .addService(window.googletag.pubads())
            window.googletag
                .defineSlot(
                    '/21859074942/300x600SOCIAL_SX_STF_1-SIXTHCONTINENT(87269)',
                    [160, 600],
                    'div-gpt-ad-1574268302888-0',
                )
                .addService(window.googletag.pubads())
            window.googletag
                .defineSlot(
                    '/21859074942/970x250SOCIAL_CENTER_ATF-SIXTHCONTINENT(87267)',
                    [970, 250],
                    'div-gpt-ad-1574275810832-0',
                )
                .addService(window.googletag.pubads())
        }
    })

    if (category) {
        window.googletag.pubads().setTargeting('categoria', [category])
    }

    if (brand) {
        window.googletag.pubads().setTargeting('brand', [brand])
    }

    /**  */
    const ct = Environment.defaultCountry
    window.googletag.pubads().setTargeting('country', ct)

    if (window.googletag.pubads) {
        window.googletag.pubads().disableInitialLoad()
        window.googletag.pubads().enableSingleRequest()
        window.googletag.pubads().collapseEmptyDivs()
        window.googletag.enableServices()
    }
}

export const refreshBid = () => {
    pbjs.que.push(function() {
        pbjs.requestBids({
            timeout: 3000,
            adUnitCodes: pbjs.adUnits,
            bidsBackHandler: function() {
                pbjs.setTargetingForGPTAsync(pbjs.adUnits)
                window.googletag.pubads().refresh()
            },
        })
    })
}
