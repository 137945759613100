/**
 *
 * @param {*} searchUrl
 * @param {*} searchString
 * @param {*} userId
 * @param {*} accessToken
 */
import { Environment } from "../../../../config/webpack/environment";

export default async function SearchFriend(searchString, userId, accessToken) {
    const url = `${Environment.apiDomain}api/searchfriends?access_token=${accessToken}&session_id=${userId}`

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                reqObj: {
                    friend_name: searchString,
                    limit_size: 1000,
                    limit_start: 0,
                    session_id: userId,
                    skip_save_onls: true,
                    user_id: userId,
                },
            }),
        }).then((response) => response.json())

        return res
    } catch (err) {
        console.log(err)
    }
}
