/**
 *
 * @param {*} user
 */
export const blockUser = user =>
    new Promise((resolve, reject) => {
        window.sb.blockUser(user, (response, error) => {
            if (error) {
                reject(error)
            }

            resolve(response)
        })
    })

/**
 *
 * @param {*} user
 */
export const unblockUser = user =>
    new Promise((resolve, reject) => {
        window.sb.unblockUser(user, (response, error) => {
            if (error) {
                reject(error)
            }

            resolve(user)
        })
    })
