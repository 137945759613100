import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import debugApi from 'Lib/sxc/debug.api'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        cart: Joi.array().items(
            Joi.object({
                activation_time: Joi.string(),
                activation_type: Joi.string(),
                bundle: Joi.array().optional(),
                card_id: Joi.string(),
                ciFromUsableReturned: Joi.number(),
                credits: Joi.number(),
                currency: Joi.string(),
                deliveryType: Joi.string(),
                description: Joi.string(),
                earn: Joi.number(),
                img: Joi.string(),
                isBundle: Joi.bool().optional(),
                isFirstMonthMandatoryPremium: Joi.bool().optional(),
                isFirstMonthMinimumAmount: Joi.number().optional(),
                isMandatoryPremium: Joi.bool(),
                isSxcCardsBuyable: Joi.bool(),
                isSxcTicketsBuyable: Joi.bool(),
                itemId: Joi.number(),
                itemName: Joi.string(),
                maxQty: Joi.number(),
                isPromotionalCodeLimited: Joi.bool().allow(null).optional(),
                merchant_iab1: Joi.string().allow(null, ''),
                merchant_iab2: Joi.string().allow(null, ''),
                merchant_url: Joi.string().allow(null, ''),
                minQty: Joi.number(),
                offer_id: Joi.number(),
                points: Joi.number(),
                premiumData: Joi.array().optional(),
                productType: Joi.string(),
                quantity: Joi.number(),
                ticketCharge: Joi.number(),
                ticketUsage: Joi.object().optional(),
                total: Joi.number(),
                totalWithCp: Joi.number(),
                use_card: Joi.bool(),
                use_credit: Joi.number(),
                use_ticket: Joi.bool(),
                value: Joi.number(),
                walletUsage: Joi.object().optional(),
                personalCardId: Joi.string().optional(),
            }),
        ),
    }),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error, errors, value } = schema.validate(opts)
    debugApi(error, errors, value)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.payDomain}api/previewcart?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`
    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        value.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        }).then((res) => res.json())

        if (res.code === 1105) {
            throw new Error('VOUCHER_NOT_ACTIVE')
        }

        if (res.code === 1115) {
            throw new Error('VOUCHER_PREMIUM_NOT_ACTIVE')
        }

        if (res.code === 1037) {
            throw new Error('USER_LIMITED')
        }

        if (res.code === 1043) {
            throw new Error('USER_DISABLED')
        }

        if (res.code === 1052) {
            throw new Error('EMAIL_TO_VALIDATE')
        }

        if (res.code === 1090) {
            throw new Error('DEVICE_NOT_REGISTERED')
        }

        if (res.code !== 101 && res.code !== 102) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
