import React from 'react'
import { useIntl } from 'react-intl'
import { Row, Col, Modal } from 'antd'
import { ButtonOrange } from 'Lib/components/Buttons/'
import useGetBadges from './hooks/useGetBadges'

const Badges: React.FC = () => {
    const intl = useIntl()
    const [badges, current, setCurrent, updateBadge] = useGetBadges()

    if (!badges.length || !badges[current]) {
        return null
    }

    return (
        <Modal centered visible onCancel={() => setCurrent(-1)} footer={null}>
            {badges.map((v: any, indx: number) => {
                if (current !== indx) return null

                return (
                    <Row key={v.id} gutter={[20, 20]}>
                        <Col span={24} className="align-center">
                            {intl.messages['Badges.congratulations']}
                        </Col>
                        <Col span={24} className="align-center">
                            <div
                                className={`badge-listing-icon ${v.badge_name}`}
                            >
                                <i className="fa w-100 text-center"></i>
                            </div>
                            {intl.messages[`Badges.name.${v.badge_name}`]}
                        </Col>
                        <Col span={24} className="align-center">
                            <ButtonOrange onClick={updateBadge}>
                                {intl.messages['Common.accept']}
                            </ButtonOrange>
                        </Col>
                    </Row>
                )
            })}
        </Modal>
    )
}

export default Badges
