import { useState, useEffect } from 'react'

// If password contains both lower and uppercase characters, increase strength value.
export const lowerAndUpper = (password: string): boolean =>
    !!(password.match(/([a-z])/) && password.match(/([A-Z])/))

// If it has numbers and characters, increase strength value.
export const numberAndCharacters = (password: string): boolean =>
    !!(password.match(/([a-zA-Z])/) && password.match(/([0-9])/))

// If it has one special character, increase strength value.
export const symbols = (password: string): boolean =>
    !!password.match(/([!,=,%,&,@,#,$,^,*,?,_,~])/)

export default function useCheckPasswordStrength(): any {
    const [password, setPassword] = useState('')
    const [passwordScore, setPasswordScore] = useState(0)
    const [passwordStrength, setPasswordStrength] = useState({
        isUpperAndLowerCase: false,
        isNumbAndChars: false,
        isSymbols: false,
        isGreatherThan7: false,
    })

    useEffect(() => {
        if (lowerAndUpper(password)) {
            setPasswordStrength((prevState) => ({
                ...prevState,
                isUpperAndLowerCase: true,
            }))
        } else {
            setPasswordStrength((prevState) => ({
                ...prevState,
                isUpperAndLowerCase: false,
            }))
        }

        if (numberAndCharacters(password)) {
            setPasswordStrength((prevState) => ({
                ...prevState,
                isNumbAndChars: true,
            }))
        } else {
            setPasswordStrength((prevState) => ({
                ...prevState,
                isNumbAndChars: false,
            }))
        }

        if (symbols(password)) {
            setPasswordStrength((prevState) => ({
                ...prevState,
                isSymbols: true,
            }))
        } else {
            setPasswordStrength((prevState) => ({
                ...prevState,
                isSymbols: false,
            }))
        }

        if (password.length > 7) {
            setPasswordStrength((prevState) => ({
                ...prevState,
                isGreatherThan7: true,
            }))
        } else {
            setPasswordStrength((prevState) => ({
                ...prevState,
                isGreatherThan7: false,
            }))
        }
    }, [password])

    useEffect(() => {
        let score = 0
        Object.values(passwordStrength).map((p) => {
            if (p) score += 1
        })
        setPasswordScore(score)
    }, [passwordStrength])

    return { setPassword, passwordStrength, passwordScore }
}
