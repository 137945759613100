export default {
    debug: false,
    deviceAccess: window.pbjsCookies,
    consentManagement: {
        gdpr: {
            cmpApi: 'iab',
            timeout: 10000,
            // allowAuctionWithoutConsent: true,
            lookUpFailureResolution: 'cancel',
            defaultGdprScope: false,
        },
    },
    userSync: {
        iframeEnabled: true,
        filterSettings: {
            iframe: {
                bidders: [
                    'appnexus',
                    'smartadserver',
                    'improvedigital',
                    'rubicon',
                    'openx',
                    'index',
                ],
                filter: 'include',
            },
        },
        syncDelay: 5000,
    },
    schain: {
        validation: 'strict',
        config: {
            ver: '1.0',
            complete: 1,
            nodes: [
                {
                    asi: 'adasta.it',
                    sid: '106',
                    hp: 1,
                },
            ],
        },
    },
}
