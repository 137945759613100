import base64 from 'base-64'
/**
 * Random Password generator
 * for Amazon and Facebook Login
 */
export default function() {
    const part1 = Math.random()
        .toString(36)
        .slice(-6)

    const part2 = Math.random()
        .toString(36)
        .toUpperCase()
        .slice(-1)

    const part3 = Math.random()
        .toString(36)
        .toUpperCase()
        .slice(-1)

    const part4 = Math.random()
        .toString(36)
        .toUpperCase()
        .slice(-1)

    const part5 = Math.random()
        .toString(10)
        .slice(-1)

    const part6 = '@'

    return base64.encode(`${part1}${part2}${part3}${part4}${part5}${part6}`)
}
