import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        username: Joi.string().email({ tlds: { allow: false } }),
        password: Joi.string(),
    }),
}).options({ presence: 'required' })

export default async (opts = {}) => {
    const { error } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}api/logins?access_token=${window.TOKEN}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.code !== 101) {
            const error = new Error('REQUEST_FAILED')
            error.code = 'RequestFailed'
            throw error
        }

        window.SESSION_ID = res.data.id
        localStorage.setItem('session_id', res.data.id)

        return res
    } catch (err) {
        throw err
    }
}
