import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { shuffle } from 'Lib/sxc/js.utils'
import { getCurrencySymbol } from 'Lib/sxc/currency'
import { getDateOnly } from 'Lib/sxc/date.utils'
import { VoucherState, PremiumRestApi } from 'Lib/types/voucher'
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'Lib/redux/reducers';

const initialState: VoucherState = {
    activationMethod: 'default',
    activationTime: '',
    availableCi: 0,
    availablePt: 0,
    avgRating: 0,
    buyLimit: '',
    bypassPremiumBehaviour: 0,
    bypassOrangePremiumInstantActivation: 0,
    bypassOrangePremiumFreeShipping: 0,
    cardPersonalBuyLimit: 999,
    cardRequired: 0,
    cardWallet: {},
    countRating: 0,
    creditbackAsFixed: 0,
    creditBonusAmount: 0,
    creditSelection: 1,
    currency: '',
    deliveryTime: null,
    deliveryType: '',
    deliveryTypeWallet: '',
    disableCredit: false,
    firstMonthMandatoryPremium: false,
    firstMonthMinimumAmount: 0,
    img: '',
    isDeliveryInSpecifcDate: false,
    isDelta: 0,
    isOrange: 0,
    isOrangePremium: 0,
    isWelcomeCard: false,
    longDescription: '',
    mandatoryCi: 0,
    mandatoryCiType: '',
    mandatoryPremium: false,
    mandatoryPremiumNumber: 0,
    minQty: 0,
    maxQty: 0,
    name: '',
    offerAmount: {},
    offer_id: 0,
    options: [],
    orangeStart: {},
    orangeEnd: {},
    pan: '',
    premium: { added: [], price: 0, earn: 0, usable: 0 },
    premiumAssociated: [],
    price: 0,
    priceValue: 0,
    quantitySelected: 1,
    shortDescription: '',
    timeEndH: {},
    selectedOption: null,
    sellerId: 0,
    shippingCost: 0,
    sxcTicket: 0,
    totalEarn: 0,
    usedWelcomeCredit: 0,
}

const VoucherReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.GIFTCARD_CI_MANDATORY:
            //
            draft.mandatoryCi = draft.selectedOption.ci_mandatory_value
            //
            draft.mandatoryCiType = draft.selectedOption.ci_usable_type
            break
        case C.GIFTCARD_CHOOSE_PAN:
            draft.pan = payload
            break
        case C.GIFTCARD_CREDIT_SELECTION:
            draft.creditSelection = payload.creditSelection
            break
        case C.GIFTCARD_DELIVERY_TIME:
            //
            if (
                (draft.isMandatoryPremium ||
                    draft.firstMonthMandatoryPremium) &&
                draft.deliveryType !== 'physical' &&
                !draft.bypassPremiumBehaviour
            ) {
                draft.deliveryTime = '0'
            } else {
                const date = getDateOnly(draft.activationTime || '')
                draft.deliveryTime = date
                draft.isDeliveryInSpecifcDate = date.length > 3
            }
            break
        case C.GIFTCARD_INIT:
            draft.activationTime = payload.extra_information.activation_time
            draft.avgRating = payload.avg_rating
            draft.buyLimit = payload.limit
            draft.bypassPremiumBehaviour =
                payload.promotion_type.bypassPremiumBehaviour ?? 0
            draft.bypassOrangePremiumInstantActivation =
                payload.promotion_type.bypassOrangePremiumInstantActivation ?? 0
            draft.bypassOrangePremiumFreeShipping =
                payload.promotion_type.bypassOrangePremiumFreeShipping ?? 0
            draft.cardRequired = payload.promotion_type.cardRequired ?? 0
            draft.cardWallet = payload.cardWallet
            draft.countRating = payload.count_rating
            draft.creditbackAsFixed =
                payload.promotion_type.creditbackAsFixed ?? 0
            draft.creditBonusAmount =
                payload.promotion_type.creditBonusAmount ?? 0
            draft.currency = getCurrencySymbol(
                payload.extra_information.base_currency,
            )
            draft.deliveryType =
                payload.promotion_type.deliveryType === 'precharged'
                    ? 'digital'
                    : payload.promotion_type.deliveryType
            draft.deliveryTypeWallet = payload.promotion_type.deliveryTypeWallet
            draft.disableCredit = !!(
                payload.promotion_type.disableCredit || payload.cardWallet.delta
            )
            draft.firstMonthMandatoryPremium = payload.logged
                ? payload.promotion_type.isFirstMonthMandatoryPremium &&
                  !payload.cardWallet.monthPurchases
                : !!payload.promotion_type.isFirstMonthMandatoryPremium
            draft.firstMonthMinimumAmount = payload.logged
                ? !payload.cardWallet.monthPurchases
                    ? (payload.promotion_type.isFirstMonthMinimumAmount ?? 0) /
                      100
                    : 0
                : (payload.promotion_type.isFirstMonthMinimumAmount ?? 0) / 100
            draft.img = payload.extra_information.landscape_image ?? ''
            draft.isDelta = payload.promotion_type.deltaCi ?? 0
            draft.isOrange = payload.promotion_type.isOrange ?? 0
            draft.isOrangePremium = payload.promotion_type.isOrangePremium ?? 0
            draft.isWelcomeCard = payload.promotion_type.isUsersWelcomeCreditBuyable ?? 0
            draft.longDescription =
                payload.extra_information.merchant_long_description
                    .split('<a')
                    .join('<a target="_blank"') ?? ''
            //
            draft.mandatoryPremium =
                payload.promotion_type.isMandatoryPremium ?? false
            draft.mandatoryPremiumNumber =
                payload.promotion_type.mandatoryPremiumNumber ?? 0
            draft.name = payload.extra_information.retailer_name ?? ''
            draft.offer_id = payload.id ?? 0
            draft.options = payload.extra_information.single_cards ?? []
            draft.orangeStart =
                payload.promotion_type.orangePublicTimeStart ?? {}
            draft.orangeEnd = payload.promotion_type.orangePublicTimeEnd ?? {}
            draft.premiumAssociated = shuffle(
                payload.extra_information.bundle.filter(
                    (v: any) => v.type === 'premium',
                )[0]?.data || [],
            )
            draft.selectedOption = draft.options[0]
            draft.shortDescription =
                payload.extra_information.merchant_short_description ?? ''
            draft.timeEndH = payload.timeEndH ?? {}
            draft.sellerId = payload.sellerId ?? 0
            /**
             * re assign personal limit if data.cards_buyable
             */
            if (payload.limit !== 0 && payload.buyLimit !== 'limited') {
                draft.cardPersonalBuyLimit = payload.limit
            }
            break
        case C.GIFTCARD_OFFER_AMOUNT:
            draft.availablePt = draft.availablePt || payload.userPtAmount
            draft.availableCi = draft.availableCi || payload.userCiAmount

            if (payload.use_ci) {
                draft.offerAmount[1] = payload.data
            } else {
                draft.offerAmount[0] = payload.data
            }
            break
        case C.GIFTCARD_PRICE:
            //
            draft.price = payload.logged
                ? draft.selectedOption.card_amount
                : draft.selectedOption.card_amount

            //
            draft.priceValue = payload.logged
                ? draft.selectedOption.card_amount * draft.quantitySelected +
                  draft.premium.price
                : draft.options[0].card_amount
            break
        case C.GIFTCARD_QTY_SELECTED:
            draft.minQty = draft.selectedOption.buy_quantity_minimum_limit || 1
            draft.maxQty = draft.selectedOption.buy_quantity_maximum_limit || 1

            if (payload === 'max') {
                draft.quantitySelected =
                    draft.selectedOption?.buy_quantity_maximum_limit || 1
            } else {
                draft.quantitySelected =
                    draft.selectedOption.buy_quantity_minimum_limit || 1
            }
            break
        case C.GIFTCARD_SELECT_OPTION:
            draft.selectedOption = payload
            break
        case C.GIFTCARD_SELECT_QTY:
            draft.quantitySelected = payload
            break
        case C.GIFTCARD_SHIPPING_COST:
            /*
             * Digital cards don't need shipping calculation
             */
            if (draft.deliveryType === 'digital') return

            /**
             * If bypassPremiumBehaviour is not active, shipping = 0
             */
            if (
                (draft.premium?.added?.length ||
                    draft.mandatoryPremium ||
                    draft.firstMonthMandatoryPremium) &&
                !draft.bypassPremiumBehaviour
            ) {
                draft.shippingCost = 0
                return
            }

            /**
             * If bypassPremiumBehaviour is not active:
             * if quantity is enough >= shipping_qty bypass shipping cost
             */
            if (
                draft.selectedOption.shipping_quantity <=
                    draft.quantitySelected &&
                !draft.bypassPremiumBehaviour
            ) {
                draft.shippingCost = 0
                return
            }

            /**
             * otherwise
             */
            draft.shippingCost = draft.selectedOption.shipping_cost
            break
        case C.GIFTCARD_TICKET_CASHBACK:
            {
                /* first get the ticket from the main voucher */
                const vouchetTicketAmount =
                    draft.selectedOption.sxc_ticket_value *
                    draft.quantitySelected

                /* then retrieve all premium added info */
                const details = draft.premium.added.map((v: any) =>
                    draft.premiumAssociated.find(
                        (v2: PremiumRestApi) =>
                            v2.voucherCardId === v.voucherCardId,
                    ),
                )

                /* add ticket + eventually premium ticket */
                draft.sxcTicket = details.reduce(
                    (a: any, b: any) => a + b.sxc_ticket_value,
                    vouchetTicketAmount,
                )
            }
            break
        case C.GIFTCARD_TICKET_MANDATORY:
            draft.isTicketMandatory = draft.selectedOption.isTicketMandatory
            break
        case C.GIFTCARD_TOGGLE_ACTIVATION:
            draft.activationMethod = payload
            if (payload === 'default') {
                draft.premium.added = []
                draft.premium.price = 0
                draft.premium.earn = 0
                draft.premium.usable = 0
            }
            break
        case C.GIFTCARD_TOGGLE_CREDIT:
            draft.creditSelection = payload
            break
        case C.GIFTCARD_TOGGLE_PREMIUM: {
            const index = draft.premium.added.find(
                (v: any) => v.voucherCardId === payload.voucherCardId,
            )

            if (index) {
                draft.premium.added = [
                    ...draft.premium.added.filter(
                        (v: any) => v.voucherCardId !== payload.voucherCardId,
                    ),
                ]
                draft.premium.price -= payload.voucherPrice
                draft.premium.earn -= payload.buyerCharge * payload.voucherPrice
                draft.premium.usable -= payload.ci_usable_value
            } else {
                draft.premium.added.push(payload)
                draft.premium.price += payload.voucherPrice
                draft.premium.earn += payload.buyerCharge * payload.voucherPrice
                draft.premium.usable += payload.ci_usable_value
            }
            break
        }
        case C.GIFTCARD_WELCOME_CREDIT:
            draft.usedWelcomeCredit =
                draft.isDelta || draft.disableCredit || draft.offer_id === 1337
                    ? 0
                    : 5
            break
        case C.ROUTE_CHANGE:
            return { ...initialState }
        default:
            return draft
    }

})

export default VoucherReducer
