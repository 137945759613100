import { Environment } from "../../../../config/webpack/environment";

export default async (invoiceId) => {
    const url = `${Environment.payDomain}api/deleteinvoiceaddress/${invoiceId}?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json())

        if (res.code === 1090) {
            throw new Error('DEVICE_TO_VALIDATE')
        }

        if (res.code !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
