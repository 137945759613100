import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { BSState } from 'Lib/types/bestshopping'

const initialState: BSState = {
    filter: 'suggested',
    query: '*',
}

const BestshoppingReducer = produce(
    (draft = initialState, { type, payload }) => {
        switch (type) {
            case C.BESTSHOPPING_FILTER:
                draft.filter = payload
                break
            case C.BESTSHOPPING_SEARCH:
                draft.query = payload
                break
            case C.LOGOUT:
                return { ...initialState }
            default:
                return draft
        }
    },
)

export default BestshoppingReducer
