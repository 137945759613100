import { Environment } from "../../../../../config/webpack/environment";

export default async function initAmzSdk(): Promise<void> {
    let lang = ''
    let client = ''
    let seller = ''
    const country = Environment.defaultCountry.toLowerCase()

    if (country == 'us') {
        client = Environment.amzClientId
        seller = Environment.amzSellerId
    } else {
        client = Environment.amzClientIdEu
        seller = Environment.amzSellerIdEu
    }

    const setAmzClientId = () =>
        new Promise((resolve) => {
            try {
                amazon.Login.setClientId(client)
                resolve(true)
            } catch (error) {
                window.setTimeout(() => resolve(setAmzClientId()), 1000)
            }
        })

    await setAmzClientId()

    if (country == 'it') {
        lang = 'it-IT'
    } else if (country == 'de') {
        lang = 'de-DE'
    } else {
        lang = 'en-GB'
    }

    OffAmazonPayments.Button('LoginWithAmazon', seller, {
        type: 'LwA',
        color: 'Gold',
        size: 'large',
        language: lang,
    })
}
