import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { getNewBadges, updateBadges } from 'Lib/sxc/user'

export default function useGetBadges(): any[] {
    const ismounted = useRef(true)
    const userId = useSelector((s: RootState) => s.Profile.user_id)
    const [badges, setBadges] = useState([])
    const [current, setCurrent] = useState(0)

    /**
     * fist loading
     */
    useEffect(() => {
        return () => {
            ismounted.current = false
        }
    }, [])

    /**
     *
     */
    useEffect(() => {
        if (!userId) return
        ;(async function () {
            const opts = {
                reqObj: {
                    user_id: userId,
                    profile_type: 4,
                },
            }

            try {
                const res = await getNewBadges(opts)
                if (ismounted.current && res.data.length) {
                    setBadges(res.data)
                }
            } catch (err) {
                console.log(err)
            }
        })()
    }, [userId])

    /**
     *
     */
    async function updateBadge() {
        const opts = {
            reqObj: {
                user_id: userId,
                badge_id: badges[current].id,
            },
        }
        try {
            /* move the current badge to show */
            setCurrent(current + 1)
            /* erase the badge */
            await updateBadges(opts)
        } catch (err) {
            console.log(err)
        }
    }

    return [badges, current, setCurrent, updateBadge]
}
