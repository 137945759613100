import C from 'Lib/redux/constants'
import produce from 'immer'
import { getCurrencySymbol } from 'Lib/sxc/currency'
import { getCartLength } from 'Lib/sxc/cart'
import { CartState, CartItemType } from 'Lib/types/cart'

const initialState: CartState = {
    cart_id: '',
    cart: [],
    charges: {},
    currency: '',
    incomingCredits: null,
    isAmazonPayBuyable: false,
    isBitsaPayBuyable: false,
    isSxcCardsBuyable: false,
    isSxcTicketsBuyable: false,
    isTicketMandatory: false,
    length: 0,
    shipping: null,
    sxcCardWallet: 0,
    sxcTicketAvailable: 0,
    totalDiscount: {
        ciUsedTotal: 0,
        ptUsedTotal: 0,
        totalSxcCardAmount: 0,
        totalSxcTicketAmount: 0,
    },
    totalToPay: 0,
    totalUsedWelcomeCreditAmount: 0,
    userCiAmount: 0,
    userPtAmount: 0,
    totalValue: 0,
    use_card: false,
    use_ticket: false,
    calculating: false,
    allowedPaymentMethods: [],
}

const DatalayerReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.CART_AMAZON_PAYABLE:
            draft.isAmazonPayBuyable = payload
            break
        case C.CART_CHANGE_QUANTITY:
            draft.cart = [
                ...draft.cart.map((val: CartItemType) => {
                    if (val.card_id === payload.id) {
                        val.quantity = payload.qty
                    }

                    return val
                }),
            ]
            draft.calculating = true
            break
        case C.CART_GET:
            draft.cart_id = payload.data.cart_id
            draft.cart = payload.data.cart
            draft.charges = payload.data.totalChargeData || {}
            draft.currency = getCurrencySymbol(payload.data.currency)
            draft.incomingCredits = payload.data.incomingCredits || null
            draft.isBitsaPayBuyable = payload.data.isBitsaPayBuyable
            draft.isSxcCardsBuyable = payload.data.isSxcCardsBuyable
            draft.isSxcTicketsBuyable = payload.data.isSxcTicketsBuyable
            draft.isTicketMandatory = payload.data.isTicketMandatory
            draft.length = getCartLength(payload.data)
            draft.shipping = payload.data.shipping ?? null
            draft.sxcCardWallet = payload.data.sxcCardWallet
            draft.sxcTicketAvailable = payload.data.sxcTicketAvailable
            draft.totalDiscount.ciUsedTotal =
                payload.data.totalDiscount.ciUsedTotal
            draft.totalDiscount.ptUsedTotal =
                payload.data.totalDiscount.ptUsedTotal
            draft.totalDiscount.totalSxcCardAmount =
                payload.data.totalDiscount.totalSxcCardAmount
            draft.totalDiscount.totalSxcTicketAmount =
                payload.data.totalDiscount.totalSxcTicketAmount
            draft.totalToPay = payload.data.totalToPay || 0
            draft.totalUsedWelcomeCreditAmount = payload.data.totalUsedWelcomeCreditAmount || 0
            draft.totalValue = payload.data.total || 0
            draft.userCiAmount = payload.data.userCiAmount
            draft.userPtAmount = payload.data.userPtAmount
            draft.allowedPaymentMethods = payload.data.allowed_payments || []
            draft.calculating = false

            if (payload.data.totalDiscount.totalSxcCardAmount > 0) {
                draft.use_card = true
            }

            if (payload.data.totalDiscount.totalSxcTicketAmount > 0) {
                draft.use_ticket = true
            }
            break
        case C.CART_CLEAR:
            return { ...initialState }
        case C.GIFTCARD_CREDIT_SELECTION:
            draft.userPtAmount = payload.userPtAmount
            draft.userCiAmount = payload.userCiAmount
            break
        case C.CART_RESET_CI:
            draft.totalDiscount.ciUsedTotal = 0
            draft.totalDiscount.ptUsedTotal = 0
            break
        case C.CART_REMOVE_VOUCHER:
            draft.cart = [
                ...draft.cart.filter(
                    (val: CartItemType, index: number) => index !== payload,
                ),
            ]
            draft.calculating = true
            break
        case C.CART_REMOVE_PREMIUM:
            draft.cart = [
                ...draft.cart.map((val: CartItemType, index: number) => {
                    if (index === payload.index) {
                        val.premiumData = val.premiumData.filter(
                            (prm: CartItemType) =>
                                prm.offer_id !== payload.premiumId,
                        )
                    }
                    return val
                }),
            ]
            draft.calculating = true
            break
        case C.CART_TOGGLE_CARD:
            draft.use_card = payload
            draft.cart = [
                ...draft.cart.map((val: CartItemType) => ({
                    ...val,
                    use_card: payload,
                })),
            ]
            draft.calculating = true
            break
        case C.CART_TOGGLE_TICKET:
            draft.use_ticket = payload
            draft.cart = [
                ...draft.cart.map((val: CartItemType) => ({
                    ...val,
                    use_ticket: payload,
                })),
            ]
            draft.calculating = true
            break
        case C.LOGOUT:
            return { ...initialState }
        default:
            return draft
    }
})

export default DatalayerReducer
