import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { ChatSettingsState } from 'Lib/types/chat'

const initialState: ChatSettingsState = {
    userToUnblock: {},
    isSearch: false,
}

const ChatSettingsReducer = produce(
    (draft = initialState, { type, payload }) => {
        switch (type) {
            case C.CHAT_TOGGLE_SEARCH:
                draft.isSearch = payload
                break
            case C.CHAT_PREPARE_TO_UNBLOCK:
                draft.userToUnblock = payload
                break
            default:
                return draft
        }
    },
)

export default ChatSettingsReducer
