import moment from 'moment'
import { union } from 'Lib/sxc/js.utils'
import { NotiObjectType, NotifyType } from 'Lib/types/notifications'

import {
    getNotifFriends,
    getNotifSocialAll,
    getNotifGroupAll,
    getNotifTransactions, getApprovedFriendRequests
} from "Lib/sxc/notifications";

export default async function () {
    /**
     * if user is not logged exit
     */
    if (!window.SESSION_ID) {
        return
    }

    /**
     * PULL NOTIFICATIONS IF 1 MINUTE IS PASSED
     */
    const lastPull = ~~sessionStorage.getItem('sxc.last_noti_pull')
    const lastPullPlus1Min = moment.unix(lastPull).add(1, 'm')
    if (lastPullPlus1Min.unix() > moment().unix()) {
        return
    }

    const noti1: NotiObjectType = { list: [], counter: 0 }
    const noti2: NotiObjectType = { list: [], counter: 0 }
    const noti3: NotiObjectType = { list: [], counter: 0 }
    const noti4: NotiObjectType = { list: [], counter: 0 }

    /**
     *
     */
    try {
        const opts1 = {
            reqObj: {
                is_view: 1,
                notification_type: { POST_AT_CLUB_WALL: 0, TXN: 0 },
                limit_start: 0,
                limit_size: 50,
                user_id: `${window.SESSION_ID}`,
            },
        }

        const { data: data1 } = await getNotifSocialAll(opts1)
        noti1.counter = data1.size
        noti1.list = data1.requests.map(
            (val: any): NotifyType => ({
                created_at: val.create_date.date,
                icon: 'comment.png',
                id: val.notification_id,
                is_interactive: false,
                is_read: !!val.is_read,
                link: `social/timeline/friend/${val.notification_from.id}`,
                // val.message_type.toLowerCase() === 'friend'
                //     ? `social/timeline/friend/${val.notification_from.id}`
                //     : `user/post/${val.post_info?.postId}`,
                text: `Notifications.Social.${val.message_type.toLowerCase()}`,
                value1: `${val.notification_from.first_name} ${val.notification_from.last_name}`,
                value2: val.post_info?.postId,
                value3: null,
                type: 'social',
            }),
        )
    } catch (err) {
        console.log(err)
        noti1.list = []
    }

    /**
     *
     */
    try {
        const { data: data2 } = await getNotifFriends({
            reqObj: {
                limit_start: 0,
                limit_size: 999,
                user_id: window.SESSION_ID,
            },
        })
        const { data: approvedFriendsData } = await getApprovedFriendRequests({
            reqObj: {
                limit_start: 0,
                limit_size: 999,
                user_id: window.SESSION_ID,
            },
        })

        noti2.list = data2.requests.map(
            (val: any): NotifyType => ({
                created_at: val.created_at,
                icon: 'friend.png',
                id: val.request_id,
                is_interactive: false,
                is_read: false,
                link: `user/notifications`,
                text: `Notifications.PendingFriend.received`,
                value1: `${val.user_info.first_name} ${val.user_info.last_name}`,
                value2: val.user_info.id,
                value3: null,
                type: 'pendingFriend',
            }),
        )

        noti2.list = noti2.list.concat(
            approvedFriendsData.map(
                (val: any): NotifyType => ({
                    created_at: val.create_date.date,
                    icon: 'friend.png',
                    is_interactive: false,
                    is_read: val.is_read,
                    link: `social/timeline/friend/${val.notification_from.id}`,
                    id: val.notification_id,
                    text: `Notifications.Social.${val.message_type.toLowerCase()}`,
                    value1: `${val.notification_from.first_name} ${val.notification_from.last_name}`,
                    value2: val.post_info?.postId,
                    value3: null,
                    type: 'social',
                }),
            ),
        )
    } catch (err) {
        console.log(err)
        noti2.list = []
    }

    /**
     *
     */
    try {
        const opts3 = {
            token: window.TOKEN,
            type: 'GROUP',
            user_id: window.SESSION_ID,
        }

        const { data: data3 } = await getNotifGroupAll(opts3)
        noti3.list = data3
            .filter((val: any) => val.message_mapping_code.length)
            .map((val: any) => ({
                ...val,
                attributes: JSON.parse(val.attributes),
            }))
            .map(
                (val: any): NotifyType => ({
                    created_at: val.created_at,
                    icon: 'group.png',
                    id: val.id,
                    is_interactive: val.is_interactive,
                    is_read: val.is_read,
                    link: val.message_mapping_code.includes('_POST_')
                        ? `social/groups/${val.attributes?.group_id}` // FIXME: was /${val.attributes?.post_id}`
                        : `social/groups/${val.attributes?.group_id}`,
                    text: `Notifications.Groups.${val.message_localize_code}`,
                    value1:
                        val.attributes.group_name ||
                        val.attributes.group_name_old,
                    value2:
                        val.attributes.member_name ||
                        val.attributes.group_name_new,
                    value3: val.attributes.invitation_id,
                    type: 'groups',
                }),
            )
    } catch (err) {
        console.log(err)
        noti1.list = []
    }

    /**
     *
     */
    try {
        const opts4 = {
            reqObj: {
                is_view: 1,
                limit_start: 0,
                limit_size: 100,
                user_id: `${window.SESSION_ID}`,
            },
        }

        const { data: data4 } = await getNotifTransactions(opts4)
        const list = data4.requests.filter(
            (it: any) => it.message !== 'TXN_CUST_CI_GAIN',
        )
        noti4.list = list.map(
            (val: any): NotifyType => ({
                created_at: val.create_date.date,
                icon: 'earning.png',
                id: val.notification_id,
                is_interactive: false,
                is_read: !!val.is_read,
                link:
                    val.message === 'TXN_STATUS_COMPLETED'
                        ? val.transaction_info.link
                        : val.message === 'TXN_STATUS_COMPLETED'
                        ? '/buyspecialvoucher_cancel'
                        : `user/mycredits`,
                text:
                    val.message === 'TXN_STATUS_COMPLETED'
                        ? 'Notifications.Transactions.completed'
                        : val.message === 'TXN_STATUS_CANCELED'
                        ? 'Notifications.Transactions.canceled'
                        : 'Notifications.Transactions.received',
                value1: `${val.transaction_info.currency_symbol} ${val.transaction_info.citizen_income}`,
                value2: null,
                value3: null,
                type: 'transaction',
            }),
        )
    } catch (err) {
        console.log(err)
        noti4.list = []
    }

    /**
     * Store this last pull
     */
    sessionStorage.setItem('sxc.last_noti_pull', `${moment().unix()}`)

    const merged = union(
        noti1.list.filter((val) => !val.is_read),
        noti2.list,
        noti3.list.filter((val) => !val.is_read),
        noti4.list.filter((val) => !val.is_read),
    )

    return {
        pendingFriend: noti2.list,
        social: union(noti1.list),
        transactions: noti4.list,
        groups: noti3.list,
        allMerged: merged,
    }
}
