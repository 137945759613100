import C from 'Lib/redux/constants'
import produce from 'immer'
import { OrangeState } from 'Lib/types/orange'

const initialState: OrangeState = {
    step: 0,
    orangePromoCode: '',
    vouchersDetails: [],
    selectedPlan: 0,
    gateway: '',
    useCard: false,
    useTicket: false,
    creditCardData: {
        number: '',
        name: '',
        expiry_month: '',
        expiry_year: '',
        cvc: '',
        zipcode: '',
    },
}

const OrangeReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.ORANGE_STEP:
            draft.step += 1
            break
        case C.ORANGE_INITIAL:
            draft.step = 0
            break
        case C.ORANGE_VOUCHERS_DETAILS:
            draft.vouchersDetails = payload
            break
        case C.ORANGE_SELECT_PLAN:
            draft.selectedPlan = payload
            break
        case C.ORANGE_SET_CREDIT_CARD:
            draft.creditCardData[payload.field] = payload.value
            break
        case C.ORANGE_SET_GATEWAY:
            draft.gateway = payload
            break
        case C.ORANGE_SET_USE_CARD:
            draft.useCard = payload
            break
        case C.ORANGE_SET_USE_TICKET:
            draft.useTicket = payload
            break
        case C.SET_ORANGE_PROMOCODE:
            draft.orangePromoCode = payload
            break
        case C.LOGOUT:
            return { ...initialState }
        default:
            return draft
    }
})

export default OrangeReducer
