const defaultCookies = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
}

const getCookiesConsent = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        window.__tcfapi(
            'addEventListener',
            2,
            (ev: any, evSuccess: boolean) => {
                if (ev.gdprApplies) {
                    if (evSuccess && ev.eventStatus === 'useractioncomplete') {
                        window.__tcfapi(
                            'getNonIABVendorConsents',
                            2,
                            (iConsent: any, iSuccess: boolean) => {
                                if (iSuccess && iConsent.nonIabVendorConsents) {
                                    resolve(iConsent.nonIabVendorConsents)
                                } else {
                                    resolve(defaultCookies)
                                }
                            },
                        )
                    }
                } else {
                    resolve(defaultCookies)
                }
            },
        )
    })
}

export default getCookiesConsent
