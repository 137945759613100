import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from '../../../../config/webpack/environment'

const schema = Joi.object({
    reqObj: Joi.object({
        limit_start: Joi.number().integer(),
        limit_size: Joi.number().integer(),
        notification_type: Joi.object({
            POST_AT_CLUB_WALL: 0,
            TXN: 0,
        }),
        is_view: 1,
        user_id: Joi.string(),
    }),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}api/getallnotifications?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then((res) => res.json())

        //Accepted requests are to be retrieved with different API
        res.data.requests = res.data.requests.filter(
            (e) => e.message !== 'accepted_personal',
        )
        res.data.size = res.data.requests.length

        if (res.code !== 101) throw new Error('REQUEST_FAILED')

        return res
    } catch (err) {
        throw err
    }
}
