import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { withAccessTokenOkAsync } from 'Lib/sxc/device/'
import { Environment } from "../../../../config/webpack/environment";

const STORAGE_KEY = 'access_token'

const schema = Joi.object({
    reqObj: Joi.object({
        client_id: Joi.string(),
        client_secret: Joi.string(),
        grant_type: Joi.string().valid('password'),
        username: Joi.string().email({ tlds: { allow: false } }),
        password: Joi.string(),
    }),
}).options({ presence: 'required' })

export default async opts => {
    const { error } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}webapi/getaccesstoken`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()

        opts.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.message === 'USERLOCKED_FOR_TWO_HOURS') {
            throw new Error('USERLOCKED_FOR_TWO_HOURS')
        }

        if (res.message === 'USERNAME_OR_PASSWORD_IS_WRONG') {
            throw new Error('USERNAME_OR_PASSWORD_IS_WRONG')
        }

        if (Number(res.code) !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        window.TOKEN = res.data.access_token

        withAccessTokenOkAsync(res)

        /**
         * save it on localStorage
         */
        localStorage.setItem(STORAGE_KEY, res.data.access_token)

        return res
    } catch (err) {
        throw err
    }
}
