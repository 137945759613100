import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { ChatIdleState } from 'Lib/types/chat'

const globalDelay = 60000

const initialState: ChatIdleState = {
    idleTime: 0,
    isIdled: false,
    intervalDelay: globalDelay,
}

const ChatIdleReducer = produce((draft = initialState, { type }) => {
    switch (type) {
        case C.CHAT_IDLE_INCREMENT:
            draft.idleTime += 1
            break
        case C.CHAT_IDLE_RESET_TIME:
            draft.idleTime = 0
            break
        case C.CHAT_IDLE_OFF:
            draft.idleTime = 0
            draft.isIdled = false
            draft.intervalDelay = globalDelay
            break
        case C.CHAT_IDLE_ON:
            draft.isIdled = true
            draft.intervalDelay = 0
            break
        default:
            return draft
    }
})

export default ChatIdleReducer
