import Joi from 'joi'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        country: Joi.string(),
        email: Joi.string(),
        facebook_id: Joi.string(),
        facebook_accesstoken: Joi.string(),
        firstname: Joi.string(),
        id: Joi.number().integer(),
        is_all_accepted: 1,
        is_usage_accepted: 1,
        is_selling_accepted: 1,
        is_privacy_accepted: 1,
        is_marketing_accepted: 1,
        lastname: Joi.string(),
        password: Joi.string(),
        promotional_code: Joi.string().optional(),
        type: 1,
        referral_id: Joi.string().optional(),
        walletCurrency: Joi.string(),
    }),
}).options({ presence: 'required' })

export default async opts => {
    const { error } = schema.validate(opts)
    console.log(error, 'error')
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}webapi/facebookregister`

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.code !== '101') {
            const error = new Error('REQUEST_FAILED')
            error.code = 'RequestFailed'
            throw error
        }

        return res
    } catch (err) {
        throw err
    }
}
