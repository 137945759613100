import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { WalletState } from 'Lib/types/wallet'

const initialState: WalletState = {
    availableCredits: 0,
    availablePoints: 0,
    pendingCi: {},
    sxcCardBalance: 0,
    sxcCardTransactions: [],
    sxcTicketBalance: 0,
    sxcTicketTransactions: [],
}

const ProfileReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.WALLET_GET_CARD_BALANCE:
            draft.sxcCardBalance = payload
            break
        case C.WALLET_GET_CARD_TRANSACTIONS:
            draft.sxcCardTransactions = payload
            break
        case C.WALLET_GET_TICKET_BALANCE:
            draft.sxcTicketBalance = payload
            break
        case C.WALLET_GET_TICKET_TRANSACTIONS:
            draft.sxcTicketTransactions = payload
            break
        case C.WALLET_PENDINGCI:
            draft.pendingCi = payload
            break
        case C.USER_CREDITS:
            draft.availableCredits = payload.credits
            draft.availablePoints = payload.points
            break
        case C.LOGOUT:
            return { ...initialState }
        default:
            return draft
    }
})

export default ProfileReducer
