/**
 *
 * @param {*} url
 * @param {*} user_id
 * @param {*} access_token
 */
import { Environment } from "../../../../config/webpack/environment";

export default function getGraphData(url, user_id, access_token) {
    return new Promise((resolve, reject) => {
        fetch(`${Environment.crawlerDomain}/linkcrawler`, {
            method: 'POST',
            body: JSON.stringify({ url, user_id, access_token }),
        })
            .then(res => resolve(res.json()))
            .catch(err => reject(err))
    })
}
