export * from './request.login.amazon'
export * from './request.login.apple'
export * from './request.login.fb'

export { default as amazonAuthorize } from './amazon.authorize'
export { default as appleAuthorize } from './apple.authorize'
export { default as getAccessToken } from './get.access.token'
export { default as requestLogin } from './request.login'

/** SIGNUP */
export { default as activation } from './activation'
export { default as facebookRegister } from './facebook.register'
export { default as preRegister } from './pre.register'

/** PASSWORD */
export { default as generatePwd } from './generate.pwd'
export { default as forgotPassword } from './forgot.password'
export { default as resetPassword } from './reset.password'
