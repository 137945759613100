import C from 'Lib/redux/constants'
import produce from 'immer'
import { GroupsState } from 'Lib/types/groups'

const initialState: GroupsState = {
    selected: null,
    posts: {},
    postsOrder: [],
    write: {
        description: '',
        images: [],
        link: {},
        tagged: [],
    },
}

const GroupsReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.GROUPS_SELECT:
            draft.selected = payload
            draft.posts = {}
            draft.postsOrder = []
            draft.write = {
                description: '',
                images: [],
                tagged: [],
                link: {},
            }
            break
        case C.GROUPS_ADD_COMMENT:
            draft.posts[payload.postId].comments = [
                ...draft.posts[payload.postId].comments,
                payload.comment,
            ]
            break
        case C.GROUPS_ADD_POST:
            draft.posts[payload.id] = { ...payload, comments: [] }
            draft.postsOrder.unshift(payload.id)
            draft.write = initialState.write
            break
        case C.GROUPS_DELETE_COMMENT:
            draft.posts[payload.postId].comments = draft.posts[
                payload.postId
            ].comments.filter((v: any) => v.id !== payload.comment.id)
            break
        case C.GROUPS_DELETE_POST:
            draft.postsOrder = draft.postsOrder.filter(
                (v: any) => v !== payload,
            )
            delete draft.posts[payload]
            break
        case C.GROUPS_GET_COMMENTS:
            if (!payload.comments.length) return

            draft.posts[payload.postId].comments = [
                ...payload.comments,
                ...draft.posts[payload.postId].comments,
            ]
            break
        case C.GROUPS_GET_POSTS:
            draft.postsOrder = [
                ...draft.postsOrder,
                ...payload.map((v: any) => v.id),
            ]

            payload.forEach((element: any) => {
                draft.posts[element.id] = { ...element, comments: [] }
            })
            break
        case C.GROUPS_LIKE_COMMENT:
            draft.posts[payload.postId].comments = draft.posts[
                payload.postId
            ].comments.map((v: any) => {
                if (v.id === payload.id) {
                    return { ...v, hasRated: payload.rate }
                }

                return v
            })
            break
        case C.GROUPS_LIKE_POST:
            draft.posts[payload.postId].has_rated = payload.rate
            draft.posts[payload.postId].ratings_cnt = payload.rate
                ? ++draft.posts[payload.postId].ratings_cnt
                : --draft.posts[payload.postId].ratings_cnt
            break
        case C.GROUPS_UPDATE_POST:
            draft.posts[payload.id] = {
                ...payload,
                ...draft.posts[payload.id].comments,
            }
            break
        case C.GROUPS_WRITE_ADD_IMAGE:
            draft.write.images.push(payload)
            break
        case C.GROUPS_WRITE_REMOVE_IMAGE:
            draft.write.images.splice(draft.write.images.indexOf(payload))
            break
        case C.GROUPS_WRITE_POST:
            draft.write.description = payload
            break
        case C.GROUPS_WRITE_LINK:
            draft.write.link = payload
            break
        case C.GROUPS_WRITE_TAG:
            draft.write.tagged = payload
            break
        case C.LOGOUT:
        case C.ROUTE_CHANGE:
            return { ...initialState }
        default:
            return draft
    }
})

export default GroupsReducer
