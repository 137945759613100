export default function GetLoadChannelsIstance() {
    try {
        const channelListQuery = window.sb.GroupChannel.createMyGroupChannelListQuery()
        channelListQuery.includeEmpty = true

        return offset =>
            new Promise((resolve, reject) => {
                channelListQuery.limit = offset // The value of pagination limit could be set up to 100.

                if (channelListQuery.hasNext) {
                    channelListQuery.next((channels, error) => {
                        if (error) {
                            reject(error)
                        }
                        resolve(channels)
                    })
                }
            })
    } catch (err) {
        return () => new Promise(res => res([]))
    }
}
