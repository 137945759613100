import C from 'Lib/redux/constants'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Col, Row, Card, Typography } from 'antd'
import ForgotPasswordForm from './components/ForgotPasswordForm'
import { useIntl } from 'react-intl'

const ForgotPassword: React.FC = () => {
    const dispatch = useDispatch()
    const intl = useIntl()

    return (
        <Row id="forgotpassword" justify="center">
            <Col md={16} xs={24}>
                <Card>
                    <a
                        className="link"
                        onClick={() =>
                            dispatch({ type: C.AUTH_SET, payload: 'signin' })
                        }
                    >
                        {intl.messages['Common.login']}
                    </a>
                    <Typography.Title level={3}>
                        {intl.messages['Common.forgot_password']}
                    </Typography.Title>
                    <ForgotPasswordForm />
                </Card>
            </Col>
        </Row>
    )
}

export default ForgotPassword
