import C from 'Lib/redux/constants'
import produce from 'immer'
import { CheckoutState } from 'Lib/types/checkout'

const initialState: CheckoutState = {
    billingAddress: null,
    deviceType: 'web',
    gateway: '',
    requireBilling: false,
    saveAddress: 0,
    shippingAddress: null,
    tandc: false,
    params: {},
    revolut: {
        public_id: '',
        order_id: 0,
    },
    creditCardData: {
        number: '',
        name: '',
        expiry_month: '',
        expiry_year: '',
        cvc: '',
        zipcode: '',
    },
}
const DatalayerReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.CHECKOUT_COMPLETED:
            return { ...initialState }
        case C.CHECKOUT_SAVE_ADDRESS:
            draft.saveAddress = 1
            draft.shippingAddress = {
                address: payload.address,
                city: payload.city,
                country: payload.country,
                email: payload.email,
                first_name: payload.firstname,
                last_name: payload.lastname,
                phone1: payload.phone,
                state: payload.province,
                zipcode: payload.cap,
            }
            break
        case C.CHECKOUT_SET_BILLING:
            draft.billingAddress = ~~payload
            break
        case C.CHECKOUT_SET_CREDIT_CARD:
            draft.creditCardData[payload.field] = payload.value
            break
        case C.CHECKOUT_SET_GATEWAY:
            draft.gateway = payload
            break
        case C.CHECKOUT_SET_REVOLUT_ID:
            draft.revolut = payload
            break
        case C.CHECKOUT_SET_SHIPPING:
            draft.shippingAddress = payload
            break
        case C.CHECKOUT_TOGGLE_BILLING:
            draft.requireBilling = payload
            break
        case C.CHECKOUT_TOGGLE_TANDC:
            draft.tandc = payload
            break
        case C.CHECKOUT_SET_PARAMS:
            draft.params = payload
            break
        case C.LOGOUT:
        default:
            return draft
    }
})

export default DatalayerReducer
