import C from 'Lib/redux/constants'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getWishlist } from 'Lib/sxc/wishlist'

export default function useGetWishlist(accessToken: string): void {
    const dispatch = useDispatch()

    useEffect(() => {
        if (!accessToken) {
            return
        }

        (async function () {
            try {
                const res = await getWishlist()
                dispatch({
                    type: C.WISHLIST_GET,
                    payload: res.data,
                })
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])
}
