const pbjs = window.pbjs || {}

export default function initAdserver() {
    if (pbjs.initAdserverSet) return

    pbjs.initAdserverSet = true

    pbjs.que.push(function() {
        pbjs.setConfig({
            deviceAccess: window.pbjsCookies,
            consentManagement: {
                gdpr: {
                    cmpApi: 'iab',
                    timeout: 10000,
                    defaultGdprScope: false,
                },
            },
            enableSendAllBids: true,
        })
        pbjs.setTargetingForGPTAsync()
        window.googletag.pubads().refresh()
    })
}
