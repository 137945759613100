import C from 'Lib/redux/constants'
import produce from 'immer'
import { TimelineState, CommentType, PostType } from 'Lib/types/timeline'

const initialState: TimelineState = {
    loadingPosts: true,
    showLoadMore: true,
    lastPostId: '',
    posts: {},
    postsOrder: [],
    write: {
        post_id: '',
        description: '',
        images: [],
        media_id: [],
        tagged: [],
        share: {},
    },
    friendWall: {
        badges: [],
        cover: '',
        currently_working: '',
        loading: true,
        id: 0,
        is_friend: false,
        is_readonly: false,
        friends: [],
        friendsCount: 0,
        gender: 'm',
        name: '',
        thumb: '/assets/images/prof-pic.jpg',
        subscription_date: '',
    },
}

const TimelineReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.TIMELINE_ADD_COMMENT:
            draft.posts[payload.post_id].comments.push(payload)
            break
        case C.TIMELINE_ADD_POST:
            draft.posts[payload.id] = payload
            draft.postsOrder.unshift(payload.id)
            draft.write = initialState.write
            break
        case C.TIMELINE_DELETE_COMMENT:
            draft.posts[payload.post_id].comments = draft.posts[
                payload.post_id
            ].comments.filter((v: CommentType) => v.id !== payload.id)
            break
        case C.TIMELINE_DELETE_POST:
            draft.postsOrder = draft.postsOrder.filter(
                (v: string) => v !== payload,
            )
            delete draft.posts[payload]
            break
        case C.TIMELINE_GET_COMMENTS:
            if (!payload.length) return

            draft.posts[payload[0].post_id].comments = [
                ...payload,
                ...draft.posts[payload[0].post_id].comments,
            ]
            break
        case C.TIMELINE_GET_CRAWL:
            if (payload.type === 'youtube') {
                draft.write.share.canonicalUrl = 'https://www.youtube.com/'
                draft.write.share.description =
                    payload.items[0].snippet.description
                draft.write.share.images = [
                    payload.items[0].snippet.thumbnails.default.url,
                ]
                draft.write.share.pageUrl = 'youtube'
                draft.write.share.title = payload.items[0].snippet.title
                draft.write.share.url = `https://www.youtube.com/watch?v=${payload.items[0].id}`
                draft.write.share.video = 'yes'
            } else {
                draft.write.share.canonicalUrl = payload.url
                draft.write.share.description = payload.description
                draft.write.share.images = [payload.image]
                draft.write.share.pageUrl = payload.sitename
                draft.write.share.title = payload.title
                draft.write.share.url = payload.url
                draft.write.share.video = 'no'
            }
            break
        case C.TIMELINE_GET_POSTS:
            draft.loadingPosts = false

            if (!payload.length) {
                draft.showLoadMore = false
                return
            }

            draft.postsOrder = [
                ...draft.postsOrder,
                ...payload.map((v: PostType) => v.id),
            ]
            draft.lastPostId = payload[payload.length - 1]?.id
            payload.forEach((element: PostType) => {
                draft.posts[element.id] = element
            })
            break
        case C.TIMELINE_GET_FRIEND_WALL:
            draft.friendWall.id = payload.user_id
            draft.friendWall.cover = payload.user_info.cover_image
            draft.friendWall.currently_working =
                payload.user_info.currently_working
            draft.friendWall.gender = payload.user_info.gender
            draft.friendWall.name = `${payload.user_info.first_name} ${payload.user_info.last_name}`
            draft.friendWall.thumb = payload.user_info.profile_image_thumb
                .length
                ? payload.user_info.profile_image_thumb
                : '/assets/images/prof-pic.jpg'
            draft.friendWall.loading = false
            draft.friendWall.subscription_date = payload.user_info.created_at
            draft.friendWall.is_readonly = payload.user_info.is_readonly
            break
        case C.TIMELINE_GET_FRIEND_BADGES:
            draft.friendWall.badges = payload
            break
        case C.TIMELINE_GET_FRIEND_FRIENDS:
            draft.friendWall.friends = payload.friends
            draft.friendWall.friendsCount = payload.count
            break
        case C.TIMELINE_GET_FRIEND_FRIENDSHIP:
            draft.friendWall.is_friend = payload
            break
        case C.TIMELINE_LIKE_COMMENT:
            draft.posts[payload.post_id].comments = draft.posts[
                payload.post_id
            ].comments.map((v: CommentType) => {
                if (v.id === payload.id) {
                    v.is_rated = !!payload.rate
                }

                return v
            })
            break
        case C.TIMELINE_LIKE_POST:
            draft.posts[payload.postId].no_of_votes = payload.no_of_votes
            draft.posts[payload.postId].avg_rate = payload.avg_rate
            draft.posts[payload.postId].current_user_rate =
                payload.current_user_rate
            break
        case C.TIMELINE_LOAD_MORE:
            draft.loadingPosts = true
            break
        case C.TIMELINE_RESET_CRAWL:
            draft.write.share = {}
            break
        case C.TIMELINE_UPDATE_POST:
            draft.posts[payload.id] = payload
            break
        case C.TIMELINE_WRITE_ADD_IMAGE:
            draft.write.images.push(payload.img)
            draft.write.post_id = payload.post_id
            draft.write.media_id.push(payload.media_id)
            break
        case C.TIMELINE_WRITE_REMOVE_IMAGE:
            draft.write.images.splice(draft.write.images.indexOf(payload), 1)
            draft.write.post_id = payload.post_id
            draft.write.media_id.push(payload.media_id)
            break
        case C.TIMELINE_WRITE_POST:
            draft.write.description = payload
            break
        case C.TIMELINE_WRITE_TAG:
            draft.write.tagged = payload
            break
        case C.ROUTE_CHANGE:
            return { ...initialState }
        default:
            return draft
    }
})

export default TimelineReducer
