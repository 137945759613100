import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { ChatMessageState, MessageType } from 'Lib/types/chat'

const initialState: ChatMessageState = { messages: [] }

const ChatMessagesReducer = produce(
    (draft = initialState, { type, payload }) => {
        switch (type) {
            case C.CHAT_CLEAR_HISTORY:
                draft.messages = []
                break

            case C.CHAT_MESSAGES_DELETE:
                draft.messages.forEach(
                    (message: MessageType, index: number) => {
                        if (~~message.messageId === ~~payload.messageId) {
                            draft.messages[index]._isremoved = true
                        }
                    },
                )

                break
            case C.CHAT_MESSAGES_GET:
                // prevent messages in wrong chat (changing fast)
                if (
                    payload.messages[0].channelUrl === payload.activeChannel.url
                ) {
                    draft.messages = [...draft.messages, ...payload.messages]
                    draft.messages.sort(
                        (a: MessageType, b: MessageType) =>
                            a.createdAt - b.createdAt,
                    )
                }
                break
            case C.CHAT_MESSAGES_UPDATE:
            case C.CHAT_ON_MESSAGE_UPDATED:
                if (payload.channel.url === payload.activeChannel.url) {
                    draft.messages.forEach(
                        (message: MessageType, index: number) => {
                            if (
                                ~~message.messageId ===
                                ~~payload.message.messageId
                            ) {
                                draft.messages[index] = payload.message
                            }
                        },
                    )
                }

                break
            case C.CHAT_ON_MESSAGE_DELETED:
                draft.messages.forEach(
                    (message: MessageType, index: number) => {
                        if (~~message.messageId === ~~payload.messageId) {
                            draft.messages[index]._isremoved = true
                        }
                    },
                )

                break
            case C.CHAT_ON_MESSAGE_RECEIVED:
            case C.CHAT_MESSAGES_PREPARE:
                if (payload.channel.url === payload.activeChannel.url) {
                    draft.messages.push(payload.message)
                }
                break
            case C.CHAT_MESSAGES_SEND:
                if (payload.channel.url === payload.activeChannel.url) {
                    draft.messages.forEach(
                        (message: MessageType, index: number) => {
                            if (
                                payload.tempHash &&
                                message.tempHash === payload.tempHash
                            ) {
                                draft.messages.splice(index, 1)
                            }
                        },
                    )

                    draft.messages.push(payload.message)
                }
                break
            case C.CHAT_SET_ACTIVE_CHANNEL:
                if (payload.channel.url !== payload.activeChannel.url) {
                    draft.messages = []
                }
                break
            case C.CHAT_CLEAR_ACTIVE_CHANNEL:
                draft.messages = []
                break
            case C.CHAT_ON_REACTION:
                draft.messages.forEach(
                    (message: MessageType, index: number) => {
                        if (message.messageId === payload.messageId) {
                            if (payload.operation === 'add') {
                                draft.messages[index].reactions.push(payload)
                            } else {
                                draft.messages[index].reactions
                                    .filter(
                                        (val: any) =>
                                            String(val.userId) ===
                                            String(payload.userId),
                                    )
                                    .map((_: null, index: number) =>
                                        draft.messages[index].reactions.splice(
                                            index,
                                            1,
                                        ),
                                    )
                            }
                        }
                    },
                )
                break
            default:
                return draft
        }
    },
)

export default ChatMessagesReducer
