import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { ChatWriteState } from 'Lib/types/chat'

const initialState: ChatWriteState = {
    quote: null,
    text: '',
    attachment: {},
    errorAttachment: false,
    voucher: {},
}
const ChatWriteReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.CHAT_SET_ATTACHMENT:
            draft.attachment = payload
            break
        case C.CHAT_SET_ATTACHMENT_ERROR:
            draft.errorAttachment = payload
            break
        case C.CHAT_SET_QUOTE:
            draft.quote = payload
            break
        case C.CHAT_CLEAR_ACTIVE_CHANNEL:
        case C.CHAT_SET_ACTIVE_CHANNEL:
            draft.text = ''
            draft.attachment = {}
            draft.errorAttachment = false
            draft.quote = null
            break
        case C.CHAT_SHARE_VOUCHER:
            draft.voucher = payload.voucher
            break
        case C.CHAT_CLEAR_SHARE_VOUCHER:
        case C.CHAT_MESSAGES_SEND:
            draft.voucher = {}
            break
        default:
            return draft
    }
})
export default ChatWriteReducer
