// FAVICON UPDATE

let originalSource

export default function(number) {
    var favicon = document.getElementById('favicon')
    var faviconSize = 16

    var canvas = document.createElement('canvas')
    canvas.width = faviconSize
    canvas.height = faviconSize

    var context = canvas.getContext('2d')
    var img = document.createElement('img')
    img.src = favicon.href

    img.onload = () => {
        originalSource = !!originalSource ? originalSource : favicon.href

        // if there are not notifications keep the original Favicon
        if (!number) {
            favicon.href = originalSource
            return
        }
        // Draw Original Favicon as Background
        context.drawImage(img, 0, 0, faviconSize, faviconSize)

        // Draw Notification Circle
        context.beginPath()
        context.arc(
            canvas.width - faviconSize / 3,
            faviconSize / 3,
            faviconSize / 3,
            0,
            2 * Math.PI,
        )
        // change icon color if we are passing the string 'zzz' instead of the number of the notifications
        context.fillStyle = typeof number !== 'number' ? '#42426D' : '#FF0000'
        context.fill()

        // Draw Notification Number
        context.font = '8px "helvetica", sans-serif'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        context.fillStyle = '#FFFFFF'
        context.fillText(
            number > 9 ? '+9' : number,
            canvas.width - faviconSize / 3,
            faviconSize / 3,
        )

        // Replace favicon
        favicon.href = canvas.toDataURL('image/png')
    }
}
