import C from 'Lib/redux/constants'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { mergeGlobalNotif } from 'Lib/sxc/notifications'

export default function useGetNotifications(accessToken: string): void {
    const dispatch = useDispatch()
    const pulling = useSelector((s: RootState) => s.Notifications.pulling)

    useEffect(() => {
        sessionStorage.removeItem('sxc.last_noti_pull')
    }, [])

    useEffect(() => {
        if (!accessToken || !pulling) return
        ;(async () => {
            try {
                const res = await mergeGlobalNotif()
                if (!res) return

                dispatch({ type: C.NOTIFICATIONS_GET, payload: res })
            } catch (err) {
                console.log(err)
            }
        })()
    }, [pulling])
}
