/**
 *
 * @param {*} message
 * @param {*} reaction
 * @param {*} channel
 */
export default function toggleReaction(channel, message, myReaction) {
    return new Promise((resolve, reject) => {
        if (!myReaction) {
            channel.deleteReaction(message, 'heart', function(
                reactionEvent,
                error,
            ) {
                if (!error) {
                    //  message.applyReactionEvent(reactionEvent)
                    resolve(true)
                } else {
                    reject(error)
                }
            })
            return
        }

        channel.addReaction(message, 'heart', function(reactionEvent, error) {
            if (!error) {
                // message.applyReactionEvent(reactionEvent)
                resolve(true)
            } else {
                reject(error)
            }
        })
    })
}
