import C from 'Lib/redux/constants'
import { extendChannelAttrs } from 'Lib/sxc/messenger'
import { produce } from 'immer'
import { ChatChannelState, ChannelType, MemberType } from 'Lib/types/chat'

const initialState: ChatChannelState = {
    activeChannel: {}, // the entire active channel object
    activeChannelOnlyFirstMessages: true,
    activeChannelIsTyping: false,
    activeChannelIsUserInactive: null, // isn't the one-to-one user orange anymore
    /** these are calculated by helper any time */
    activeChannelChatName: '', // name to be displayed
    activeChannelAvatar: '', // avatar to be displayed
    activeChannelNotSet: true, // is a channel active set or not
    activeChannelIs1to1: false, // is the active channel a one-to-one
    activeChannelIsUserBlockingMe: false, // is the user one-to-one blocking me
    activeChannelIsUserBlockedByMe: false, // is the user one-to-one blocked by me
    activeChannelMembersCount: 0, // number of members of the active channel
    activeChannelAllMembers: [], // list of all members of the active channel
    activeChannelSecondMember: {}, // the other member if is a one-to-one channel
    /**  */
    allUnreadNotif: 0,
    channels: {},
    channelOrder: [],
}

const ChatChannelReducer = produce(
    (draft = initialState, { type, payload }) => {
        switch (type) {
            case C.CHAT_COUNT_UNREAD_NOTIF:
                draft.allUnreadNotif = payload
                break
            case C.CHAT_CLEAR_HISTORY:
                draft.activeChannelOnlyFirstMessages = true
                draft.activeChannelIsTyping = false
                if (draft.channels[draft.activeChannel.url])
                    draft.channels[draft.activeChannel.url].lastMessage = null
                break
            case C.CHAT_LEAVE_CHANNEL: {
                const removeIndx = draft.channelOrder.indexOf(payload)

                draft.activeChannel = {}
                draft.activeChannelIsUserInactive = null
                draft.activeChannelChatName = ''
                draft.activeChannelAvatar = ''
                draft.activeChannelNotSet = true
                draft.activeChannelIs1to1 = false
                draft.activeChannelIsUserBlockingMe = false
                draft.activeChannelIsUserBlockedByMe = false
                draft.activeChannelMembersCount = 0
                draft.activeChannelAllMembers = []
                draft.activeChannelSecondMember = {}
                draft.channelOrder.splice(removeIndx, 1)
                if (draft.channels[payload]) delete draft.channels[payload]
                break
            }
            case C.CHAT_SET_CHANNELS:
                payload.forEach((val: ChannelType) => {
                    if (
                        !Object.prototype.hasOwnProperty.call(
                            draft.channels,
                            val.url,
                        )
                    ) {
                        /* fix for old channels with wrong isDistinct */
                        const regex = /[0-9]{2}_and_[0-9]{2}/
                        if (!val.isDistinct && val.memberCount === 2) {
                            if (regex.test(val.name)) val.isDistinct = true
                        }
                        /**  */

                        draft.channels[val.url] = val
                        draft.channelOrder.push(val.url)
                    }
                })

                break
            case C.CHAT_ERASE_CHANNEL:
                draft.channels = {}
                draft.channelOrder = []
                break
            case C.CHAT_SET_NEW_CHANNEL:
                if (payload.url !== draft.activeChannel.url) {
                    if (!draft.channels[payload.url]) {
                        draft.channels[payload.url] = payload
                    }
                }

                /**
                 * Edit channels order
                 */
                draft.channelOrder.splice(
                    draft.channelOrder.indexOf(payload.url),
                    1,
                )
                draft.channelOrder.unshift(payload.url)

                break
            case C.CHAT_SET_ACTIVE_CHANNEL:
                if (payload.channel.url !== draft.activeChannel.url) {
                    draft.activeChannel = payload.channel
                    draft.activeChannelIsTyping = false
                    draft.activeChannelOnlyFirstMessages = true
                    draft.activeChannelIsUserInactive = null
                    if (draft.channels[payload.channel.url])
                        draft.channels[
                            payload.channel.url
                        ].unreadMessageCount = 0

                    const calc1 = extendChannelAttrs(
                        payload.channel,
                        payload.userId,
                    )

                    draft.activeChannelChatName = calc1.chatName
                    draft.activeChannelAvatar = calc1.chatAvatar
                    draft.activeChannelNotSet = calc1.notSet
                    draft.activeChannelIs1to1 = calc1.is1to1
                    draft.activeChannelIsUserBlockingMe = calc1.isBlockingMe
                    draft.activeChannelIsUserBlockedByMe = calc1.isBlockedByMe
                    draft.activeChannelMembersCount = calc1.membersCount
                    draft.activeChannelSecondMember = calc1.secondMember
                    draft.activeChannelAllMembers = []
                }

                draft.allUnreadNotif = Object.values(draft.channels)
                    .map((val: ChannelType) => val.unreadMessageCount)
                    .reduce((a, b) => a + b, 0)

                break

            case C.CHAT_SET_MEMBERS:
                payload.forEach((val: MemberType) => {
                    if (
                        !draft.activeChannelAllMembers.find(
                            (member: MemberType) =>
                                String(member.userId) === String(val.userId),
                        )
                    ) {
                        draft.activeChannelAllMembers.push(val)
                    }
                })
                break

            case C.CHAT_CLEAR_ACTIVE_CHANNEL:
                draft.activeChannel = {}
                draft.activeChannelOnlyFirstMessages = true
                draft.activeChannelIsTyping = false
                draft.activeChannelIsUserInactive = null
                draft.activeChannelNotSet = true
                break

            case C.CHAT_USER_NOT_ORANGE:
                draft.activeChannelIsUserInactive = payload
                break
            case C.CHAT_MARK_AS_READ:
                if (draft.channels[draft.activeChannel.url]) {
                    draft.channels[
                        draft.activeChannel.url
                    ].unreadMessageCount = 0
                }
                break
            case C.CHAT_MESSAGES_GET:
                if (payload.oldMessages.length) {
                    draft.activeChannelOnlyFirstMessages = false
                }
                break
            case C.CHAT_ON_TYPING:
                if (payload.url === draft.activeChannel.url) {
                    draft.activeChannelIsTyping = payload.isTyping
                }
                break
            case C.CHAT_ON_MESSAGE_DELETED:
                draft.activeChannelOnlyFirstMessages = false
                if (
                    draft.channels[payload.channel.url] &&
                    ~~draft.channels[payload.channel.url].lastMessage
                        .messageId === ~~payload.messageId
                ) {
                    draft.channels[payload.channel.url].lastMessage.message =
                        '🚫'
                }
                break
            case C.CHAT_ON_MESSAGE_RECEIVED:
                draft.activeChannelIsTyping = false
                draft.channels[payload.channel.url].lastMessage =
                    payload.message
                draft.channels[payload.channel.url].myLastRead =
                    payload.channel.myLastRead
                draft.channels[payload.channel.url].unreadMessageCount += 1

                /**
                 * Edit channels order
                 */
                draft.channelOrder.splice(
                    draft.channelOrder.indexOf(payload.channel.url),
                    1,
                )
                draft.channelOrder.unshift(payload.channel.url)
                draft.allUnreadNotif = Object.values(draft.channels)
                    .map((val: ChannelType) => val.unreadMessageCount)
                    .reduce((a, b) => a + b, 0)
                break
            case C.CHAT_ON_MESSAGE_UPDATED:
            case C.CHAT_MESSAGES_SEND:
                draft.activeChannelOnlyFirstMessages = false
                draft.channels[payload.channel.url] = payload.channel

                /**
                 * Edit channels order
                 */
                draft.channelOrder.splice(
                    draft.channelOrder.indexOf(payload.channel.url),
                    1,
                )
                draft.channelOrder.unshift(payload.channel.url)

                break
            case C.CHAT_MESSAGES_UPDATE:
                draft.activeChannelOnlyFirstMessages = false
                if (draft.channels[payload.channel.url])
                    draft.channels[payload.channel.url].lastMessage.message =
                        payload.message.message

                break
            case C.CHAT_CHANNEL_EDITED: {
                draft.activeChannel = payload.channel
                const calc2 = extendChannelAttrs(
                    payload.channel,
                    payload.userId,
                )

                draft.activeChannelChatName = calc2.chatName
                draft.activeChannelAvatar = calc2.chatAvatar
                draft.activeChannelNotSet = calc2.notSet
                draft.activeChannelIs1to1 = calc2.is1to1
                draft.activeChannelIsUserBlockingMe = calc2.isBlockedByMe
                draft.activeChannelIsUserBlockedByMe = calc2.isBlockingMe
                draft.activeChannelMembersCount = calc2.membersCount
                draft.activeChannelSecondMember = calc2.secondMember

                if (draft.channels[payload.url])
                    draft.channels[payload.url] = payload.channel

                break
            }
            case C.CHAT_BAN_USER_FROM_CHANNEL:
            case C.CHAT_ADD_USER_TO_CHANNEL:
                draft.activeChannel.memberCount = payload.memberCount
                draft.activeChannel.members = payload.members
                draft.activeChannelMembersCount = payload.memberCount
                break
            case C.CHAT_ADDED_AS_ADMIN:
                draft.activeChannelAllMembers.forEach(
                    (member: MemberType, indx: number) => {
                        if (String(member.userId) === String(payload)) {
                            draft.activeChannelAllMembers[indx] = {
                                ...member,
                                role: 'operator',
                            }
                        }
                    },
                )

                break
            case C.CHAT_REMOVED_AS_ADMIN:
                draft.activeChannelAllMembers.forEach(
                    (member: MemberType, indx: number) => {
                        if (String(member.userId) === String(payload)) {
                            draft.activeChannelAllMembers[indx] = {
                                ...member,
                                role: 'none',
                            }
                        }
                    },
                )
                break
            case C.CHAT_BLOCK_USER:
                draft.activeChannel.members.forEach(
                    (user: MemberType, index: number) => {
                        if (String(payload.userId) === String(user.userId)) {
                            draft.activeChannel.members[index].isBlockedByMe =
                                true
                        }
                    },
                )
                break
            case C.CHAT_UNBLOCK_USER:
                draft.activeChannel.members.forEach(
                    (user: MemberType, index: number) => {
                        if (String(payload.userId) === String(user.userId)) {
                            draft.activeChannel.members[index].isBlockedByMe =
                                false
                        }
                    },
                )
                break
            case C.CHAT_IDLED:
                draft.allUnreadNotif = payload
                break
            default:
                return draft
        }
    },
)

export default ChatChannelReducer
