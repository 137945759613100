import C from 'Lib/redux/constants'
import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { initCookiesConsent } from 'Lib/sxc/cookies'
import UniversalId from 'Lib/pixels/adasta/universal.id'

export default function useInitCookies(): void {
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        // COOKIE POLICY
        (async function getCookies() {
            const cookies = await initCookiesConsent()
            window.initAnalytics(cookies[2])
            window.googleAdsCookie(cookies[2], true)
            if (cookies[2]) {
                dispatch({ type: C.DATALAYER_INIT })
            }
            window.ytCookies = cookies[3]
            window.initDynatrace(cookies[4])
            window.initAlexametrics(cookies[5])
            window.pbjsCookies = cookies[6]
            if (cookies[6]) {
                dispatch({ type: C.ADASTA_INIT })
                UniversalId()
            }
        })()
    }, [])
}
