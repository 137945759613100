import { def } from 'Lib/sxc/js.utils'

/**
 * Usually called after getUpdatedCart to assign

 */
export default (myCart = {}) => {
    const { cart = [] } = myCart

    let cartLength = 0
    cart.forEach(voucher => {
        // ~~voucher.quantity force to 0 if prop not exists
        cartLength += ~~voucher.quantity
        if (def(voucher.premiumData)) {
            cartLength += voucher.premiumData.length
        }
    })
    return cartLength
}
