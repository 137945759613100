import React from 'react'

export default function Loader() {
    return (
        <div style={{ textAlign: 'center' }}>
            <img
                className="loader-classic"
                src="/assets/images/svg/loading.svg"
                alt=""
            />
            <img
                className="loader-negative"
                src="/assets/images/svg/loading_w.svg"
                alt=""
            />
        </div>
    )
}
