import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from '../../../../config/webpack/environment'

const schema = Joi.object({
    limit_start: '0',
    limit_size: '12',
    token: Joi.string(),
    user_id: Joi.number().integer(),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.notifyDomain}1.1/notifies/unread/get?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then((res) => res.json())

        if (res.response.code !== 200) {
            throw new Error('REQUEST_FAILED')
        }

        return res.response
    } catch (err) {
        throw err
    }
}
