import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { withAccessTokenOkAsync } from 'Lib/sxc/device/'
import { Environment } from "../../../../config/webpack/environment";

/**
 *
 *
 *
 *
 * Amazon login
 * @param {*} opts
 */
const schema1 = Joi.object({
    reqObj: Joi.object({
        access_token: Joi.string(),
        token_type: Joi.string(),
        is_all_accepted: 1,
        is_selling_accepted: 1,
        is_usage_accepted: 1,
        is_privacy_accepted: 1,
        is_marketing_accepted: 1,
    }),
}).options({ presence: 'required' })

export const requestLoginAmazon = async (opts = {}) => {
    const { error } = schema1.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}webapi/loginwithamazon`
    window.TEMP_LOGIN = 'amz'

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.code === 102) {
            throw new Error('CANNOT_SEND_DATA_TO_AMAZON')
        }

        if (res.code !== 200 && res.code !== 101) {
            const error = new Error('REQUEST_FAILED')
            error.code = 'RequestFailed'
            throw error
        }

        if (res.code === 101) {
            window.TEMP_TOKEN = res.data.access_token
            window.TEMP_ID = res.data.user_id
            withAccessTokenOkAsync(res)
        }

        return res
    } catch (err) {
        throw err
    }
}

/**
 *
 *
 *
 *
 * Amazon afterlogin
 * @param {*} opts
 */
const schema2 = Joi.object({
    reqObj: Joi.object({
        user_id: Joi.number().integer(),
        access_token: Joi.string(),
    }),
}).options({ presence: 'required' })

export const requestAfterLoginAmazon = async opts => {
    const { error } = schema2.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}api/afteramazonlogin?access_token=${window.TEMP_TOKEN}&session_id=${window.TEMP_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.code !== 101) {
            const error = new Error('REQUEST_FAILED')
            error.code = 'RequestFailed'
            throw error
        }

        window.TOKEN = res.data.access_token
        window.SESSION_ID = res.data.id
        window.EMAIL = res.data.email

        /**
         * save it on localStorage
         */
        localStorage.setItem('access_token', res.data.access_token)
        localStorage.setItem('session_id', res.data.id)

        return res
    } catch (err) {
        throw err
    }
}
