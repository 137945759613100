import { Environment } from '../../../../config/webpack/environment'

export default function appleAuthorize() {
    return new Promise((resolve, reject) => {
        try {
            AppleID.auth.init({
                clientId: Environment.appleClientId,
                redirectURI: window.location.origin,
                scope: 'email',
                usePopup: true,
            })

            AppleID.auth.signIn().then(({ authorization }) => {
                resolve(authorization)
            })
        } catch (err) {
            reject(err)
        }
    })
}
