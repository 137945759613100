import { Col } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import useLoginWithAmazon from '../hooks/useLoginWithAmazon'
import useLoginWithApple from '../hooks/useLoginWithApple'
import useLoginWithFacebook from '../hooks/useLoginWithFacebook'
import { useIntl } from 'react-intl'
import initAmzSdk from '../functions/initamzsdk'

const SocialButtons: React.FC = () => {
    const lang = useSelector((s: RootState) => s.Profile.language.toLowerCase())
    const intl = useIntl()
    const setLoginWithAmazon = useLoginWithAmazon()
    const setLoginWithFacebook = useLoginWithFacebook()
    const setLoginWithApple = useLoginWithApple()

    useEffect(() => {
        if (!lang) return
        initAmzSdk()
    }, [])

    return (
        <Col xs={24} className="social-btn-wrapper align-center">
            <>
                {/* AMAZON */}
                <div
                    id="LoginWithAmazon"
                    className={`social-btn`}
                    onClick={() => setLoginWithAmazon('login')}
                >
                    <div
                        className="social-icon"
                        style={{
                            backgroundImage:
                                "url('/assets/images/amz-icn.png')",
                            borderRadius: '50%',
                            backgroundSize: 'cover',
                        }}
                    ></div>
                    <div className="channel-name-2qzLW">
                        {' '}
                        {intl.formatMessage(
                            {
                                id: 'Common.loginWith',
                            },
                            { social: 'Amazon' },
                        )}
                    </div>
                </div>
                {/* FB */}
                <div
                    id="LoginWithFacebook"
                    className={`social-btn`}
                    onClick={() => setLoginWithFacebook('login')}
                >
                    <div
                        className="social-icon"
                        style={{
                            backgroundImage: "url('/assets/images/fb-icn.png')",
                            borderRadius: '50%',
                        }}
                    ></div>
                    <div className="channel-name-2qzLW">
                        {intl.formatMessage(
                            {
                                id: 'Common.loginWith',
                            },
                            { social: 'Facebook' },
                        )}
                    </div>
                </div>
                {/* APPLE */}
                <div
                    className={`social-btn`}
                    onClick={() => setLoginWithApple('login')}
                >
                    <div
                        className="social-icon"
                        style={{
                            fontSize: '28px',
                            textAlign: 'center',
                            height: '34px',
                            lineHeight: '1.3em',
                        }}
                    >
                        
                    </div>
                    <div className="channel-name-2qzLW">
                        {intl.formatMessage(
                            {
                                id: 'Common.loginWith',
                            },
                            { social: 'Apple' },
                        )}
                    </div>
                </div>
            </>
        </Col>
    )
}

export default SocialButtons
