import C from 'Lib/redux/constants'

/**
 * DATALAYER
 * @param {*} store
 */

export default function(store) {
    return next => action => {
        next(action)

        if (action.type === C.ROUTE_CHANGE) {
            store.dispatch({
                type: C.NOTIFY_PULL,
            })
        }

        if (action.type.includes('NOTIFICATIONS_')) {
            const prevTitle = document.title.replace(/\(.*?\)/, '')
            const count = store.getState().Notifications.merged.length
            const countTitle = countTitle > 99 ? '+99' : count

            document.title = count ? `(${countTitle}) ${prevTitle}` : prevTitle
        }
    }
}
