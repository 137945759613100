import { Environment } from '../../../../config/webpack/environment'

export default async () => {
    const url = `${Environment.payDomain}api/cart?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json())

        if (res.code === 1037) {
            throw new Error('USER_LIMITED')
        }

        if (res.code === 1043) {
            throw new Error('USER_DISABLED')
        }

        if (res.code === 1052) {
            throw new Error('EMAIL_TO_VALIDATE')
        }

        if (res.code === 1090) {
            throw new Error('DEVICE_TO_VALIDATE')
        }

        if (res.code === 102 && res.message === 'GENERIC_ERROR') {
            throw new Error('DELETE_CURRENT_CART')
        }

        if (res.code !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
