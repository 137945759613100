import Connect from './connect'
import UpdateUserInfo from './update.user.info'

export default async function Login(chatToken, userId, name, profileImg) {
    try {
        const user = await Connect(userId, chatToken)
        await UpdateUserInfo(name, profileImg)
        return user
    } catch (err) {
        console.log(err)
    }
}
