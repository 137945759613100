import { Environment } from "../../../../config/webpack/environment";

export default async () => {
    const url = `${Environment.apiDomain}api/checkorangestatus?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json())

        if (res.code === 1075) {
            throw new Error('USER_NOT_ORANGE')
        }

        if (res.code !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
