import C from 'Lib/redux/constants'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { Dropdown, Menu, Typography } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import useLanguage from './hooks/useLanguage'
import { Environment } from '../../../../../config/webpack/environment'
import { updateLanguage } from "Lib/sxc/language";

const { Text } = Typography
const Language: React.FC = () => {
    const dispatch = useDispatch()
    const language = useSelector((s: RootState) => s.Profile.language)
    const accessToken = useSelector((s: RootState) => s.Profile.accessToken)
    const userId = useSelector((s: RootState) => s.Profile.user_id)
    const list = Object.values(Environment.languageOptions)
    useLanguage()

    return (
        <Dropdown
            placement="bottomCenter"
            overlay={
                <Menu className="choose-language">
                    {list.map((val) => (
                        <Menu.Item
                            key={`lng-${val}`}
                            onClick={() => {
                                updateLanguage({
                                    access_token: accessToken,
                                    current_language: val,
                                    user_id: userId,
                                })
                                dispatch({
                                    type: C.CHANGE_LANGUAGE,
                                    payload: val,
                                })
                            }}
                        >
                            <span>{val.toUpperCase()}</span>
                        </Menu.Item>
                    ))}
                </Menu>
            }
            trigger={['hover']}
            arrow
        >
            <Text
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
            >
                {language} <CaretDownOutlined />
            </Text>
        </Dropdown>
    )
}

export default Language
