import C from 'Lib/redux/constants'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Row, Col, Typography } from 'antd'
import useCountryCode from './hooks/useCountryCode'
import { getCookiesConsent } from 'Lib/sxc/cookies'
import UniversalId from 'Lib/pixels/adasta/universal.id'
import { Environment } from '../../../config/webpack/environment'
import { socialLinks } from './links'
import Email from './components/email';
import Support from './components/support';

const { Text } = Typography

const Footer: React.FC = () => {
    const history = useHistory()
    const intl = useIntl()
    const dispatch = useDispatch()
    const country = useSelector((s: RootState) => s.Profile.country)
    const [countryCode] = useCountryCode()

    function showQuantcast() {
        window.__tcfapi('displayConsentUi', 2, () => {
            ;(async () => {
                const cookies = await getCookiesConsent()
                window.initAnalytics(cookies[2])
                window.googleAdsCookie(cookies[2])
                if (cookies[2]) {
                    dispatch({ type: C.DATALAYER_INIT })
                }
                window.ytCookies = cookies[3]
                window.initDynatrace(cookies[4])
                window.initAlexametrics(cookies[5])
                window.pbjsCookies = cookies[6]
                if (cookies[6]) {
                    dispatch({ type: C.ADASTA_INIT })
                    UniversalId()
                }
            })()
        })
    }

    return (
        <div id="footer">
            <Row className="wrapper" gutter={[20, 20]} justify="center">
                <Col md={2} className="the4">
                    <Support/>
                </Col>
                <Col md={4} className="the4">
                    <Text onClick={() => history.push('/conditions-of-use')}>
                        {intl.messages['Footer.conditions-of-use']}
                    </Text>
                    <Text onClick={() => history.push('/conditions-of-sale')}>
                        {intl.messages['Footer.conditions-of-sale']}
                    </Text>
                </Col>
                <Col md={4} className="the4">
                    <Text onClick={() => history.push('/privacy')}>
                        {intl.messages['Footer.privacy-policy']}
                    </Text>
                    <Text onClick={() => history.push('/cookies')}>
                        {intl.messages['Footer.cookie-policy']}
                    </Text>
                </Col>
                <Col md={4} className="the4">
                    {country !== 'US' ? (
                        <Text onClick={showQuantcast}>
                            {intl.messages['Footer.confidentiality']}
                        </Text>
                    ) : null}
                    {countryCode === 'LU' ? (
                        <Text onClick={() => history.push('/refund_policy')}>
                            REFUND POLICY
                        </Text>
                    ) : null}
                    <br />
                </Col>
                <Col md={5}>
                    <Text strong>{intl.messages['Footer.social']}</Text>
                    <Row gutter={[20, 20]}>
                        <Col>
                            <a
                                target="_blank"
                                href={
                                    socialLinks.fb[Environment.defaultCountry]
                                }
                                rel="noreferrer"
                            >
                                <i
                                    className="fa fa-facebook-official"
                                    style={{ fontSize: '20px' }}
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </Col>
                        <Col>
                            <a
                                target="_blank"
                                href={
                                    socialLinks.ig[Environment.defaultCountry]
                                }
                                rel="noreferrer"
                            >
                                <i
                                    className="fa fa-instagram"
                                    style={{ fontSize: '20px' }}
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="wrapper" justify="center">
                <Col md={12} className="align-center">
                    <Text>
                        &copy; Copyright {Environment.companyName.toUpperCase()}{' '}
                        {Environment.defaultCountry === 'US'
                            ? '2022'
                            : '2014 - 2022'}
                    </Text>
                </Col>
            </Row>
            <Row className="wrapper last">
                <Col span={24} className="align-center">
                    {Environment.defaultCountry === 'IT' ? (
                        <Text>
                            {intl.messages['Footer.address.it.inc']} -{' '}
                            {intl.messages['Footer.address.it.address']}{' '}
                            {intl.messages['Footer.address.it.zip']}{' '}
                            {intl.messages['Footer.address.it.city']}{' '}
                            {intl.messages['Footer.address.it.country']}
                        </Text>
                    ) : Environment.defaultCountry === 'US' ? (
                        <Text>
                            {intl.messages['Footer.address.us.inc']} -{' '}
                            {intl.messages['Footer.address.us.address']}{' '}
                            {intl.messages['Footer.address.us.zip']}{' '}
                            {intl.messages['Footer.address.us.city']}{' '}
                            {intl.messages['Footer.address.us.country']}
                        </Text>
                    ) : (
                        <>
                            <Text>
                                {intl.messages['Footer.address.lu.inc']} -{' '}
                                {intl.messages['Footer.address.lu.address']}{' '}
                                {intl.messages['Footer.address.lu.zip']}{' '}
                                {intl.messages['Footer.address.lu.country']}
                            </Text>
                            <Text>Registration Number: B247407</Text>
                            <Text>VAT Number: LU32487323</Text>
                            <Email/>
                            <Text>tel. +1 919 617 6384</Text>
                        </>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default Footer
