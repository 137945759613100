// DEFINE THE GATEWAY NAMES (AVOIDING MISPELLINGS OR TYPOS)
export const COMNPAY = {
        label: 'credit_card',
        type: 'comnpay',
        image: 'comnpay',
    },
    EZPAY = {
        label: 'credit_card',
        type: 'ezpay',
        image: 'mistralpay',
    },
    SOFORT = {
        label: 'sofort',
        type: 'sofort',
        image: 'sofort',
    },
    BITSAPAY = {
        label: 'bitsa',
        type: 'bitsapay',
        image: 'bitsa',
    },
    MISTRAL = {
        label: 'credit_card',
        type: 'mistral',
        image: 'mistralpay',
    },
    PAYHUB = {
        label: 'payhub',
        type: 'payhub',
        image: 'payhub',
    },
    CONNEXPAY = {
        label: 'connexpay',
        type: 'connexpay',
        image: 'connexpay',
    },
    REVOLUT = {
        label: 'revolut',
        type: 'revolut',
        image: 'revolut',
    },
    PAYEER = {
        label: 'payeer',
        type: 'payeer',
        image: 'payeer',
    },
    PAYPAL = {
        label: 'paypal',
        type: 'paypal',
        image: 'paypal',
    },
    STRIPE = {
        label: 'stripe',
        type: 'stripe',
        image: 'stripe',
    },
    YAPILY = {
        label: 'yapily',
        type: 'yapily',
        image: 'yapily',
    }

//THIS CLASS BUILDS THE LIST OF THE PAYMENTS METHOD
// HERE YOU CAN EDIT METHOD's NAME,IMAGES and TYPE
// IF YOU HAVE TO ADD A NEW METHOD ADD IT HERE
export function methods(list) {
    const gatewayList = {}

    list.forEach((l) => {
        gatewayList[l.type] = { ...l }
    })
    return {
        ...gatewayList,
    }
}
