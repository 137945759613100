import { withAccessTokenOkAsync } from 'Lib/sxc/device/'
import { Environment } from '../../../../config/webpack/environment'

export default async () => {
    const url = `${Environment.apiDomain}api/getagainverificationtoken?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json())

        withAccessTokenOkAsync(res)

        return res
    } catch (err) {
        throw err
    }
}
