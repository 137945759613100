import C from 'Lib/redux/constants'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useMedia } from 'react-use'
import { Col, Row, Card, Divider, Typography } from 'antd'
import SignInForm from './components/SignInForm'
import { useIntl } from 'react-intl'
import SocialButtons from './components/SocialButtons'

const SignIn: React.FC = () => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const isMd = useMedia('(min-width: 768px)')

    return (
        <Row id="signin" justify="center">
            {isMd ? (
                <Col span={12} className="violet-column">
                    <img src="/assets/images/login.svg" alt="login" />
                </Col>
            ) : null}
            <Col md={12} sm={24} xs={24}>
                <Card>
                    <Typography.Title level={3}>
                        {intl.messages['Common.hello']}
                    </Typography.Title>
                    <Typography.Title level={5}>
                        {intl.messages['Common.login']}
                    </Typography.Title>
                    <SignInForm />
                    <Divider />
                    <SocialButtons />
                    <Divider />
                    <a
                        className="link"
                        onClick={() =>
                            dispatch({ type: C.AUTH_SET, payload: 'signup' })
                        }
                    >
                        {intl.messages['Common.not_registered']}
                    </a>
                    <div id="fb-root"></div>
                </Card>
            </Col>
        </Row>
    )
}

export default SignIn
