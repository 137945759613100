import { encode } from 'html-entities'

// Return if argument supplied is defined.
export const def = (x: unknown): boolean => typeof x !== 'undefined'

// Return if argument supplied is undefined.
export const undef = (x: unknown): boolean => !def(x)

// Return if argument supplied is null
export const isnull = (x: unknown): boolean => x === null

// Return the string sanitize of html entities.
export const entities = (st: string): string => encode(st)

// return the value forced to type string
export const str = (x: unknown): string => String(x)

// return array of unique values, in order, from all given arrays
export const union = (...args: unknown[][]): unknown[] =>
    args.reduce(
        (a, b) => a.concat(b.filter((val: unknown) => !a.includes(val))),
        [],
    )

// Return a random integer in a specific range
export const getRandomInt = (min: number, max: number): number => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

// Return an array of object ordered asc/desc by property
export const orderBy = (key: any, asc = 'asc') => {
    const order = asc === 'asc' ? 1 : -1

    return (a: any[], b: any[]) =>
        a[key] > b[key] ? order : b[key] > a[key] ? -order : 0
}

// Return an array shuffle ordered
export const shuffle = (x: unknown[]): unknown[] => {
    for (let i = x.length - 1; i >= 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1))
        const itemAtIndex = x[randomIndex]
        x[randomIndex] = x[i]
        x[i] = itemAtIndex
    }
    return x
}

export const getValueFromQueryParam = (param = ''): string | null => {
    const params = window.location?.search?.split('&') || []
    const [string] = params.filter((val) => val.includes(param))

    if (string) {
        const [search, value] = string.split('=') || []
        return value
    } else {
        return null
    }
}
