import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import debugApi from 'Lib/sxc/debug.api'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        use_ticket: Joi.bool(),
        use_card: Joi.bool(),
    }),
}).options({ presence: 'required' })

/**
 *
 *
 *
 */
export default async (opts) => {
    const { error, errors, value } = schema.validate(opts)
    debugApi(error, errors, value)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.payDomain}api/cart?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()

        value.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        }).then((res) => res.json())

        if (res.code === 1090) {
            throw new Error('DEVICE_TO_VALIDATE')
        }

        if (res.code !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
