/**
 *
 * @param {*} text
 * @param {*} channel
 * @param {*} data
 */
export default function SendChannelMessage(text, channel, data) {
    return new Promise((resolve, reject) => {
        const params = new window.sb.UserMessageParams()
        params.message = text
        params.data = data
        params.mentionType = 'users' // Either 'users' or 'channel'
        // params.mentionedUserIds = ['Jeff', 'Julia'] // or mentionedUsers = Array<User>;
        // params.metaArrayKeys = ['key1', 'key2']
        // params.translationTargetLanguages = ["de"] // French and German
        params.pushNotificationDeliveryOption = 'default' // Either 'default' or 'suppress'

        channel.sendUserMessage(params, (message, error) => {
            if (error) {
                reject(error)
            }

            window.sb.GroupChannel.getChannel(channel.url, (newCh, err) => {
                if (err) {
                    reject(err)
                }

                resolve({ freshChannel: newCh, message })
            })

            // create meta keys for other features
            // channel.createMessageMetaArrayKeys(message, ['like'], (_, err) => console.log(err))
        })
    })
}
