import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

export default async () => {
    const user_id = window.SESSION_ID || window.TEMP_ID
    const token = window.TOKEN || window.TEMP_TOKEN

    const opts = {
        user_id: String(user_id),
    }

    const url = `${Environment.devicesDomain}check_user_phone_register?access_token=${token}&session_id=${user_id}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        return res.data.result === '601'
    } catch (err) {
        throw err
    }
}
