export default function loadChannel(channelUrl) {
    return new Promise((resolve, reject) => {
        window.sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
            if (error) {
                reject(error)
            }

            resolve(channel)
        })
    })
}
