/**
 *
 * @param {*} editedMsg
 * @param {*} channel
 * @param {*} messageId
 */
export default function updateMessage(editedMsg, channel, messageId) {
    return new Promise((resolve, reject) => {
        const params = new window.sb.UserMessageParams()
        params.message = editedMsg
        channel.updateUserMessage(messageId, params, (message, error) => {
            if (error) {
                reject(error)
            }
            resolve(true)
        })
    })
}
