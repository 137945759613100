import { AppConfig } from './AppConfig'
import { countries } from '../sxc/countries/countries'
import {ads, enablePromoBanner} from '../sxc/ad-banners/adBanners'
import { languages } from '../sxc/languages/languages'
import { offers } from '../sxc/orange-offers/offers'
import { endpoints } from './endpoints/endpoints'
import { amazon } from './amazon/amazon'
import { clientSecurity } from './client-security/client-security'
import { app } from './app/app'
import { default as constants } from '../sxc/templates/constants'
import { facebook } from './facebook/facebook'
import { localization } from './localization/localization'

export {
    paymentsObj,
    paymentsObjRicaricacell,
    paymentsObjBulletin,
    paymentsObjOrange,
} from '../sxc/payments/payments'

export const Environment = {
    adBanners: ads,
    enablePromoBanner: enablePromoBanner,
    companyName: constants.title,
    countries: countries,
    languageOptions: languages,
    orangeOffers: offers,

    ...localization,
    ...app,
    ...endpoints,
    ...clientSecurity,
    ...amazon,
    ...facebook,
} as AppConfig
