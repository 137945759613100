export default function CreateGroupChannel(
    operator,
    partecipants,
    channelName,
    img,
    isSuper = false,
) {
    return new Promise((resolve, reject) => {
        const params = new window.sb.GroupChannelParams()
        params.isPublic = false
        params.isEphemeral = false
        params.isSuper = isSuper
        params.operatorUserIds = [operator]
        params.addUserIds(partecipants)
        params.name = channelName

        if (!!img && img.length) {
            params.coverImage = img[0]
        } else {
            params.coverUrl = ''
        }
        params.data = `{ "superAdmin": "${operator}" }`
        params.isDistinct = false

        window.sb.GroupChannel.createChannel(params, (groupChannel, error) => {
            if (error) {
                console.log(error)
                reject(error)
            }
            resolve(groupChannel)
        })
    })
}
