/**
 *
 */
import { Environment } from '../../../../config/webpack/environment'

export default async (image) => {
    const url = `${Environment.apiDomain}api/uploaduserprofileimages?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    const fd = new FormData()
    fd.append('user_media', image)
    fd.append(
        'reqObj',
        JSON.stringify({ user_id: window.SESSION_ID, user_media: {} }),
    )

    try {
        const res = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            body: fd,
        }).then(res => res.json())

        if (res.message !== 'SUCCESS') {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
