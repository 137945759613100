import C from 'Lib/redux/constants'
import React from 'react'
import { Col, Row, Card, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useMedia } from 'react-use'
import Registration from './components/Registration'
import usePreRegister from './hooks/usePreRegister'
import useRegister from './hooks/useRegister'
import useScripts from './hooks/useScripts'

const Register: React.FC = () => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const [msg, onFinish] = usePreRegister()
    const isMd = useMedia('(min-width: 768px)')
    useRegister()
    useScripts()

    return (
        <Row id="register" justify="center">
            {isMd ? (
                <Col md={12} className="violet-column">
                    <img src="/assets/images/signup.svg" alt="login" />
                </Col>
            ) : null}
            <Col md={12} sm={24} xs={24}>
                <Card>
                    <Typography.Title level={3}>
                        {intl.messages['Common.register']}
                    </Typography.Title>

                    <Registration msg={msg} onFinish={onFinish} />

                    <a
                        className="link"
                        onClick={() =>
                            dispatch({ type: C.AUTH_SET, payload: 'signin' })
                        }
                    >
                        {intl.messages['Common.already_registered']}
                    </a>
                </Card>

                <div id="fb-root"></div>
            </Col>
        </Row>
    )
}

export default Register
