import { Environment } from '../../../config/webpack/environment'

export default function <T, U, V>(error: T, errors: U, value: V): void {
    if (Environment.debugMode && error) {
        if (errors) {
            console.log(errors)
        } else {
            console.log(error)
        }
        console.log(value)
    }
}
