import C from 'Lib/redux/constants'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { Modal } from 'antd'
import SignIn from './signin'
import Register from './register'
import ForgotPassword from './forgotpassword'
import FirstOffer from './firstoffer'

const Auth: React.FC = () => {
    const dispatch = useDispatch()
    const auth = useSelector((s: RootState) => s.Auth)

    if (!auth) {
        return null
    }

    return (
        <Modal
            visible
            footer={null}
            title={null}
            onCancel={() => dispatch({ type: C.AUTH_SET, payload: null })}
            className="auth-modal"
        >
            {auth === 'signin' ? <SignIn /> : null}
            {auth === 'signup' ? <Register /> : null}
            {auth === 'forgot' ? <ForgotPassword /> : null}
            {auth === 'firstoffer' ? <FirstOffer /> : null}
        </Modal>
    )
}

export default Auth
