import C from 'Lib/redux/constants'
import produce from 'immer'
import { PagamenoState, PagamenoPremiumType } from 'Lib/types/pagameno'

const initialState: PagamenoState = {
    product: '',
    card: { cardId: '', premium: [] },
    offerAmount: { withCi: {}, withOutCi: {}, creditSelection: 'withOutCi' },
    form: {},
    sxcCardInUse: 0,
    sxcTicketInUse: 0,
}

const BulletinReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.PAGAMENO_ADD_PREMIUM:
            draft.card.premium.push(payload)
            break
        case C.PAGAMENO_CHOOSE_PRODUCT:
            draft.product = payload
            break
        case C.PAGAMENO_REMOVE_PREMIUM:
            draft.card.premium = [
                ...draft.card.premium.filter(
                    (val: PagamenoPremiumType) => val.cardId !== payload,
                ),
            ]
            break
        case C.PAGAMENO_SET_CREDITSELECTION:
            draft.offerAmount.creditSelection = payload
            break
        case C.PAGAMENO_SET_OFFERAMOUNT:
            draft.offerAmount.withCi = payload.withCi
            draft.offerAmount.withOutCi = payload.withOutCi
            break
        case C.PAGAMENO_SET_CARD:
            draft.card = { ...draft.card, ...payload }
            break
        case C.PAGAMENO_SET_FORM:
            draft.form = payload
            break
        case C.PAGAMENO_TOGGLE_SXCCARD:
            draft.sxcCardInUse = payload
            break
        case C.PAGAMENO_TOGGLE_SXCTICKET:
            draft.sxcTicketInUse = payload
            break
        case C.PAGAMENO_RESET:
        case C.CHECKOUT_COMPLETED:
        case C.LOGOUT:
            return { ...initialState }
        default:
            return draft
    }
})

export default BulletinReducer
