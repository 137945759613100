import C from 'Lib/redux/constants'
import produce from 'immer'
import { RegistrationStaste } from 'Lib/types/registration'

const initialState: RegistrationStaste = {
    password: '',
    firstName: '',
    lastName: '',
    country: '',
    promotional_code: '',
    registrationType: 'classic',
    preId: 0,
    preRegistered: false,
    loading: false,
}

const RegistrationReducer = produce(
    (draft = initialState, { type, payload }) => {
        switch (type) {
            case C.PREREGISTRATION:
                draft.email = payload.email
                draft.password = payload.password
                draft.preId = payload.preId
                draft.firstName = payload.firstName
                draft.lastName = payload.lastName
                draft.country = payload.country
                draft.preRegistered = true
                break
            case C.PREREGISTRATION_AMZ:
                draft.firstName = payload.firstName
                draft.lastName = payload.lastName
                draft.preId = payload.preId
                draft.password = payload.password
                draft.registrationType = 'amazon'
                break
            case C.PREREGISTRATION_APPLE:
                draft.email = payload.email
                draft.preId = payload.preId
                draft.password = payload.password
                draft.registrationType = 'apple'
                break
            case C.PREREGISTRATION_FB:
                draft.firstName = payload.firstName
                draft.lastName = payload.lastName
                draft.email = payload.email
                draft.preId = payload.preId
                draft.password = payload.password
                draft.facebook_id = payload.facebook_id
                draft.facebook_accesstoken = payload.facebook_accesstoken
                draft.registrationType = 'facebook'
                break
            case C.PREREGISTRATION_ENABLED:
                draft.country = payload.country
                draft.firstName = payload.firstName
                draft.lastName = payload.lastName
                draft.preRegistered = true
                break
            case C.REGISTRATION_PROMOCODE:
                draft.promotional_code = payload.promotional_code
                break
            case C.REGISTRATION_SKIP:
                draft.step = draft.step + 1
                break
            case C.REGISTRATION_LOADING:
                draft.loading = payload
                break
            default:
                return draft
        }
    },
)

export default RegistrationReducer
