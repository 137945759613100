import React from "react";
import {useIntl} from "react-intl";

function Support() {
    const intl = useIntl()

    return (
        <a
            href='mailto:support@sixthcontinent.com'
        >
            {intl.messages['Footer.support']}
        </a>
    )
}

export default Support