import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        gateway: Joi.string(),
        deviceType: Joi.string(),
        isMobile: Joi.boolean(),
        purchaseType: Joi.string(),
        iban: Joi.string(),
        institutionId: Joi.string(),
        country: Joi.string(),
        userAccount: Joi.object({
            name: Joi.string(),
            surname: Joi.string(),
        }),
    }),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error, value } = schema.validate(opts)
    if (error) {
        console.error(error)
        throw new Error('INVALID_OPTS')
    }

    const url = `${Environment.payDomain}api/payment/yapily/create?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()

        value.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        }).then((res) => res.json())

        if (res.code !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
