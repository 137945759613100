import React, { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import md5 from 'md5'
import { Environment } from '../../../config/webpack/environment'

const Kount: React.FC = () => {
    const accessToken = useSelector((s: RootState) => s.Profile.accessToken)
    /**
     *
     */
    useLayoutEffect(() => {
        if (!accessToken) return
        if (Environment.defaultCountry !== 'US') return

        const src = Environment.kountSandbox
            ? 'https://tst.kaptcha.com'
            : 'https://ssl.kaptcha.com'

        const url = `${src}/collect/sdk?m=100077&s=${md5(`${accessToken}`)}`

        fetch(url)
            .then((res) => res.text())
            .then((res) => {
                const script = document.createElement('script')
                script.innerHTML = res
                document.body.appendChild(script)

                const client = new ka.ClientSDK()
                client.autoLoadEvents()
            })
    }, [accessToken])

    return <div className="kaxsdc" data-event="load"></div>
}

export default Kount
