import getFinger from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

export default async function resetToken() {
    try {
        let chatToken = await _resetChatToken()
        if (!chatToken) {
            chatToken = await _reInitAccessToken()
        }
        return chatToken
    } catch (err) {
        console.log(err)
        return null
    }
}

/**
 * Call the reset chat token api
 * If user doesn't exists on Sendbird return false
 */
const _resetChatToken = async () => {
    try {
        const url = `${Environment.apiDomain}api/resetchattoken?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`
        const { chat_token } = await fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then((res) => {
                const { code } = res
                switch (code) {
                    case 101:
                        return res.data
                    case 102:
                        return false
                    default:
                        return false
                }
            })

        return chat_token
    } catch (err) {
        console.log(err)
        return null
    }
}

/**
 * Call viewmultiprofile e get a new chat token
 */
const _reInitAccessToken = () =>
    new Promise((resolve) => {
        getFinger((finger) => {
            const url = `${Environment.apiDomain}api/viewmultiprofiles?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`
            const opts = {
                reqObj: {
                    user_id: window.SESSION_ID,
                    hash: finger,
                    profile_type: 4,
                },
            }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(opts),
            })
                .then((response) => response.json())
                .then(async (res) => {
                    resolve(res?.data?.chat_token)
                })
                .catch((error) => {
                    console.error('Error:', error)
                })
        })
    })
