import { Environment } from '../../../../config/webpack/environment'

export default (store) => {
    return (next) =>
        ({ type = '', payload = {}, history = {} }) => {
            try {
                if (type.includes('GET_PROFILE')) {
                    if (
                        !payload.is_all_accepted &&
                        Environment.defaultCountry !== 'US'
                    ) {
                        history.push('/new-privacy')
                        return
                    }
                }

                if (type.includes('ROUTE_CHANGE')) {
                    const user = store.getState().Profile
                    if (
                        user.id &&
                        !user.is_all_accepted &&
                        ENV_COUNTRY !== 'US'
                    ) {
                        history.push('/new-privacy')
                        return
                    }
                }
            } catch (err) {
                console.log(err)
            } finally {
                next({ type, payload })
            }
        }
}
