import C from 'Lib/redux/constants'
import { useLayoutEffect } from 'react'
import { useState, useRef, useEffect } from 'react'
import {
    requestLoginApple,
    requestAfterLoginApple,
    generatePwd,
    appleAuthorize,
} from 'Lib/sxc/loginregister'
import configProfile from '../functions/configProfile'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {useLocation} from 'react-use';

export default function useLoginWithApple(): any {
    const history = useHistory()
    const dispatch = useDispatch()
    const [type, setType] = useState('')

    const isMounted = useRef(true)
    const location = useLocation()
    const isDeviceReset = location.state?.state?.deviceReset

    useLayoutEffect(() => {
        const scriptTag = document.createElement('script')
        scriptTag.src =
            'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
        document.body.appendChild(scriptTag)
    }, [])

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (!type) {
            return
        }

        (async () => {
            try {
                const auth = await appleAuthorize()
                const opts = {
                    is_all_accepted: true,
                    is_selling_accepted: true,
                    is_usage_accepted: true,
                    is_privacy_accepted: true,
                    is_marketing_accepted: true,
                    social_auth_token: auth.code,
                    from_mobile_device: false,
                    provider: 'apple',
                }

                const msg = await requestLoginApple(opts)

                if (msg === 'error') {
                    return
                }
                if (msg === 'preregistration') {
                    dispatch({
                        type: C.PREREGISTRATION_APPLE,
                        payload: {
                            email: window.TEMP_EMAIL,
                            preId: window.TEMP_ID,
                            password: generatePwd(),
                        },
                    })
                    dispatch({
                        type: C.AUTH_SET,
                        payload: 'signup',
                    })
                    return
                }

                /**
                 * go on with login
                 */

                const res2 = await requestAfterLoginApple(opts)

                dispatch({
                    type: C.LOGIN,
                    payload: {
                        user_email: window.EMAIL,
                    },
                })
                dispatch({
                    type: C.AUTH_SET,
                    payload: null,
                })

                await configProfile(dispatch, history, false, isDeviceReset ? '/device' : null)
            } catch (err) {
                console.log(err)
            }
        })()
    }, [type])

    return setType
}
