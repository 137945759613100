import React from 'react'
import { Col, Row, Card, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { formatPrice } from 'Lib/sxc/currency'
import { useMedia } from 'react-use'
import { ButtonOrange } from 'Lib/components/Buttons'
import firstBuy from '../../../json/first_buy.json'
import { Environment } from "../../../../config/webpack/environment";

const FirstOffer: React.FC = () => {
    const history = useHistory()
    const intl = useIntl()
    const isMd = useMedia('(min-width: 768px)')

    return (
        <Row id="register" justify="center">
            {isMd ? (
                <Col md={12} className="violet-column">
                    <img src="/assets/images/firstbuy.svg" alt="login" />
                </Col>
            ) : null}
            <Col md={12} sm={24} xs={24}>
                <Card>
                    <Typography.Title level={3}>
                        {intl.messages['Registration.buy_cards']}
                    </Typography.Title>
                    <Row justify="center">
                        <Col span={24}>
                            <div className="shop-card-img">
                                <img src={firstBuy[Environment.defaultCountry].img[0]} />
                            </div>
                        </Col>
                        <Col
                            span={24}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <Row justify="center" className="price-example">
                                <Col xs={24}>
                                    <Typography.Paragraph
                                        style={{
                                            color: '#b3b3b3',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {intl.messages['Common.example']}:
                                    </Typography.Paragraph>
                                </Col>
                                <Col xs={24}>
                                    <Typography.Paragraph>
                                        <span>
                                            {
                                                intl.messages[
                                                    'Wallet.Initial Cost'
                                                ]
                                            }
                                            :
                                        </span>{' '}
                                        <strong>
                                            {formatPrice({ price: 25 })}
                                        </strong>
                                    </Typography.Paragraph>
                                </Col>
                                <Col xs={24}>
                                    <Typography.Paragraph>
                                        <span>
                                            {intl.messages['Common.points']}:
                                        </span>{' '}
                                        <strong>12500</strong>
                                    </Typography.Paragraph>
                                </Col>
                                <Col xs={24}>
                                    <Typography.Paragraph>
                                        <span>
                                            {
                                                intl.messages[
                                                    'Common.total_to_pay'
                                                ]
                                            }
                                            :
                                        </span>{' '}
                                        <strong>
                                            <span>
                                                {formatPrice({ price: 25 })}
                                            </span>
                                            <span>
                                                {formatPrice({ price: 20 })}
                                            </span>
                                        </strong>
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <ButtonOrange
                        className="ant-btn-lg"
                        onClick={() =>
                            history.push(firstBuy[Environment.defaultCountry].url)
                        }
                    >
                        {intl.messages['Common.view_offer']}
                    </ButtonOrange>

                    <a
                        className="skip"
                        onClick={() => history.push('/shopping')}
                    >
                        {intl.messages['Common.skip']}
                    </a>
                </Card>

                <div id="fb-root"></div>
            </Col>
        </Row>
    )
}

export default FirstOffer
