import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Badge, Tooltip } from 'antd'

const Cart: React.FC = () => {
    const history = useHistory()
    const intl = useIntl()
    const countCart = useSelector((s: RootState) => s.Cart.length)
    const [visible, setVisible] = useState(false)

    const goToCart = () => {
        if (!countCart) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
            }, 3000)
            return
        }
        history.push('/cart')
    }

    return (
        <span onClick={goToCart} style={{ cursor: 'pointer' }}>
            <Tooltip
                visible={visible}
                placement="bottom"
                title={intl.messages['Common.cart-empty']}
            >
                <Badge count={countCart}>
                    <span className="header-icon icon-cart"></span>
                </Badge>
            </Tooltip>
        </span>
    )
}

export default Cart
