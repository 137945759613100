import { Environment } from '../../../../config/webpack/environment'

const STORAGE_KEY = 'sxc.wallet_income'

export default async function getSmUserCredits(options = {}) {
    const storedWallet = JSON.parse(localStorage.getItem(STORAGE_KEY))

    /**
     * if profile already exist on localstorage get it
     */
    if (storedWallet && !options.forceApi && !options.friendId) {
        return storedWallet
    }

    const url = `${Environment.apiDomain}api/getcitizenwalletincome?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`
    const opts = {
        reqObj: {
            buyer_id: options.friendId || window.SESSION_ID,
        },
    }

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then((res) => res.json())

        if (res.message !== 'SUCCESS') {
            throw new Error('REQUEST_FAILED')
        }

        /**
         * save it on localStorage if is not about a friend
         */
        if (!options.friendId) {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(res))
        }

        return res
    } catch (err) {
        throw err
    }
}
