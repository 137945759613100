export const offers = [
    {
        offer_id: 1259,
        shop_id: 52271,
        card_id: '42438833-602f-440c-b7aa-c9eaa3b5bcb9',
        type: 'premium',
    },
    {
        offer_id: 1258,
        card_id: 'f4b2b4a3-b0b4-47e3-ac9b-fdc82615c7fa',
        shop_id: 52271,
        type: 'premium',
    },
    /** Card USD 30 for the moment deactivated
     *{
        offer_id: 1272,
        card_id: '9f427cca-806f-4e1e-a254-2ea7119264c4',
        shop_id: 52271,
        type: 'premium',
    }*/
]