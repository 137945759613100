import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from '../../../../config/webpack/environment'

const schema = Joi.object({
    reqObj: Joi.object({
        message_type: Joi.string().optional(),
        notification_id: Joi.alternatives(
            Joi.number(),
            Joi.string(),
        ).optional(),
        user_id: Joi.string(),
    }),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error, value } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}api/markdeletenotifications?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        value.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        }).then((res) => res.json())

        if (res.code !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
