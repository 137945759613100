import C from 'Lib/redux/constants'
import { produce } from 'immer'
import { ShoppingState } from 'Lib/types/shopping'

const initialState: ShoppingState = {
    category: 'cat-all',
    country: null,
    filter: 'suggested',
    query: '*',
    welcomeCredits: false,
    sort: null
}

const ShoppingReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.SHOPPING_CATEGORY:
            draft.category = payload
            break
        case C.SHOPPING_COUNTRY:
            draft.country = payload
            break
        case C.SHOPPING_FILTER:
            draft.filter = payload
            break
        case C.SHOPPING_SEARCH:
            draft.query = payload.query
            draft.welcomeCredits = payload.welcomeCredits
            draft.sort = payload.sort
            break
        case C.LOGOUT:
            return { ...initialState }
        default:
            return draft
    }
})

export default ShoppingReducer
