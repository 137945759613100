import C from 'Lib/redux/constants'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getSmUserCredits } from 'Lib/sxc/wallet'

export default function useGetCredits(accessToken: string): void {
    const dispatch = useDispatch()

    useEffect(() => {
        if (!accessToken) return
        ;(async () => {
            try {
                const res = await getSmUserCredits({ forceApi: true })
                const { result } = res.response

                dispatch({
                    type: C.USER_CREDITS,
                    payload: {
                        points: result.points_available,
                        credits: result.citizen_income_available,
                    },
                })
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])
}
