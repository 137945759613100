import C from 'Lib/redux/constants'
import { produce } from 'immer'

const initialState: string | null = null

const AuthReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.AUTH_SET:
            return payload
        default:
            return draft
    }
})

export default AuthReducer
