/**
 * SF-498
 * @param {*} step
 * @param {*} lang
 */
import { Environment } from '../../../../config/webpack/environment'

export default function(step = 1) {
    const lang = Environment.defaultCountry.toLocaleLowerCase()

    fetch(
        `https://adagiofe.neodatagroup.com/ad/sixthcontinent.jsp?sid=1022&loc=sixth_trk_stp_all_step${step}&lang=${lang}&rnd=1590048496257`,
    )
}
