export default function CreateSingleChannel(creator, friendIds) {
    return new Promise((resolve, reject) => {
        const params = new window.sb.GroupChannelParams()
        params.isPublic = false
        params.isEphemeral = false
        params.addUserIds([creator, friendIds.toString()])
        params.coverUrl = ''
        params.isDistinct = true
        params.name = `${creator}_and_${friendIds}`
        // set the Operator as the creator of the group
        // if (friendIds.length > 1) params.operatorUserIds = [creator]
        window.sb.GroupChannel.createChannel(params, (groupChannel, error) => {
            if (error) {
                reject(error)
            }
            resolve(groupChannel)
        })
    })
}
