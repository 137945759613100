import React, { useRef } from 'react'
import { Form, Input, Button, Row, Col, Divider, Alert, Select } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { useIntl } from 'react-intl'
import useCheckPasswordStrength, {
    lowerAndUpper,
    numberAndCharacters,
    symbols,
} from '../hooks/useCheckPasswordStrength'
import { Environment } from '../../../../../config/webpack/environment'
import Loader from 'Lib/ui/Loader'

interface IProps {
    msg: string
    onFinish: () => void
}

const Registration: React.FC<IProps> = ({ msg, onFinish }) => {
    const intl = useIntl()
    const formRef = useRef(null)
    const language = useSelector((s: RootState) => s.Profile.language)
    const registration = useSelector((s: RootState) => s.Registration)

    const { setPassword } = useCheckPasswordStrength()

    const checkPasswordStrength = (e: any) => {
        setPassword(e.target.value)
    }

    /**
     * if a social pre-registration is done
     * fill the form with the data we have
     */

    if (registration.email && formRef.current) {
        formRef.current.setFieldsValue({
            email: registration.email,
        })
    }

    if (registration.firstName && formRef.current) {
        formRef.current.setFieldsValue({
            firstName: registration.firstName,
        })
    }

    if (registration.lastName && formRef.current) {
        formRef.current.setFieldsValue({
            lastName: registration.lastName,
        })
    }

    if (registration.password && formRef.current) {
        formRef.current.setFieldsValue({
            password: registration.password,
            confirmPassword: registration.password,
        })
    }

    return (
        <Row justify="center" className="form" style={{ marginTop: '20px' }}>
            <Col xs={24}>
                <Form
                    ref={formRef}
                    name="reg"
                    layout="vertical"
                    onFinish={onFinish}
                    // initialValues={{
                    //     country: env.defaultCountry,
                    // }}
                >
                    <Form.Item
                        label={intl.messages['Common.form.email']}
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: String(
                                    intl.messages['Common.form.enter_email'],
                                ),
                            },
                        ]}
                    >
                        <Input
                            disabled={registration.email}
                            autoComplete="off"
                            prefix={
                                <UserOutlined className="site-form-item-icon" />
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={intl.messages['Common.form.password']}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: ' ',
                            },
                            () => ({
                                validator(rule, value) {
                                    if (
                                        value &&
                                        lowerAndUpper(value) &&
                                        symbols(value) &&
                                        numberAndCharacters(value) &&
                                        value.length > 7
                                    ) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        intl.messages[
                                            'Common.invalid_password_format'
                                        ],
                                    )
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            autoComplete="false"
                            onInput={checkPasswordStrength}
                            disabled={registration.password}
                            prefix={
                                <LockOutlined className="site-form-item-icon" />
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={intl.messages['Common.form.confirm_password']}
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: String(
                                    intl.messages['Common.passwordMismatch'],
                                ),
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        intl.messages[
                                            'Common.passwordMismatch'
                                        ],
                                    )
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            disabled={registration.password}
                            autoComplete="off"
                            prefix={
                                <LockOutlined className="site-form-item-icon" />
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        label={intl.messages['Common.name']}
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: String(
                                    intl.messages['Common.form.required_field'],
                                ),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={intl.messages['Common.lastname']}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: String(
                                    intl.messages['Common.form.required_field'],
                                ),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Row gutter={[20, 10]}>
                        <Col span={24}>
                            <Form.Item
                                label={intl.messages['Common.country']}
                                name="country"
                                rules={[
                                    {
                                        required: true,
                                        message: String(
                                            intl.messages[
                                                'Common.form.required_field'
                                            ],
                                        ),
                                    },
                                ]}
                            >
                                <Select>
                                    {Object.values(Environment.countries).map((c) => (
                                        <Select.Option key={c.id} value={c.id}>
                                            {language === 'IT'
                                                ? c.country_it
                                                : c.country}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {Environment.defaultCountry !== 'US' ? (
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <Form.Item
                                    style={{ margin: 0, maxWidth: '100%' }}
                                    label={
                                        intl.messages['Common.promoCodeDesc']
                                    }
                                    name="promotional_code"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                    <p
                        style={{
                            fontSize: 12,
                            lineHeight: '12px',
                        }}
                        dangerouslySetInnerHTML={{
                            __html: String(intl.messages['Common.cguInfo']),
                        }}
                    ></p>
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <Form.Item
                                style={{ marginBottom: 0, marginTop: 20 }}
                            >
                                {registration.loading ? (
                                    <Loader />
                                ) : (
                                    <Button
                                        className="btn-submit"
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {intl.messages['Common.register']}
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    {msg && (
                        <Alert
                            className="align-center"
                            type="error"
                            message={msg}
                        />
                    )}
                </Form>
            </Col>
        </Row>
    )
}

export default Registration
