import getCookiesConsent from './get.cookies.consent'

const initCookiesConsent = (): Promise<any[]> => {
    return new Promise((resolve, reject) => {
        window.__tcfapi(
            'getNonIABVendorConsents',
            2,
            (consent: any, success: boolean) => {
                if (success && consent.nonIabVendorConsents) {
                    resolve(consent.nonIabVendorConsents)
                } else {
                    ;(async () => {
                        const cookiesPreferences = await getCookiesConsent()
                        resolve(cookiesPreferences)
                    })()
                }
            },
        )
    })
}

export default initCookiesConsent
