/**
 *
 * @param {*} msgToDelete
 * @param {*} channel
 */
export default function deleteMessage(msgToDelete, channel) {
    return new Promise((resolve, reject) => {
        channel.deleteMessage(msgToDelete, (response, error) => {
            if (error) {
                reject(error)
            }
            resolve(true)
        })
    })
}
