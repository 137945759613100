import C from 'Lib/redux/constants'
import slots from 'Lib/pixels/adasta/slots'

export default function (store) {
    return (next) => (action) => {
        next(action)

        try {
            /**
             *
             */
            // SHOPPING ROUTE
            if (
                action.type === C.ADASTA_INIT &&
                !window.location.pathname.includes('smartvoucher')
            ) {
                slots()
            }

            /**
             *
             */
            if (
                action.type === C.ROUTE_CHANGE &&
                !action.payload.includes('smartvoucher')
            ) {
                slots()
            }

            /**
             *
             */
            if (action.type === C.CATEGORY_SET) {
                slots(action.payload)
            }

            /**
             *
             */
            if (action.type === C.PAGINATION_CHANGE) {
                slots()
            }

            /**
             *
             */
            if (action.type === C.GIFTCARD_INIT) {
                slots(action.payload.category, action.payload.name)
            }
        } catch (err) {
            console.log(err)
        }
    }
}
