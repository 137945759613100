import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Row, Col, Typography } from 'antd'

const { Title, Text } = Typography

const Maintenance: React.FC = () => {
    const intl = useIntl()

    useEffect(() => {
        document.body.classList.add('maintenance')
    }, [])

    return (
        <Row gutter={[20, 20]} id="maintenance">
            <Col span={24} className="align-center">
                <img src="/assets/images/logo/logo.svg" alt="" />
            </Col>
            <Col span={24} className="align-center">
                <Title>{intl.messages['Maintenance.title']}</Title>
            </Col>
            <Col span={24} className="align-center">
                <Title level={3}>{intl.messages['Maintenance.subtitle']}</Title>
            </Col>
            <Col span={24} className="align-center">
                <img src="/assets/images/maintenance.svg" alt="" />
            </Col>
        </Row>
    )
}

export default Maintenance
