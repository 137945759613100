import Joi from 'joi'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        verify_token: Joi.string(),
        user_email: Joi.string().email({ tlds: { allow: false } }),
    }),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}webapi/accountverification`

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.code === 1035) {
            throw Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
