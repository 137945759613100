import C from 'Lib/redux/constants'
import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import registrationConversion from 'Lib/pixels/google/registration.conversion'
import {
    activation,
    facebookRegister,
    getAccessToken,
    requestLogin,
} from 'Lib/sxc/loginregister'
import registrationPixelNeodata from 'Lib/pixels/neodata/registration.pixel'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import configProfile from '../../signin/functions/configProfile'
import useLoginWithAmazon from '../../signin/hooks/useLoginWithAmazon'
import useLoginWithFacebook from '../../signin/hooks/useLoginWithFacebook'
import useLoginWithApple from '../../signin/hooks/useLoginWithApple'
import { Environment } from '../../../../../config/webpack/environment'

export default function useRegister(): any {
    const history = useHistory()
    const isMounted = useRef(true)
    const registration = useSelector((s: RootState) => s.Registration)
    const setLoginWithAmazon = useLoginWithAmazon()
    const setLoginWithFacebook = useLoginWithFacebook()
    const setLoginWithApple = useLoginWithApple()
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (!registration.preRegistered) return
        ;(async () => {
            try {
                const affiliation = localStorage.getItem('sxc.affiliation_id')
                const opts = {
                    reqObj: {
                        firstname: registration.firstName,
                        lastname: registration.lastName,
                        password: registration.password,
                        id: registration.preId,
                        type: 1,
                        is_all_accepted: 1,
                        is_selling_accepted: 1,
                        is_usage_accepted: 1,
                        is_privacy_accepted: 1,
                        is_marketing_accepted: 1,
                        country: registration.country,
                        walletCurrency: Environment.currency,
                        email: registration.email,
                        ...(!!registration.promotional_code && {
                            promotional_code: registration.promotional_code,
                        }),
                        ...(!!affiliation && { referral_id: affiliation }),
                    },
                }

                switch (registration.registrationType) {
                    case 'amazon':
                        await activation(opts)
                        setLoginWithAmazon('login')
                        break

                    case 'apple':
                        await activation(opts)
                        setLoginWithApple('login')
                        break

                    case 'facebook':
                        await facebookRegister({
                            reqObj: {
                                ...opts.reqObj,
                                facebook_id: registration.facebook_id,
                                facebook_accesstoken:
                                    registration.facebook_accesstoken,
                            },
                        })
                        setLoginWithFacebook('login')
                        break

                    default:
                        await activation(opts)
                        ;(async function classicLogin() {
                            await getAccessToken({
                                reqObj: {
                                    client_id: Environment.client_id,
                                    client_secret: Environment.client_secret,
                                    grant_type: Environment.grant_type,
                                    username: registration.email,
                                    password: registration.password,
                                },
                            })
                            const res = await requestLogin({
                                reqObj: {
                                    username: registration.email,
                                    password: registration.password,
                                },
                            })

                            configProfile(dispatch, history, true)
                        })()
                }

                registrationConversion()
                registrationPixelNeodata(2)
                dispatch({ type: C.AUTH_SET, payload: 'firstoffer' })
            } catch (error) {
                console.log(error)
            } finally {
                dispatch({ type: C.REGISTRATION_LOADING, payload: false })
            }
        })()
    }, [registration.preRegistered])

    return
}
