import base64 from 'base-64'
import C from 'Lib/redux/constants'
import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { useIntl } from 'react-intl'
import { preRegister } from 'Lib/sxc/loginregister'
import { promocodeCheck } from 'Lib/sxc/wallet'

export default function usePreRegister(): any {
    const dispatch = useDispatch()
    const intl = useIntl()
    const isMounted = useRef(true)
    const [msg, setMsg] = useState('')
    const registration = useSelector((s: RootState) => s.Registration)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    const onFinish = async (values: Record<string, string>) => {
        setMsg('')
        dispatch({ type: C.REGISTRATION_LOADING, payload: true })

        /**
         * First try to redeem promo code if inserted
         */
        if (values.promotional_code) {
            try {
                await promocodeCheck({
                    reqObj: {
                        promotional_code: values.promotional_code,
                    },
                })

                dispatch({
                    type: C.REGISTRATION_PROMOCODE,
                    payload: {
                        promotional_code: values.promotional_code,
                    },
                })
            } catch (error) {
                if (isMounted.current) {
                    setMsg(String(intl.messages['Promocode.redeem.error']))
                    dispatch({ type: C.REGISTRATION_LOADING, payload: false })
                }
                return
            }
        }

        /**
         * If i have already a preId so i registered with social exit from this
         */
        if (registration.preId) {
            dispatch({
                type: C.PREREGISTRATION_ENABLED,
                payload: {
                    country: values.country,
                    firstName: values.firstName,
                    lastName: values.lastName,
                },
            })
            return
        }

        /**
         * Otherwise do the pre-registration
         */
        try {
            const password = base64.encode(values.password)
            const opts = {
                reqObj: {
                    email: values.email,
                    password,
                    type: 1,
                    is_all_accepted: 1,
                    is_selling_accepted: 1,
                    is_usage_accepted: 1,
                    is_privacy_accepted: 1,
                    is_marketing_accepted: 1,
                },
            }

            const res = await preRegister(opts)
            dispatch({
                type: C.PREREGISTRATION,
                payload: {
                    email: values.email,
                    password,
                    preId: res.data.id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    country: values.country,
                },
            })
        } catch (error) {
            console.log(error)
            if (isMounted.current) {
                setMsg(
                    error.message === 'USER_ALREADY_EXISTS'
                        ? `${intl.messages['Common.user_exists']}`
                        : `${intl.messages['Common.something_wrong']}`,
                )
                dispatch({ type: C.REGISTRATION_LOADING, payload: false })
            }
        }
    }

    return [msg, onFinish]
}
