/**
 *
 * @param {*} searchStr
 * @param {*} type
 */
export default function searchChat(searchStr, type = 'chatsWithName') {
    return new Promise((resolve, reject) => {
        const params = window.sb.GroupChannel.createMyGroupChannelListQuery()
        params.includeEmpty = true
        if (type === 'chatsWithName') {
            params.channelNameContainsFilter = searchStr
        } else {
            params.nicknameContainsFilter = searchStr
        }
        params.next(function(groupChannels, error) {
            if (error) {
                reject(error)
            }

            // FIx for super channels (it returns n times the same channel)
            const invitedAtArr = []
            const channels = groupChannels.filter(ch => {
                if (invitedAtArr.indexOf(ch.invitedAt) === -1) {
                    invitedAtArr.push(ch.invitedAt)
                    return true
                }
            })
            // end of fix resolve with unique

            // A list of group channels that have 'SendBird' in their names is returned.
            resolve(channels)
        })
    })
}
