import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { updateLanguage } from 'Lib/sxc/language'

export default function useLanguage(): void {
    const language = useSelector((s: RootState) => s.Profile.language)

    useEffect(() => {
        ;(async function () {
            if (window.SESSION_ID) {
                try {
                    const opts = {
                        reqObj: {
                            access_token: window.TOKEN,
                            current_language: language,
                            user_id: `${window.SESSION_ID}`,
                        },
                    }
                    await updateLanguage(opts)
                } catch (err) {
                    console.log(err)
                }
            }

            window._omgDmp = window._omgDmp || []
            window._omgDmp.push([
                'E',
                ['SixthLingua', '', 'u|lingua_' + language],
            ])
        })()
    }, [language])
}
