import getSmUserCredits from './get.sm.user.credits'

export default async (promotion_type = {}) => {
    const obj = {}

    try {
        const res = await getSmUserCredits({ forceApi: true })

        obj.availableCi = parseFloat(
            res.response.result.citizen_income_available
        )
        obj.availablePt = parseFloat(
            res.response.result.points_available * 0.0004
        )
        obj.monthPurchases = parseFloat(
            res.response.result.has_purchased_last_month
        )
        obj.delta = calculateDelta(promotion_type, res.response.result)

        return obj
    } catch (err) {
        throw err
    }
}

function calculateDelta(promotion_type = {}, data = {}) {
    // check customer credits and points
    const usrDeltaCi =
        parseFloat(data.citizen_income_gained) -
        parseFloat(data.citizen_income_available)
    const usrDeltaPt = data.points - data.points_available
    const vcrDeltaCi = promotion_type.deltaCi / 100
    const vcrDeltaPoints = promotion_type.deltaPoints
    if (usrDeltaCi < vcrDeltaCi && usrDeltaPt < vcrDeltaPoints) {
        return true
    }
    return false
}
