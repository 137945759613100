import C from 'Lib/redux/constants'
import { Logout } from 'Lib/sxc/messenger'

export default (store) =>
    (next) =>
    ({ type = '', payload: oldPay }) => {
        let payload = oldPay

        try {
            /** adjust ux */
            if (type === C.ROUTE_CHANGE && payload === '/social/messages') {
                document.body.classList.add('is-chat-route')
                store.dispatch({
                    type: C.CHAT_SET_FULL_SCREEN,
                    payload: {
                        isFullScreen: true,
                    },
                })
            } else if (type === C.ROUTE_CHANGE) {
                const unread = store.getState().Notifications.messages.unread
                if (
                    document.body.classList.contains('is-chat-route') &&
                    unread != 'zzz'
                ) {
                    store.dispatch({
                        type: C.CHAT_SET_ACTIVE_CHANNEL,
                        payload: { channel: {} },
                    })
                }

                document.body.classList.remove('is-chat-route')
                store.dispatch({
                    type: C.CHAT_SET_FULL_SCREEN,
                    payload: {
                        isFullScreen: false,
                    },
                })
            }

            /**
             * Disconnect from Sendbird sdk if chat goes in idle
             */
            if (type === C.CHAT_IDLE_ON) {
                Logout()
            }

            /**
             * If actions is one of these, extend payload with userId
             */

            if (
                [C.CHAT_SET_ACTIVE_CHANNEL, C.CHAT_CHANNEL_EDITED].includes(
                    type,
                )
            ) {
                const userId = store.getState().ChatUser.userId
                payload = { ...payload, userId }
            }

            /**
             * If actions is one of these, extend payload with activeChannel
             */
            if (
                [
                    C.CHAT_MESSAGES_GET,
                    C.CHAT_MESSAGES_UPDATE,
                    C.CHAT_ON_MESSAGE_UPDATED,
                    C.CHAT_ON_MESSAGE_RECEIVED,
                    C.CHAT_MESSAGES_PREPARE,
                    C.CHAT_MESSAGES_SEND,
                    C.CHAT_SET_ACTIVE_CHANNEL,
                ].includes(type)
            ) {
                const activeChannel =
                    store.getState().ChatChannels.activeChannel
                payload = { ...payload, activeChannel }
            }

            /**
             * If actions is one of these, reset idle
             */
            if (
                [
                    C.CHAT_SET_CHANNELS,
                    C.CHAT_SET_ACTIVE_CHANNEL,
                    C.CHAT_MESSAGES_SEND,
                    C.CHAT_MESSAGES_PREPARE,
                    C.CHAT_SET_ATTACHMENT,
                    C.CHAT_SET_QUOTE,
                ].includes(type)
            ) {
                store.dispatch({ type: C.CHAT_IDLE_RESET_TIME })
            }

            /**
             * Send data to angular for these actions
             */
            if ([C.CHAT_IDLED].includes(type)) {
                next({ type, payload })
                store.dispatch({
                    type: C.NOTIFICATIONS_MSG_GET_COUNT,
                    payload,
                })
                return
            }
            if ([C.CHAT_COUNT_UNREAD_NOTIF].includes(type)) {
                next({ type, payload })
                store.dispatch({
                    type: C.NOTIFICATIONS_MSG_GET_COUNT,
                    payload,
                })
                return
            }
            if ([C.CHAT_SET_CHANNELS].includes(type)) {
                next({ type, payload })
                store.dispatch({
                    type: C.NOTIFICATIONS_MSG_GET_CHANNELS,
                    payload: {
                        channels: { ...store.getState().ChatChannels.channels },
                        channelOrder: [
                            ...store.getState().ChatChannels.channelOrder,
                        ],
                    },
                })
                return
            }

            if ([C.CHAT_SET_ACTIVE_CHANNEL].includes(type)) {
                next({ type, payload })
                store.dispatch({
                    type: C.NOTIFICATIONS_MSG_GET_CHANNELS,
                    payload: {
                        channels: { ...store.getState().ChatChannels.channels },
                        channelOrder: [
                            ...store.getState().ChatChannels.channelOrder,
                        ],
                    },
                })
                store.dispatch({
                    type: C.NOTIFICATIONS_MSG_GET_COUNT,
                    payload: store.getState().ChatChannels.allUnreadNotif,
                })
                return
            }

            if ([C.CHAT_ON_MESSAGE_RECEIVED].includes(type)) {
                next({ type, payload })

                const ChannelsState = store.getState().ChatChannels
                let updateNotifCounter = true

                //create a variable for unread notif (channelstate.allunreadnotif is read only)
                let allUnreadNotif = ChannelsState.allUnreadNotif
                //if the chat with new message is active, do not update the global noutification counter
                if (
                    !!ChannelsState.activeChannel.url &&
                    ChannelsState.activeChannel.url === payload.channel.url
                ) {
                    updateNotifCounter = false
                }

                store.dispatch({
                    type: C.NOTIFICATIONS_MSG_GET_CHANNELS,
                    payload: {
                        channels: { ...ChannelsState.channels },
                        channelOrder: [...ChannelsState.channelOrder],
                    },
                })
                if (updateNotifCounter) {
                    store.dispatch({
                        type: C.NOTIFICATIONS_MSG_GET_COUNT,
                        payload: allUnreadNotif,
                    })
                }
                return
            }

            next({ type, payload })
        } catch (err) {
            console.log(err)
            next({ type, payload })
        }
    }
