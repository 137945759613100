export default function UpdateUserInfo(name, profileImg) {
    return new Promise((resolve, reject) => {
        window.sb.updateCurrentUserInfo(name, profileImg, function(
            response,
            error,
        ) {
            if (error) {
                reject(error)
            }
            resolve(response)
        })
    })
}
