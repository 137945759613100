/**
 *
 * @param {*} attachment
 * @param {*} channel
 */
export default function sendChannelFile(attachment, channel) {
    return new Promise((resolve, reject) => {
        const params = new window.sb.FileMessageParams()
        params.file = attachment
        params.fileName = attachment.name
        params.fileSize = attachment.size
        params.thumbnailSizes = [
            { maxWidth: 100, maxHeight: 100 },
            { maxWidth: 200, maxHeight: 200 },
        ]
        params.mimeType = attachment.type
        params.pushNotificationDeliveryOption = 'default' // Either 'default' or 'suppress'

        channel.sendFileMessage(params, (fileMessage, error) => {
            if (error) {
                reject(error)
            }

            window.sb.GroupChannel.getChannel(channel.url, (newCh, err) => {
                if (err) {
                    reject(err)
                }

                resolve({ freshChannel: newCh, message: fileMessage })
            })
        })
    })
}
