import React from 'react'
import Loadable from 'react-loadable'
import {Switch, Route, useRouteMatch} from 'react-router-dom'

const Loading = () => <div style={{minHeight: '60vh'}}></div>

const UserInvoices = Loadable({
    loader: () => import('./invoices/'),
    loading: Loading,
})

const UserPagameno = Loadable({
    loader: () => import('./pagameno/'),
    loading: Loading,
})

const UserRicaricacell = Loadable({
    loader: () => import('./ricaricacell/'),
    loading: Loading,
})

const UserVouchers = Loadable({
    loader: () => import('./vouchers/'),
    loading: Loading,
})

const UserRanking = Loadable({
    loader: () => import('./ranking/'),
    loading: Loading,
})

const UserBadges = Loadable({
    loader: () => import('./badges/'),
    loading: Loading,
})

const UserTicket = Loadable({
    loader: () => import('./ticket/'),
    loading: Loading,
})

const UserSxcCard = Loadable({
    loader: () => import('./sxccard/'),
    loading: Loading,
})

const UserMomosy = Loadable({
    loader: () => import('./momosy/'),
    loading: Loading,
})

const UserWallet = Loadable({
    loader: () => import('./wallet/'),
    loading: Loading,
})

const UserCredits = Loadable({
    loader: () => import('./credits/'),
    loading: Loading,
})

const UserGuadagnalink = Loadable({
    loader: () => import('./guadagnalink/'),
    loading: Loading,
})

const UserFamilyBox = Loadable({
    loader: () => import('./familybox/'),
    loading: Loading,
})

const UserOrange = Loadable({
    loader: () => import('./orange/'),
    loading: Loading,
})

const UserRefund = Loadable({
    loader: () => import('./refundArea/'),
    loading: Loading,
})

const UserDiana = Loadable({
    loader: () => import('./diana/'),
    loading: Loading,
})

function UserRouting() {
    let {path} = useRouteMatch()

    return (
        <Switch>
            {/** PORTFOLIO */}
            <Route path={`${path}/mywallet`}>
                <UserWallet/>
            </Route>
            <Route path={`${path}/mycredits`}>
                <UserCredits/>
            </Route>
            <Route path={`${path}/myinvoices`}>
                <UserInvoices/>
            </Route>
            <Route path={`${path}/pagameno`}>
                <UserPagameno/>
            </Route>
            <Route path={`${path}/mycards`}>
                <UserVouchers/>
            </Route>
            <Route path={`${path}/ricaricacell`}>
                <UserRicaricacell/>
            </Route>
            <Route path={`${path}/guadagnalink`}>
                <UserGuadagnalink/>
            </Route>
            <Route path={[`${path}/sxcticket`, `${path}/ufticket`]}>
                <UserTicket/>
            </Route>
            <Route path={[`${path}/sxccard`, `${path}/ufcard`]}>
                <UserSxcCard/>
            </Route>
            <Route path={[`${path}/orange`, `${path}/premium`]}>
                <UserOrange/>
            </Route>
            <Route path={`${path}/familybox`}>
                <UserFamilyBox/>
            </Route>
            <Route path={`${path}/refundarea`}>
                <UserRefund/>
            </Route>
            <Route path={`${path}/momosy_rules`}>
                <UserMomosy/>
            </Route>
            <Route path={`${path}/ranking`}>
                <UserRanking/>
            </Route>
            <Route path={`${path}/mybadges`}>
                <UserBadges/>
            </Route>
            <Route path={`${path}/diana`}>
                <UserDiana/>
            </Route>
        </Switch>
    )
}

export default UserRouting
