export default function(response) {
    const status =
        response.device_register_status ||
        response.data.device_register_status ||
        response.data.device_status ||
        response.code

    if (status && status !== '503' && status !== 1090) {
        return
    }

    if (response.data.user_id) {
        window.TEMP_ID = response.data.user_id
        window.TEMP_TOKEN = response.data.access_token
        if (window.TEMP_LOGIN !== 'amz') {
            window.TEMP_LOGIN = 'mail'
        }
    }
}
