export default async url => {
    try {
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
        }).then(res => {
            if ([1009, 1019].includes(res.json()?.data?.code)) {
                throw new Error('VOUCHER_EXPIRED')
            }
        })

        return res
    } catch (err) {
        throw err
    }
}
