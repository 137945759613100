import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Loadable from 'react-loadable'

import User from './user/'

const Loading = () => <div style={{ minHeight: '60vh' }}></div>

const Home = Loadable({
    loader: () => import('./home/'),
    loading: Loading,
})

const BuyCancel = Loadable({
    loader: () => import('./buycancel/'),
    loading: Loading,
})

const BuySuccess = Loadable({
    loader: () => import('./buysuccess/'),
    loading: Loading,
})

const BuyWaitPayhub = Loadable({
    loader: () => import('./buywaitpayhub/'),
    loading: Loading,
})

const RevolutMobile = Loadable({
    loader: () => import('./cart/components/Payment/RevolutCardFormMobile'),
    loading: Loading,
})

const Cart = Loadable({
    loader: () => import('./cart/'),
    loading: Loading,
})

const ResetPassword = Loadable({
    loader: () => import('./resetpassword/'),
    loading: Loading,
})

const PhoneConfirm = Loadable({
    loader: () => import('./phoneconfirm/'),
    loading: Loading,
})

const Pagameno = Loadable({
    loader: () => import('./pagameno/'),
    loading: Loading,
})

const Ricaricacell = Loadable({
    loader: () => import('./ricaricacell/'),
    loading: Loading,
})

const Shopping = Loadable({
    loader: () => import('./shopping/'),
    loading: Loading,
})

const Guadagnalink = Loadable({
    loader: () => import('./guadagnalink/list/'),
    loading: Loading,
})

const GuadagnalinkDetail = Loadable({
    loader: () => import('./guadagnalink/detail/'),
    loading: Loading,
})

const VoucherError = Loadable({
    loader: () => import('./vouchererror/'),
    loading: Loading,
})

const Verify = Loadable({
    loader: () => import('./user/verify/'),
    loading: Loading,
})

const Voucher = Loadable({
    loader: () => import('./voucher/'),
    loading: Loading,
})

const Wishlist = Loadable({
    loader: () => import('./wishlist/'),
    loading: Loading,
})

const Device = Loadable({
    loader: () => import('./device/'),
    loading: Loading,
})

// const MessengerCourtesy = Loadable({
//     loader: () => import('./staticpages/messengerCourtesy/'),
//     loading: Loading,
// })

const Timeline = Loadable({
    loader: () => import('./timeline/'),
    loading: Loading,
})

const TimelineMine = Loadable({
    loader: () => import('./timeline/index.my'),
    loading: Loading,
})

const TimelineFriend = Loadable({
    loader: () => import('./timeline/index.friend'),
    loading: Loading,
})

const Friends = Loadable({
    loader: () => import('./friends/'),
    loading: () => null,
})

const Groups = Loadable({
    loader: () => import('./groups/'),
    loading: () => null,
})

const SxcOtp = Loadable({
    loader: () => import('./mobile/SxcOtp'),
    loading: Loading,
})

const Notifications = Loadable({
    loader: () => import('./notifications/'),
    loading: Loading,
})


/**
 * STATIC
 *
 */
const HowItWorks = Loadable({
    loader: () => import('./staticpages/howitworks/'),
    loading: Loading,
})

const NewPrivacy = Loadable({
    loader: () => import('./newprivacy/'),
    loading: Loading,
})

const ConditionsOfUse = Loadable({
    loader: () => import('./staticpages/conditionsOfUse/'),
    loading: Loading,
})

const ConditionsOfSell = Loadable({
    loader: () => import('./staticpages/conditionsOfSell/'),
    loading: Loading,
})

const CookiesPolicy = Loadable({
    loader: () => import('./staticpages/cookiesPolicy/'),
    loading: Loading,
})

const RefundPolicy = Loadable({
    loader: () => import('./staticpages/refundPolicy/'),
    loading: Loading,
})

const PrivacyPolicy = Loadable({
    loader: () => import('./staticpages/privacyPolicy/'),
    loading: Loading,
})

const SxcPay = Loadable({
    loader: () => import('./staticpages/sxcpay/'),
    loading: Loading,
})

const Profile = Loadable({
    loader: () => import('./profile/'),
    loading: Loading,
})

function Routing() {
    return (
        <Switch>
            {/** START ROUTES */}
            <Route path="/" exact>
                <Home />
            </Route>
            <Route path="/citizen_affiliation">
                <Home />
            </Route>
            <Route path="/reset">
                <ResetPassword />
            </Route>
            {/* SHOPING ROUTES */}
            <Route path="/shopping">
                <Shopping />
            </Route>
            <Route path="/smartvoucher">
                <Voucher />
            </Route>
            <Route path="/wishlist">
                <Wishlist />
            </Route>
            <Route path="/pagameno">
                <Pagameno />
            </Route>
            <Route path="/ricaricacell">
                <Ricaricacell />
            </Route>
            <Route path="/bestshopping" exact>
                <Guadagnalink />
            </Route>
            <Route path="/bestshopping/voucher" exact>
                <GuadagnalinkDetail />
            </Route>
            <Route path="/cart">
                <Cart />
            </Route>
            <Route path="/processing_payment">
                <BuyWaitPayhub />
            </Route>
            <Route path="/revolutmobile">
                <RevolutMobile />
            </Route>
            <Route path="/buyspecialvoucher_cancel">
                <BuyCancel />
            </Route>
            <Route path="/buyspecialvoucher_success">
                <BuySuccess />
            </Route>
            {/** USER */}
            <Route path="/user">
                <User />
            </Route>
            {/** SOCIAL */}
            {/*<Route path={`/social/messages`}>*/}
            {/*    <MessengerCourtesy />*/}
            {/*</Route>*/}
            <Route path="/social/timeline" exact>
                <Timeline />
            </Route>
            <Route path="/social/timeline/mine">
                <TimelineMine />
            </Route>
            <Route path="/social/timeline/friend/:friendId">
                <TimelineFriend />
            </Route>
            <Route path="/social/friends">
                <Friends />
            </Route>
            <Route path={`/social/groups`} exact>
                <Groups />
            </Route>
            <Route path={`/social/groups/:groupId`}>
                <Groups />
            </Route>
            {/** UTILITY PAGES */}
            <Route path="/verify">
                <Verify />
            </Route>
            <Route path="/device">
                <Device />
            </Route>
            <Route path="/sxcotp">
                <SxcOtp />
            </Route>
            <Route path="/new-privacy">
                <NewPrivacy />
            </Route>
            <Route path="/confirm_phone">
                <PhoneConfirm />
            </Route>
            {/** STATIC PAGES */}
            <Route path="/downloadvoucher/500">
                <VoucherError />
            </Route>
            <Route path="/cookies">
                <CookiesPolicy />
            </Route>
            <Route path="/conditions-of-use">
                <ConditionsOfUse />
            </Route>
            <Route path="/conditions-of-sale">
                <ConditionsOfSell />
            </Route>
            <Route path="/refund_policy">
                <RefundPolicy />
            </Route>
            <Route path="/privacy">
                <PrivacyPolicy />
            </Route>
            <Route path="/howitworks">
                <HowItWorks />
            </Route>
            <Route path="/sxc-pay">
                <SxcPay />
            </Route>
            <Route path="/notifications">
                <Notifications />
            </Route>
            <Route path="/profile">
                <Profile />
            </Route>
        </Switch>
    )
}

export default Routing
