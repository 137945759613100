import { useLayoutEffect } from 'react'
import { Environment } from '../../../../../config/webpack/environment'

export default function useScripts(): any {
    useLayoutEffect(() => {
        (function () {
            // Scoping function to avoid globals
            const isAmzSandbox = Environment.amzSandbox ? '/sandbox' : ''

            const script = <HTMLScriptElement>document.createElement('script')

            const src =
                Environment.defaultCountry.toLowerCase() === 'us'
                    ? `https://static-na.payments-amazon.com/OffAmazonPayments/us${isAmzSandbox}/js/Widgets.js`
                    : `https://static-eu.payments-amazon.com/OffAmazonPayments/eur${isAmzSandbox}/lpa/js/Widgets.js`
            // document.write(`<script async src="${src}"><\/script>`)

            script.src = src
            script.type = 'text/javascript'
            document.getElementsByTagName('head')[0].appendChild(script)
        })()
    }, [])

    useLayoutEffect(() => {
        (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) return
            const js = <HTMLScriptElement>d.createElement(s)
            js.id = id
            js.src =
                'https://connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v3.2&appId=' +
                Environment.fbId
            fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'facebook-jssdk')
    }, [])

    useLayoutEffect(() => {
        const scriptTag = document.createElement('script')
        scriptTag.src =
            'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
        document.body.appendChild(scriptTag)
    }, [])
}
