import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { withAccessTokenOkAsync } from 'Lib/sxc/device/'
import { Environment } from "../../../../config/webpack/environment";

/**
 *
 *
 *
 *
 * Apple login
 * @param {*} opts
 */

const schema1 = Joi.object({
    social_auth_token: Joi.string(),
    is_all_accepted: true,
    is_selling_accepted: true,
    is_usage_accepted: true,
    is_privacy_accepted: true,
    is_marketing_accepted: true,
    from_mobile_device: false,
    provider: 'apple',
}).options({ presence: 'required' })

export const requestLoginApple = async (opts = {}) => {
    const { error } = schema1.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}webapi/users/social-login`

    /**
     * get fingerprint from storage or create new one
     */
    const hash = await getFingerAsync()
    opts.device_registration_hash = hash

    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(opts),
    })
        .then(res => res.json())
        .then(res => {
            if (res.code !== 200) {
                const error = new Error('REQUEST_FAILED')
                error.code = 'RequestFailed'
                throw error
            }

            // PREREGISTRATION (NEW USER)
            if (res.data.pre_registration_id) {
                window.TEMP_ID = res.data.pre_registration_id
                window.TEMP_EMAIL = res.data.email
                return 'preregistration'
            }

            // PRELOGIN
            window.TEMP_TOKEN = res.data.access_token
            window.TEMP_ID = res.data.user_id
            withAccessTokenOkAsync(res)
            return 'prelogin'
        })
        .catch(err => {
            return 'error'
        })

    return res
}

/**
 *
 *
 *
 *
 * Apple afterlogin
 * @param {*} opts
 */

const schema2 = Joi.object({
    social_auth_token: Joi.string(),
    is_all_accepted: true,
    is_selling_accepted: true,
    is_usage_accepted: true,
    is_privacy_accepted: true,
    is_marketing_accepted: true,
    from_mobile_device: false,
    provider: 'apple',
    device_registration_hash: Joi.string(),
}).options({ presence: 'required' })

export const requestAfterLoginApple = async (opts = {}) => {
    /**
     * get fingerprint from storage or create new one
     */
    const hash = await getFingerAsync()
    opts.device_registration_hash = hash

    const { error } = schema2.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}webapi/users/${window.TEMP_ID}/login/user-data?access_token=${window.TEMP_TOKEN}&device_hash=${hash}`

    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(res => res.json())
        .then(res => {
            window.TOKEN = res.data.access_token
            window.SESSION_ID = res.data.id
            window.EMAIL = res.data.email

            /**
             * save it on localStorage
             */
            localStorage.setItem('access_token', res.data.access_token)
            localStorage.setItem('session_id', res.data.id)

            return res
        })

    return res
}
