import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Lib/redux/reducers'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Dropdown, Menu, Typography, Row, Col, Badge } from 'antd'

const { Text } = Typography

const Community: React.FC = () => {
    const history = useHistory()
    const userId = useSelector((s: RootState) => s.Profile.user_id)
    const intl = useIntl()
    const msgNotif = useSelector(
        (s: RootState) => s.Notifications.messages.unread,
    )

    const socialNotif = useSelector((s: RootState) =>
        s.Notifications.social.filter((v: any) => !v.is_read),
    )

    const groupNotif = useSelector((s: RootState) =>
        s.Notifications.groups.filter((v: any) => !v.is_read),
    )

    const sum = msgNotif + groupNotif.length + socialNotif.length

    return (
        <Dropdown
            placement="bottomCenter"
            overlay={
                !userId ? (
                    <Text>{intl.messages['Header.log_to_stay']}</Text>
                ) : (
                    <Menu className="community-drop">
                        {/*<Menu.Item*/}
                        {/*    key="1"*/}
                        {/*    onClick={() => history.push('/social/messages')}*/}
                        {/*>*/}
                        {/*    <Row>*/}
                        {/*        <Col span={22}>*/}
                        {/*            {intl.messages['Common.messages']}*/}
                        {/*        </Col>*/}
                        {/*        <Col span={2}>*/}
                        {/*            <Badge*/}
                        {/*                count={msgNotif}*/}
                        {/*                color="red"*/}
                        {/*                showZero={false}*/}
                        {/*            >*/}
                        {/*                <br />*/}
                        {/*            </Badge>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item
                            key="2"
                            onClick={() => history.push('/social/timeline')}
                        >
                            <Row>
                                <Col span={22}>
                                    <Text>
                                        {intl.messages['Timeline.title']}
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <Badge
                                        count={socialNotif.length}
                                        color="red"
                                        showZero={false}
                                    >
                                        <br />
                                    </Badge>
                                </Col>
                            </Row>
                        </Menu.Item>
                        <Menu.Item
                            key="3"
                            onClick={() =>
                                history.push('/social/timeline/mine')
                            }
                        >
                            <Row>
                                <Col span={22}>
                                    <Text>
                                        {intl.messages['Timeline.mywall']}
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <Badge
                                        count={socialNotif.length}
                                        color="red"
                                        showZero={false}
                                    >
                                        <br />
                                    </Badge>
                                </Col>
                            </Row>
                        </Menu.Item>
                        <Menu.Item
                            key="4"
                            onClick={() => history.push('/social/groups')}
                        >
                            <Row>
                                <Col span={22}>
                                    {intl.messages['Common.groups']}
                                </Col>
                                <Col span={2}>
                                    <Badge
                                        count={groupNotif.length}
                                        color="red"
                                        showZero={false}
                                    >
                                        <br />
                                    </Badge>
                                </Col>
                            </Row>
                        </Menu.Item>
                        <Menu.Item
                            key="5"
                            onClick={() => history.push('/social/friends')}
                        >
                            <Row>
                                <Col span={22}>
                                    <Text>
                                        {
                                            intl.messages[
                                                'Timeline.friendWall.friends'
                                            ]
                                        }
                                    </Text>
                                </Col>
                            </Row>
                        </Menu.Item>
                    </Menu>
                )
            }
            trigger={['click']}
            arrow
        >
            <span>
                <Text>{intl.messages['Common.community']}</Text>
                <Badge count={sum} showZero={false} dot>
                    <br />
                </Badge>
            </span>
        </Dropdown>
    )
}

export default Community
