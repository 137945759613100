export default function inviteUsersAsMember(channel, userIds) {
    return new Promise((resolve, reject) => {
        channel.inviteWithUserIds(userIds, function(response, error) {
            if (error) {
                reject(error)
            }
            resolve(response)
        })
    })
}
