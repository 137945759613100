import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import debugApi from 'Lib/sxc/debug.api'
import { Environment } from '../../../../config/webpack/environment'

const schema = Joi.object({
    reqObj: Joi.object({
        cart: Joi.array().items(
            Joi.object({
                card_id: Joi.string(),
                currency: Joi.string(),
                offer_id: Joi.number(),
                use_credit: Joi.number(),
                quantity: Joi.number(),
                premiumData: Joi.array(),
            }),
        ),
    }),
}).options({ presence: 'required' })

export default async (itemId, opts) => {
    const { error, errors, value } = schema.validate(opts)
    debugApi(error, errors, value)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.payDomain}api/cart/item/${itemId}?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()

        value.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        }).then((res) => res.json())

        if (res.code === 1090) {
            throw new Error('DEVICE_TO_VALIDATE')
        }

        if (res.code === 1155) {
            throw new Error('PURCHASE_LIMIT_REACHED')
        }

        if (Number(res.code) !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
