import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    pin_code: Joi.string(),
    user_id: Joi.number().integer(),
}).options({ presence: 'required' })

export default async opts => {
    const { error } = schema.validate(opts)
    if (error) throw new Error('INVALID_OPTS')

    const token = window.TOKEN || window.TEMP_TOKEN
    const user_id = window.SESSION_ID || window.TEMP_ID
    const url = `${Environment.smsDomain}validatesmspincode?access_token=${token}&session_id=${user_id}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.response.data.error === 'NOT VALID PIN CODE') {
            const err = new Error('INVALID_OTP')
            err.data = res.response.data
            throw err
        }

        if (res.response.data.error === 701) {
            throw new Error('NO_MORE_CHANCE')
        }

        if (res.response.data.error === 702) {
            throw new Error('INVALID_ACCESS_TOKEN')
        }

        return res.response.data
    } catch (err) {
        throw err
    }
}
