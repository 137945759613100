export default function amazonAuthorize() {
    return new Promise((resolve, reject) => {
        const options = {
            scope:
                'profile payments:widget payments:shipping_address payments:billing_address',
            popup: true,
        }

        try {
            amazon.Login.authorize(options, result => resolve(result))
        } catch (err) {
            reject(err)
        }
    })
}
