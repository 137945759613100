import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import debugApi from 'Lib/sxc/debug.api'
import { Environment } from "../../../../config/webpack/environment";

const schema = Joi.object({
    reqObj: Joi.object({
        invoiceAddress: Joi.object({
            address: Joi.string(),
            city: Joi.string(),
            company_name: Joi.string().optional(),
            country: Joi.string(),
            first_name: Joi.string().optional(),
            fiscal_code: Joi.string(),
            is_company: Joi.number().optional(),
            last_name: Joi.string().optional(),
            pec: Joi.string().allow(null, ''),
            sdi_code: Joi.string().allow(null, ''),
            shipping_nickname: Joi.string(),
            state: Joi.string(),
            zipcode: Joi.string(),
            vat_number: Joi.string().optional(),
        })
            .xor('first_name', 'company_name')
            .xor('last_name', 'vat_number')
            .with('company_name', 'is_company'),
    }),
}).options({ presence: 'required' })

export default async (opts) => {
    const { error, errors, value } = schema.validate(opts)
    debugApi(error, errors, value)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.payDomain}api/addinvoiceaddress?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()

        value.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        }).then((res) => res.json())

        if (res.code === 1048) {
            throw new Error('INVALID_CF')
        }

        if (res.data?.status === 'SDI_CODE_NOT_VALID') {
            throw new Error('SDI_CODE_NOT_VALID')
        }

        if (res.data?.status === 'PEC_NOT_VALID') {
            throw new Error('PEC_NOT_VALID')
        }

        if (res.code === 1049) {
            throw new Error('MISSING_DATA')
        }

        if (res.code === 1090) {
            throw new Error('DEVICE_TO_VALIDATE')
        }

        if (res.code !== 101) {
            throw new Error('REQUEST_FAILED')
        }

        return res
    } catch (err) {
        throw err
    }
}
