import C from 'Lib/redux/constants'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getBasicProfile, logout } from 'Lib/sxc/user'

export default function useGetProfile(
    accessToken: string,
    sessionId: number,
): void {
    const dispatch = useDispatch()

    useEffect(() => {
        /** if access token is not stored get out */
        if (!accessToken || !sessionId) {
            dispatch({ type: C.USER_NOT_LOGGED })
            return
        }

        window.SESSION_ID = sessionId
        window.TOKEN = accessToken
        ;(async function () {
            try {
                /**
                 * Force the multiprofile to call api when user arrive
                 */
                const data1 = await getBasicProfile({ forceApi: true })
                dispatch({
                    type: C.GET_PROFILE,
                    payload: {
                        ...data1.data,
                        accessToken: window.TOKEN,
                    },
                })
                /* */

                const PIXEL_COUNTRY = data1.data.country
                window._omgDmp = window._omgDmp || []
                window._omgDmp.push([
                    'E',
                    ['SixthAccedi', '', 'u|accedi_' + PIXEL_COUNTRY],
                ])
            } catch (err) {
                console.log(err)
                const opts = {
                    reqObj: {
                        access_token: window.TOKEN,
                        session_id: window.SESSION_ID,
                    },
                }

                await logout(opts)
                window.TOKEN = ''
                window.SESSION_ID = 0
                Object.keys(Cookies.get()).forEach(function (cookieName) {
                    Cookies.remove(cookieName)
                })

                localStorage.clear()
                window.location.href = '/'
            }
        })()
    }, [])
}
