export default function() {
    return next => async ({ type = '', payload = {} }) => {
        try {
            if (type.includes('UPDATE_PROFILE')) {
            }
        } catch (err) {
            console.log(err)
        } finally {
            next({ type, payload })
        }
    }
}
