/**
 *
 * @param {*} channel
 */
export default function clearHistory(channel) {
    return new Promise((resolve, reject) => {
        channel.resetMyHistory((response, error) => {
            if (error) {
                reject(error)
            }

            resolve(response)
        })
    })
}
