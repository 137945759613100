import C from 'Lib/redux/constants'
import produce from 'immer'
import { RicaricaState } from 'Lib/types/ricaricacell'

const initialState: RicaricaState = {
    card: { cardId: '', premium: [] },
    offerAmount: { withCi: {}, withOutCi: {}, creditSelection: 'withOutCi' },
    form: { operator_id: 0, operator_seller_id: 0 },
    sxcCardInUse: 0,
    sxcTicketInUse: 0,
}

const BulletinReducer = produce((draft = initialState, { type, payload }) => {
    switch (type) {
        case C.RICARICACELL_ADD_PREMIUM:
            draft.card.premium.push(payload)
            break
        case C.RICARICACELL_REMOVE_PREMIUM:
            draft.card.premium = [
                ...draft.card.premium.filter(
                    (val: any) => val.cardId !== payload,
                ),
            ]
            break
        case C.RICARICACELL_SET_CREDITSELECTION:
            draft.offerAmount.creditSelection = payload
            break
        case C.RICARICACELL_SET_OFFERAMOUNT:
            draft.offerAmount.withCi = payload.withCi
            draft.offerAmount.withOutCi = payload.withOutCi
            break
        case C.RICARICACELL_SET_OPERATOR:
            draft.form.operatorId = payload.id
            draft.form.operatorSellerId = payload.seller_id
            draft.form.operatorName = payload.punctual_search
            break
        case C.RICARICACELL_SET_CARD:
            draft.card = { ...draft.card, ...payload }
            break
        case C.RICARICACELL_SET_FORM:
            draft.form = { ...draft.form, ...payload }
            break
        case C.RICARICACELL_TOGGLE_SXCCARD:
            draft.sxcCardInUse = payload
            break
        case C.RICARICACELL_TOGGLE_SXCTICKET:
            draft.sxcTicketInUse = payload
            break
        case C.RICARICACELL_RESET:
        case C.CHECKOUT_COMPLETED:
        case C.LOGOUT:
            return { ...initialState }
        default:
            return draft
    }
})

export default BulletinReducer
