import { Environment } from "../../../../config/webpack/environment";

export default async () => {
    const url = `${Environment.devicesDomain}device_activate_number?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    const pathOptions = window.location.pathname
        .split('/')
        .filter((val) => val !== '' && val !== 'confirm_phone')

    if (pathOptions.length !== 2) {
        throw new Error('no path options for activate number')
    }

    const opts = {
        mail_hash: pathOptions[1],
        user_id: ~~pathOptions[0],
    }

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        return res.data.result === '601' || res.data.result === '607'
    } catch (err) {
        throw err
    }
}
