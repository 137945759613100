import { createTypes } from 'reduxsauce'

export default createTypes(`
    ADASTA_INIT
    AUTH_SET

    CALCULATE_POINTS

    CART_AMAZON_PAYABLE
    CART_CHANGE_QUANTITY
    CART_CLEAR
    CART_GET
    CART_GET_PAID
    CART_RESET_CI
    CART_REMOVE_VOUCHER
    CART_REMOVE_PREMIUM
    CART_TOGGLE_CARD
    CART_TOGGLE_TICKET
    CART_UPDATE

    CATEGORY_SET
    CHANGE_LANGUAGE

    CHECKOUT_CLICK_PROCEED
    CHECKOUT_COMPLETED
    CHECKOUT_SAVE_ADDRESS
    CHECKOUT_SET_BILLING
    CHECKOUT_SET_CREDIT_CARD
    CHECKOUT_SET_GATEWAY
    CHECKOUT_SET_PARAMS
    CHECKOUT_SET_REVOLUT_ID
    CHECKOUT_SET_SHIPPING
    CHECKOUT_TOGGLE_BILLING
    CHECKOUT_TOGGLE_TANDC

    DATALAYER_INIT


    DIANA_CHANGE_PWD
    DIANA_CHANGE_PWD_SAVE
    DIANA_CHANGE_PWD_ERR
    DIANA_CHECK_FILE
    DIANA_CHECK_FILE_SAVE
    DIANA_CHECK_FILE_ERR
    DIANA_DO_CHECK
    DIANA_DO_CHECK_SAVE
    DIANA_GET_DEVICES
    DIANA_GET_DEVICES_SAVE
    DIANA_GET_DEVICES_ERR
    DIANA_GO_BACK
    DIANA_REMOVE_DEVICE
    DIANA_REMOVE_DEVICE_SAVE
    DIANA_REMOVE_DEVICE_ERR
    DIANA_REQ_FILE
    DIANA_REQ_FILE_SAVE
    DIANA_REQ_FILE_ERR
    DIANA_START
    DIANA_START_SAVE
    DIANA_START_ERR
    DIANA_GET_SCORE
    DIANA_GET_SCORE_SUCCESS
    DIANA_GET_SCORE_ERR_PRE
    DIANA_GET_SCORE_ERR
    DIANA_STEP_FURTHER
    DIANA_STEP_SAVE
    DIANA_STEP_SAVE_SAVE
    DIANA_STEP_SAVE_ERR
    DIANA_END
    DIANA_END_SAVE
    DIANA_END_ERR

    GET_PROFILE

    GIFTCARD_CI_MANDATORY
    GIFTCARD_CHOOSE_PAN
    GIFTCARD_CREDIT_SELECTION
    GIFTCARD_DELIVERY_TIME
    GIFTCARD_INIT
    GIFTCARD_OFFER_AMOUNT
    GIFTCARD_PRICE
    GIFTCARD_QTY_SELECTED
    GIFTCARD_SELECT_OPTION
    GIFTCARD_SELECT_QTY
    GIFTCARD_SHIPPING_COST
    GIFTCARD_TICKET_CASHBACK
    GIFTCARD_TICKET_MANDATORY
    GIFTCARD_TOGGLE_ACTIVATION
    GIFTCARD_TOGGLE_CREDIT
    GIFTCARD_TOGGLE_PREMIUM
    GIFTCARD_WELCOME_CREDIT

    GROUPS_ADD_COMMENT
    GROUPS_ADD_POST
    GROUPS_DELETE_COMMENT
    GROUPS_DELETE_POST
    GROUPS_GET_COMMENTS
    GROUPS_GET_POSTS
    GROUPS_LIKE_COMMENT
    GROUPS_LIKE_POST
    GROUPS_SELECT
    GROUPS_UPDATE_POST
    GROUPS_WRITE_POST
    GROUPS_WRITE_ADD_IMAGE
    GROUPS_WRITE_REMOVE_IMAGE
    GROUPS_WRITE_LINK
    GROUPS_WRITE_TAG

    GUADAGNALINK_INIT
    GUADAGNALINK_SEND

    LOADER_END
    LOGIN
    LOGOUT

    NOTIFY_PULL
    NOTIFICATIONS_ACTION_DONE
    NOTIFICATIONS_DELETE
    NOTIFICATIONS_GET
    NOTIFICATIONS_MARK_ALL_READ
    NOTIFICATIONS_MARK_READ
    NOTIFICATIONS_MSG_GET_COUNT
    NOTIFICATIONS_MSG_GET_CHANNELS

    ORANGE_STEP
    ORANGE_INITIAL
    ORANGE_VOUCHERS_DETAILS
    ORANGE_SELECT_PLAN
    ORANGE_SET_CREDIT_CARD
    ORANGE_SET_GATEWAY
    ORANGE_SET_USE_CARD
    ORANGE_SET_USE_TICKET
    SET_ORANGE_PROMOCODE
    ORANGE_CHECK_STATUS

    PAGINATION_CHANGE

    PAGAMENO_ADD_PREMIUM
    PAGAMENO_REMOVE_PREMIUM
    PAGAMENO_RESET
    PAGAMENO_SEND
    PAGAMENO_CHOOSE_PRODUCT
    PAGAMENO_SET_CREDITSELECTION
    PAGAMENO_SET_FORM
    PAGAMENO_SET_CARD
    PAGAMENO_SET_OFFERAMOUNT
    PAGAMENO_TOGGLE_SXCCARD
    PAGAMENO_TOGGLE_SXCTICKET


    PROFILE_FISCALCODE

    PREREGISTRATION
    PREREGISTRATION_AMZ
    PREREGISTRATION_FB
    PREREGISTRATION_APPLE
    PREREGISTRATION_ENABLED
    REGISTRATION_LOADING
    REGISTRATION_SKIP
    REGISTRATION_PROMOCODE

    RECHARGE_SEND

    RICARICACELL_ADD_PREMIUM
    RICARICACELL_REMOVE_PREMIUM
    RICARICACELL_RESET
    RICARICACELL_SET_CARD
    RICARICACELL_SET_CREDITSELECTION
    RICARICACELL_SET_FORM
    RICARICACELL_SET_OFFERAMOUNT
    RICARICACELL_SET_OPERATOR
    RICARICACELL_TOGGLE_SXCCARD
    RICARICACELL_TOGGLE_SXCTICKET

    ROUTE_CHANGE


    SHOPPING_CATEGORY
    SHOPPING_COUNTRY
    SHOPPING_FILTER
    SHOPPING_SEARCH
    BESTSHOPPING_FILTER
    BESTSHOPPING_SEARCH

    SIGNIN

    TIMELINE_ADD_COMMENT
    TIMELINE_ADD_POST
    TIMELINE_DELETE_COMMENT
    TIMELINE_DELETE_POST
    TIMELINE_GET_COMMENTS
    TIMELINE_GET_CRAWL
    TIMELINE_GET_FRIEND_WALL
    TIMELINE_GET_FRIEND_BADGES
    TIMELINE_GET_FRIEND_FRIENDS
    TIMELINE_GET_FRIEND_FRIENDSHIP
    TIMELINE_GET_POSTS
    TIMELINE_LIKE_COMMENT
    TIMELINE_LIKE_POST
    TIMELINE_LOAD_MORE
    TIMELINE_RESET_CRAWL
    TIMELINE_UPDATE_POST
    TIMELINE_UPDATE_COVER
    TIMELINE_WRITE_ADD_IMAGE
    TIMELINE_WRITE_REMOVE_IMAGE
    TIMELINE_WRITE_POST
    TIMELINE_WRITE_TAG

    UPDATE_PRIVACY
    UPDATE_SINGLE_PRIVACY
    UPDATE_PROFILE
    UPDATE_MARKETING

    USER_CREDITS
    USER_NOT_LOGGED

    UTILITIES_SEND

    WALLET_GET
    WALLET_GET_CARD_BALANCE
    WALLET_GET_CARD_TRANSACTIONS
    WALLET_GET_TICKET_BALANCE
    WALLET_GET_TICKET_TRANSACTIONS
    WALLET_PENDINGCI

    WISHLIST_GET
    WISHLIST_ADD
    WISHLIST_REMOVE


    CHAT_IDLE_INCREMENT
    CHAT_IDLE_RESET_TIME
    CHAT_IDLE_OFF
    CHAT_IDLE_ON
    CHAT_USER_INIT
    CHAT_SET_ATTACHMENT
    CHAT_SET_ATTACHMENT_ERROR
    CHAT_SET_QUOTE
    CHAT_SET_ACTIVE_CHANNEL
    CHAT_CLEAR_ACTIVE_CHANNEL
    CHAT_SET_NEW_CHANNEL
    CHAT_SET_CHANNELS
    CHAT_LEAVE_CHANNEL
    CHAT_ERASE_CHANNEL
    CHAT_CHANNEL_EDITED
    CHAT_MARK_AS_READ
    CHAT_SET_MEMBERS
    CHAT_COUNT_UNREAD_NOTIF
    CHAT_ADD_USER_TO_CHANNEL
    CHAT_BAN_USER_FROM_CHANNEL
    CHAT_BLOCK_USER
    CHAT_UNBLOCK_USER
    CHAT_ADDED_AS_ADMIN
    CHAT_REMOVED_AS_ADMIN
    CHAT_MESSAGES_GET
    CHAT_MESSAGES_PREPARE
    CHAT_MESSAGES_SEND
    CHAT_MESSAGES_DELETE
    CHAT_MESSAGES_UPDATE
    CHAT_CLEAR_HISTORY
    CHAT_ON_MESSAGE_RECEIVED
    CHAT_ON_MESSAGE_UPDATED
    CHAT_ON_MESSAGE_DELETED
    CHAT_ON_TYPING
    CHAT_ON_REACTION
    CHAT_IDLED
    CHAT_USER_NOT_ORANGE
    CHAT_TOGGLE_SEARCH
    CHAT_PREPARE_TO_UNBLOCK
    CHAT_SIZE_INIT
    CHAT_SET_FULL_SCREEN
    CHAT_SHARE_VOUCHER
    CHAT_CLEAR_SHARE_VOUCHER
`)
