export default function getPreviousMessages(
    prevMessageListQuery,
    msgQueryLimit,
) {
    try {
        prevMessageListQuery.limit = msgQueryLimit // 100 is the maximum
        prevMessageListQuery.includeReaction = true
        return () =>
            new Promise((resolve, reject) => {
                prevMessageListQuery.load((messages, error) => {
                    if (error) {
                        reject(error)
                    }

                    resolve(messages)
                })
            })
    } catch (err) {
        return () => new Promise(res => res([]))
    }
}
