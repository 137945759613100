/**
 *
 * @param {*} channel
 * @param {*} user
 */
export default function banUser(channel, user) {
    return new Promise((resolve, reject) => {
        channel.banUser(user, 1, 'banned', function(response, error) {
            if (error) {
                reject(error)
            }
            resolve(channel)
            // TODO: Custom implementation for what should be done after banning.
        })
    })
}
