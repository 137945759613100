import C from 'Lib/redux/constants'
import {
    requestAfterLoginFb,
    requestLoginFb,
    generatePwd,
    preRegister,
} from 'Lib/sxc/loginregister'
import configProfile from '../functions/configProfile'
import { useDispatch } from 'react-redux'
import { useLayoutEffect } from 'react'
import { useState, useRef, useEffect } from 'react'
import { checkFBLoginState } from 'Lib/sxc/facebook/facebooksdk'
import { useHistory } from 'react-router-dom'
import { Environment } from "../../../../../config/webpack/environment";
import {useLocation} from 'react-use';

export default function useLoginWithFacebook(): any {
    const history = useHistory()
    const dispatch = useDispatch()
    const [type, setType] = useState('')
    const isMounted = useRef(true)
    const location = useLocation()
    const isDeviceReset = location.state?.state?.deviceReset

    useLayoutEffect(() => {
        ;(function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) return
            const js = <HTMLScriptElement>d.createElement(s)
            js.id = id
            js.src =
                'https://connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v3.2&appId=' +
                Environment.fbId
            fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'facebook-jssdk')
    }, [])

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (!type) {
            return
        }
        /**
         *
         * @param {Map} cguCheck //Cgucheck helps to understand if user is in /signin or /register routes
         */
        ;(async () => {
            try {
                const { fbId, email, fname, lname, accessToken } =
                    await checkFBLoginState()
                const opts = {
                    reqObj: {
                        facebook_id: fbId,
                        email,
                        firstname: fname,
                        lastname: lname,
                    },
                }

                const res = await requestLoginFb(opts)

                if (res.code === '99') {
                    /**
                     * user not exists on sxc and user HAS NOT flagged checkboxes
                     */
                    /**
                     * user not exists on sxc and user HAS flagged checkboxes
                     */
                    const password = generatePwd()

                    // Call preregister (it is needed for retrievieng preregistration id)
                    const res2 = await preRegister({
                        reqObj: {
                            email: res.data.email,
                            password,
                            type: 1,
                            is_all_accepted: 1,
                            is_selling_accepted: 1,
                            is_usage_accepted: 1,
                            is_privacy_accepted: 1,
                            is_marketing_accepted: 1,
                        },
                    })

                    dispatch({
                        type: C.PREREGISTRATION_FB,
                        payload: {
                            firstName: res.data.firstname,
                            lastName: res.data.lastname,
                            email: res.data.email,
                            preId: res2.data.id,
                            password,
                            facebook_accesstoken: accessToken,
                            facebook_id: res.data.facebook_id,
                        },
                    })
                    dispatch({
                        type: C.AUTH_SET,
                        payload: 'signup',
                    })
                } else if (res.code === '101') {
                    await requestAfterLoginFb(opts)

                    dispatch({
                        type: C.LOGIN,
                        payload: {
                            user_email: window.EMAIL,
                        },
                    })

                    dispatch({
                        type: C.AUTH_SET,
                        payload: null,
                    })

                    await configProfile(dispatch, history, false, isDeviceReset ? '/device' : null)
                    history.push('/shopping')
                }
            } catch (error) {
                console.log(error.message)
            }
        })()
    }, [type])

    return setType
}
