import useGetProfile from './useGetProfile'
import useGetOrangeStatus from './useGetOrangeStatus'
import useGetCredits from './useGetCredits'
import useGetCart from './useGetCart'
import useGetWishlist from './useGetWishlist'
import useGetPendingCi from './useGetPendingCi'
import useInitCookies from './useInitCookies'
import useGetNotifications from './useGetNotifications'

export default function useStartAll(): void {
    const accessToken = localStorage.getItem('access_token')
    const sessionId = localStorage.getItem('session_id')

    useGetProfile(accessToken, ~~sessionId)
    useGetOrangeStatus(accessToken)
    useGetCart(accessToken)
    useGetWishlist(accessToken)
    useGetCredits(accessToken)
    useGetPendingCi(accessToken)
    useGetNotifications(accessToken)
    useInitCookies()
}
