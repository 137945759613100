import C from 'Lib/redux/constants'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getRedisCart, deleteCart } from 'Lib/sxc/cart'

export default function useGetCart(accessToken: string): void {
    const dispatch = useDispatch()

    useEffect(() => {
        if (!accessToken) {
            return
        }

        (async function () {
            try {
                const cartRes = await getRedisCart()
                dispatch({
                    type: C.CART_GET,
                    payload: cartRes,
                })
            } catch (err) {
                console.log(err)
                if (err.message === 'DELETE_CURRENT_CART') {
                    ;(async function () {
                        await deleteCart()
                    })()
                }
            }
        })()
    }, [])
}
