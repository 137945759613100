import { useEffect, useState } from 'react'
import { Environment } from '../../../../config/webpack/environment'

export default function useCountryCode(): [string] {
    const [countryCode, setCountryCode] = useState('')

    useEffect(() => {
        const opts = {
            reqObj: {
                country: Environment.defaultCountry,
            },
        }

        fetch(`${Environment.apiDomain}webapi/topsellingvouchers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then((res) => {
            setCountryCode(res.headers.get('X-Country'))
        })
        // END OF THE HACK
    }, [])

    return [countryCode]
}
