import Joi from 'joi'
import { getFingerAsync } from 'Lib/sxc/fingerprint'
import { Environment } from "../../../../config/webpack/environment";

/**
 *
 *
 * FB login
 * @param {*} opts
 */
const schema1 = Joi.object({
    reqObj: Joi.object({
        facebook_id: Joi.string(),
        email: Joi.string().email({ tlds: { allow: false } }),
        firstname: Joi.string(),
        lastname: Joi.string(),
    }),
}).options({ presence: 'required' })

export const requestLoginFb = async (opts = {}) => {
    const { error } = schema1.validate(opts)
    if (error) console.log(error)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}webapi/facebooklogin`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.code !== '99' && res.code !== '101') {
            const error = new Error('REQUEST_FAILED')
            error.code = 'RequestFailed'
            throw error
        }

        if (res.code === '101') {
            window.TOKEN = res.data.access_token
            window.SESSION_ID = res.data.session_id
            window.EMAIL = res.data.email

            localStorage.setItem('session_id', res.data.id)
        }

        return res
    } catch (err) {
        throw err
    }
}

/**
 *
 *
 *
 *
 * Facebook afterlogin
 * @param {*} opts
 */
const schema2 = Joi.object({
    reqObj: Joi.object({
        facebook_id: Joi.string(),
        email: Joi.string().email({ tlds: { allow: false } }),
        firstname: Joi.string(),
        lastname: Joi.string(),
        hash: Joi.string(),
    }),
}).options({ presence: 'required' })

export const requestAfterLoginFb = async (opts = {}) => {
    const { error } = schema2.validate(opts)
    if (error) console.log(error)
    if (error) throw new Error('INVALID_OPTS')

    const url = `${Environment.apiDomain}api/afterfacebooklogin?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        /**
         * get fingerprint from storage or create new one
         */
        const hash = await getFingerAsync()
        opts.reqObj.hash = hash

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(opts),
        }).then(res => res.json())

        if (res.code !== 101) {
            const error = new Error('REQUEST_FAILED')
            error.code = 'RequestFailed'
            throw error
        }

        /**
         * save it on localStorage
         */
        localStorage.setItem('access_token', window.TOKEN)
        return res
    } catch (err) {
        throw err
    }
}
