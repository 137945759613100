/**
 *
 *
 *
 */
import { Environment } from "../../../../config/webpack/environment";

export default async () => {
    const url = `${Environment.payDomain}api/cart?access_token=${window.TOKEN}&session_id=${window.SESSION_ID}`

    try {
        const res = await fetch(url, { method: 'DELETE' }).then((res) =>
            res.json(),
        )

        if (res.code === 1090) {
            throw new Error('DEVICE_TO_VALIDATE')
        }

        if (res.code !== 101) {
            const error = new Error('REQUEST_FAILED')
            error.code = 'RequestFailed'
            throw error
        }

        return res
    } catch (err) {
        throw err
    }
}
