import platform from 'platform'
import localforage from 'localforage'
import md5 from 'md5'

indexedDB.open('browsersupport').onerror = function(e) {
    /* IndexedDB not supported */
    console.log(e)
}

localforage.config({
    driver: [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE,
    ],
    name: 'Sxc',
    version: 4,
    storeName: 'h', // Should be alphanumeric, with underscores.
})

function makeid(length = 10) {
    let text = ''
    const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < length; i += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
}

export const getDevice = () =>
    window.DocumentTouch && document instanceof DocumentTouch ? 'm' : 'd'

const getFinger = (callback = () => {}) => {
    localforage
        .getItem('sionses2')
        .then(finger => {
            if (finger) {
                callback(finger)
                return
            }

            const toEncode =
                platform.name +
                platform.layout +
                platform.os.architecture +
                platform.os.family +
                window.navigator.product +
                window.navigator.platform +
                window.navigator.vendor +
                window.navigator.language +
                (window.DocumentTouch
                    ? document instanceof DocumentTouch
                    : 'noTouch')

            const newFinger = `${md5(toEncode)}::${makeid(10)}`

            localforage
                .setItem('sionses2', newFinger)
                .then(() => {
                    callback(newFinger)
                })
                .catch(() => {
                    callback('')
                })
        })
        .catch(() => {
            callback('')
        })
}

export default getFinger

export const getFingerAsync = () =>
    new Promise(resolve => {
        getFinger(hash => resolve(hash))
    })
